<template>
  <section class="yl-ambassadors-map"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-ambassadors-map__container u-marg-x-auto">
      <h3 class="f-h4 f-color-brand-white u-align-center"
          v-html="content.title">
      </h3>

      <div class="yl-ambassadors-map__wrapper">
        <div class="yl-ambassadors-map__selector-container u-marg-x-auto u-bg-color-brand-black">
          <yl-selector :placeholder="{ label: this.content['select-label'], value: -1 }"
                       :choices="countries"
                       @change="selectorChange"
                       @clear="selectorClear"
                       ref="selector">
          </yl-selector>
        </div>

        <div ref="mapbox"
            class="yl-ambassadors-map__mapbox-container u-marg-t-6 u-overflow-h">
          <!-- <span class="yl-ambassadors-map__dot" style="position: absolute; display: none; opacity: 0;"></span> -->

          <div class="yl-ambassadors-map__map"
              id="mapbox">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Dependencies
  import mapboxgl from 'mapbox-gl'

  // Store
  import { STATE as S, GETTERS as G } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Constants
  import countriesLL from '@/constants/countries'

  // Components
  import YLSelector from '@/components/elements/YLSelector'

  export default {
    name: 'YLAmbassadorsMap',

    components: {
      'yl-selector': YLSelector
    },

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* header.title: Title used for the map
       * @param {String} - *REQUIRED* header.select-label: Label used for countries selection
       **/
      content: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        allMarkers: [],
        selectedCountry: null,
        mapbox: {
          accessToken: 'pk.eyJ1IjoibW9ub3BvIiwiYSI6ImNrNzBkcmgzNjAxdTAzZW5xY29xanB5eWYifQ.GPTodN71OgkJehVd35FwqQ',
          // accessToken: 'pk.eyJ1IjoiZmNvcmRpbGxvdCIsImEiOiJjaXBiYWgzNWQwMDFjdXRrc281Mnp5cmd6In0.ocQvHwCUWJh_xlfvEBcAVg',
          mapStyle: 'mapbox://styles/monopo/ck70fao6z0b3q1jqpdxht6oxc'
          // mapStyle: 'mapbox://styles/fcordillot/ck12gmyxw047l1cnthyklvqyz'
        },
        visible: false
      }
    },

    computed: {
      ambassadors () {
        return this.$store.getters[G.ambassadors]
      },

      countries () {
        const countries = []

        if (this.ambassadors) {
          this.ambassadors.forEach(ambassador => {
            const accr = ambassador.ACF.country.value
            const countryLL = countriesLL.filter(country => country.accr === accr)[0]
            countryLL.value = countryLL.accr
            countryLL.label = countryLL.name

            countries.push(countryLL)
          })
        }

        const uCountries = countries.filter((country, index, self) => index === self.findIndex(c => c.accr === country.accr))

        return uCountries
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      elClasses () {
        return [

        ]
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Variables
      this.selectorRect = null

      // Events
      EventBus.$on('resize', this._onResize)

      // Mapbox
      mapboxgl.accessToken = this.mapbox.accessToken
    },

    mounted () {
      this.$nextTick(() => {
        this.map = new mapboxgl.Map({
          container: 'mapbox',
          style: this.mapbox.mapStyle,
          zoom: this.isTabletDevice ? -1 : -0.4,
          center: [19.372928, 36.670410], // more proper center
          interactive: false,
          pitchWithRotate: false,
          attributionControl: false,
          scrollZoom: false,
          dragRotate: false,
          dragPan: false,
          keyboard: false,
          doubleClickZoom: false,
          touchZoomRotate: false,
          trackResize: false
        })

        this.map.on('load', this._onMapboxLoad)

        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)
      this.map.off('load', this._onMapboxLoad)
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)

        const $ref = this.$refs['mapbox']
        if ($ref) {
          const width = $ref.offsetWidth

          const maxWidth = 553
          const minWidth = 204
          const maxZoom = 0
          const minZoom = -1.5
          const ratioWidth = (width - minWidth) / (maxWidth - minWidth)
          const zoom = (maxZoom - minZoom) * ratioWidth + minZoom

          this.map.setZoom(zoom)
        }
      },

      // HTML Events
      selectorChange (event) {
        this.selectedCountry = event !== null ? [event] : this.countries

        this._addMarkers()

        this.$emit('change', event)
      },

      selectorClear () {
        this.selectedCountry = null

        this._addMarkers()

        this.$emit('clear')
      },

      // Mapbox
      _onMapboxLoad () {
        this._addMarkers()
      },

      _clearMarkers () {
        const $markers = this.$el.querySelectorAll('.js-marker')

        if ($markers && $markers.length > 0) {
          $markers.forEach($marker => {
            if ($marker.parentNode) {
              $marker.parentNode.removeChild($marker)
            }
          })
        }
      },

      _addMarkers () {
        this._clearMarkers()

        const _var = this.selectedCountry ? 'selectedCountry' : this.countries ? 'countries' : null

        if (_var !== null) {
          this[_var].forEach(country => {
            // create a HTML element for each feature
            const $el = document.createElement('div')
            $el.className = 'is-a-map-dot js-marker'

            // make a marker for each feature and add to the map
            new mapboxgl.Marker($el)
              .setLngLat([ country.longitude, country.latitude ])
              .addTo(this.map)

            $el.addEventListener('click', () => {
              this._onClickOnMarker(country)
            })
          })
        }
      },

      _onClickOnMarker (country) {
        let _country = country.value === this.$refs['selector'].selection.value ? null : country
        this.selectedCountry = _country !== null ? [_country] : null

        this._addMarkers()

        if (country.value === this.$refs['selector'].selection.value) {
          this.$emit('clear')
          this.$refs['selector'].selection = this.$refs['selector'].placeholder
        } else {
          this.$emit('change', _country)
          this.$refs['selector'].selection = _country
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassadors-map {
    position: sticky;
    top: 0;
    transform: translateX(100%) translateZ(0);

    $colspan: colspan(2.5);
    width: $colspan;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: map-get($colors-list, "brand-black");
    opacity: 0;
    visibility: hidden;

    transition: transform 1s $ease-out-quint, opacity 0.85s $ease-out-quint, visibility 1s;
    will-change: transform, opacity, visibility;

    .is-visible & {
      transform: translateZ(0);

      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('medium-') {
      position: relative;
      transform: translateZ(0);

      width: 100%;
      height: auto;
      min-height: auto;

      opacity: 1;
      visibility: visible;
    }
  }

  .yl-ambassadors-map__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 8,
      large: 4,
      xlarge: 4,
    ));
    padding-top: $base-px * 15;

    @include breakpoint('medium-') {
      padding-top: $base-px * 5;
      padding-bottom: $base-px * 5;
    }
  }

  .yl-ambassadors-map__wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoint('medium-') {
      flex-direction: column-reverse;
    }
  }

  .yl-ambassadors-map__selector-container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 8,
      large: 3,
      xlarge: 3,
    ));

    @include breakpoint('medium-') {
      position: sticky;
      top: 0;
      margin-top: $base-px * 6;
    }
  }

  .yl-ambassadors-map__mapbox-container {
    position: relative;

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 450 * 265);
    }

    @include breakpoint('medium-') {
      margin-top: $base-px * 2;
    }
  }

  .yl-ambassadors-map__map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
</style>

<style lang="scss">
  .is-a-map-dot {
    margin-top: -6px;

    width: 6px;
    height: 6px;

    background-color: map-get($colors-list, "brand-green");
    border-radius: 50%;
    cursor: pointer;

    &:before {
      $offset: 6px / 3;

      content: "";

      position: absolute;
      top: -$offset;
      right: -$offset;
      bottom: -$offset;
      left: -$offset;
    }
  }
</style>
