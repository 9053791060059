<template>
  <section class="yl-legends-covers-item"
           :class="elClasses"
           :style="elStyle">
    <yl-image-lazy-load :sources="cover.image.bind" />
  </section>
</template>

<script>
  export default {
    name: 'YLLegendsCoversItem',

    props: {
      /**
       * Object with cover item
       * @param {Object} - *REQUIRED* cover: Object with cover item
       * @param {String} - *REQUIRED* cover.id: Cover's ID
       * @param {Object} - *REQUIRED* cover.image: Object w/ image
       **/
      cover: {
        type: Object,
        required: true
      },

      /**
       * Boolean to know if cover is active
       * @param {Boolean} - *REQUIRED* active: Boolean to know if cover is active
       **/
      active: {
        type: Boolean,
        default: null
      }
    },

    computed: {
      elClasses () {
        return [
          this.active !== null ? this.active ? 'is-active' : 'is-inactive' : 'is-active',
          `is-${this.cover.id}`
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legends-covers-item {
    $n-of-legends: 5;
    $m-left: $base-px * 10;
    $translateX: 100%;

    position: relative;
    transform: translateX(0) translateZ(0);

    width: calc(100% / #{$n-of-legends});

    transition: transform 1s $ease-out-quint;
    will-change: transform;

    &.is-lin-dan {
      z-index: 3;

      .has-landing-visible &,
      .has-lin-dan-visible & {
        transform: translateX(0) translateZ(0);
        z-index: 3;
      }

      .has-peter-gade-visible & {
        transform: translateX($translateX) translateX($m-left * -1) translateZ(0);
        z-index: 2;
      }

      .has-taufik-hidayat-visible & {
        transform: translateX($translateX * 2) translateX($m-left * -2) translateZ(0);
        z-index: 1;
      }

      .has-lee-yong-dae-visible & {
        transform: translateX($translateX * -2) translateX($m-left * 2) translateZ(0);
        z-index: 1;
      }

      .has-lee-chong-wei-visible & {
        transform: translateX($translateX * -1) translateX($m-left) translateZ(0);
        z-index: 2;
      }
    }

    &.is-peter-gade {
      z-index: 2;

      .has-landing-visible &,
      .has-lin-dan-visible & {
        transform: translateX(0) translateX($m-left) translateZ(0);
        z-index: 2;
      }

      .has-peter-gade-visible & {
        transform: translateX($translateX) translateZ(0);
        z-index: 3;
      }

      .has-taufik-hidayat-visible & {
        transform: translateX($translateX * 2) translateX($m-left * -1) translateZ(0);
        z-index: 2;
      }

      .has-lee-yong-dae-visible & {
        transform: translateX($translateX * 3) translateX($m-left * -2) translateZ(0);
        z-index: 1;
      }

      .has-lee-chong-wei-visible & {
        transform: translateX($translateX * -1) translateX($m-left * 2) translateZ(0);
        z-index: 1;
      }
    }

    &.is-taufik-hidayat {
      z-index: 1;

      .has-landing-visible &,
      .has-lin-dan-visible & {
        transform: translateX(0) translateX($m-left * 2) translateZ(0);
        z-index: 1;
      }

      .has-peter-gade-visible & {
        transform: translateX($translateX) translateX($m-left) translateZ(0);
        z-index: 2;
      }

      .has-taufik-hidayat-visible & {
        transform: translateX($translateX * 2) translateZ(0);
        z-index: 3;
      }

      .has-lee-yong-dae-visible & {
        transform: translateX($translateX * 3) translateX($m-left * -1) translateZ(0);
        z-index: 2;
      }

      .has-lee-chong-wei-visible & {
        transform: translateX($translateX * 4) translateX($m-left * -2) translateZ(0);
        z-index: 1;
      }
    }

    &.is-lee-yong-dae {
      z-index: 1;

      .has-landing-visible &,
      .has-lin-dan-visible & {
        transform: translateX(0) translateX($m-left * -2) translateZ(0);
        z-index: 1;
      }

      .has-peter-gade-visible & {
        transform: translateX($translateX * -4) translateX($m-left * 2) translateZ(0);
        z-index: 1;
      }

      .has-taufik-hidayat-visible & {
        transform: translateX($translateX * -3) translateX($m-left) translateZ(0);
        z-index: 2;
      }

      .has-lee-yong-dae-visible & {
        transform: translateX($translateX * -2) translateZ(0);
        z-index: 3;
      }

      .has-lee-chong-wei-visible & {
        transform: translateX($translateX * -1) translateX($m-left * -1) translateZ(0);
        z-index: 2;
      }
    }

    &.is-lee-chong-wei {
      z-index: 2;

      .has-landing-visible &,
      .has-lin-dan-visible & {
        transform: translateX(0) translateX($m-left * -1) translateZ(0);
        z-index: 2;
      }

      .has-peter-gade-visible & {
        transform: translateX($translateX * 1) translateX($m-left * -2) translateZ(0);
        z-index: 1;
      }

      .has-taufik-hidayat-visible & {
        transform: translateX($translateX * -3) translateX($m-left * 2) translateZ(0);
        z-index: 1;
      }

      .has-lee-yong-dae-visible & {
        transform: translateX($translateX * -2) translateX($m-left) translateZ(0);
        z-index: 2;
      }

      .has-lee-chong-wei-visible & {
        transform: translateX($translateX * -1) translateZ(0);
        z-index: 3;
      }
    }

    &.is-active {
      opacity: 1;
    }

    &.is-inactive {
      opacity: 0.1;
    }
  }
</style>
