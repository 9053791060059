<template>
  <section class="yl-pillar-block-concept"
           :class="elClasses"
           :style="elStyle"
           @click="_openPopup">
    <div class="yl-pillar-block-concept__header">
      <div class="yl-pillar-block-concept__content">
        <p class="f-body-subtitle f-color-brand-cream">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
        </p>

        <h4 class="f-h4 f-color-brand-cream">
          <yl-text-appearance :text="block.h4"></yl-text-appearance>
        </h4>
      </div>

      <div class="yl-pillar-block-concept__icon">
        <yl-image-lazy-load :sources="icon.bind" />
      </div>
    </div>

    <div class="yl-pillar-block-concept__wrapper u-marg-t-2">
      <div class="yl-pillar-block-concept__image-and-button">
        <div v-if="isTabletDevice && hasPopup"
             class="yl-pillar-block-concept__button-container">
          <yl-appearance>
            <yl-round-button size="60px"
                             icon="plus"
                             @click="_openPopup">
            </yl-round-button>
          </yl-appearance>
        </div>

        <div class="yl-pillar-block-concept__image-container">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="image.bind" />
        </div>
      </div>

      <div class="yl-pillar-block-concept__content">
        <p class="yl-pillar-block-concept__text f-body-small f-color-brand-white">
          <yl-text-appearance :text="text"></yl-text-appearance>
        </p>

        <div v-if="!hasPopup"
             class="u-marg-t-2">
          <yl-appearance klass="yl-pillar-block-concept__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getImage } from '@/helpers/assets'
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'
  import YLCTA from '@/components/elements/YLCTA'

  export default {
    name: 'YLPillarBlockConcept',

    components: {
      'yl-round-button': YLRoundButton,
      'yl-cta': YLCTA
    },

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.subtitle - subtitle for the block.
       * @param {String} block.h4 - h4 for the block.
       * @param {String} block.text - text for the block.
       * @param {String} block.icon - icon for the block.
       * @param {String} block.image - image for the block.
       * @param {Object} block.button - button for the block.
       * @param {Object} block.button.to - Name of the page.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    computed: {
      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      image () {
        return getImage({
          filename: this.block.image
        })
      },

      icon () {
        return getImage({
          filename: this.block.icon
        })
      },

      text () {
        return this.block.text.replace(/<green>/g, '<span class="is-green">').replace(/<\/green>/g, '</span>')
      },

      hasPopup () {
        let has = false

        if (!this.block.button && (this.block.popupID || this.block.popupID === 0)) {
          has = true
        }

        return has
      },

      elClasses () {
        return [
          this.hasPopup ? 'has-popup' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      _openPopup () {
        if (this.hasPopup) {
          EventBus.$emit('YLPopups:open', this.block.popupID)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-concept {
    &.has-popup {
      cursor: url('~@/assets/images/yonex-legends_cursor_plus.png'), auto;
    }
  }

  .yl-pillar-block-concept__header {
    position: relative;

    display: flex;
    justify-content: flex-end;
  }

  .yl-pillar-block-concept__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 10,
      large: 6,
      xlarge: 6,
    ));

    opacity: 0.3;

    .is-container-for-junior-development .is-block-index-6 & {
      @include breakpoint('medium-') {
        top: 0;
        right: 0;
        left: unset;
        transform: translateX(10%) translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-participation-discovery .is-block-index-9 & {
      top: 70%;
      left: colspan(0.25, 'xsmall', 0px, true);
      transform: translateY(-65%) translateZ(0);

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 4,
        xlarge: 4,
      ));

      opacity: 1;

      @include breakpoint('medium-') {
        left: unset;
        right: 0;
        transform: translateY(0) translateZ(0);
      }
    }
  }

  .yl-pillar-block-concept__content {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 4,
      xlarge: 4,
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 2;
    }
  }

  .yl-pillar-block-concept__wrapper {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: space-between;

    .is-container-for-junior-development .is-block-index-6 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;

        flex-direction: column-reverse;
      }
    }

    .is-container-for-participation-discovery .is-block-index-9 & {
      @include breakpoint('medium-') {
        flex-direction: column;
      }
    }
  }

  .yl-pillar-block-concept__image-and-button {
    .is-container-for-junior-development .is-block-index-6 & {
      @include breakpoint('medium-') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .yl-pillar-block-concept__button-container {
    .is-container-for-junior-development .is-block-index-6 & {
      width: $base-px 6;
    }
  }

  .yl-pillar-block-concept__image-container {
    @include width-multi((
      medium: 6,
      large: 4,
      xlarge: 4,
    ));

    @include breakpoint('small-') {
      margin-top: $base-px * 2;

      width: calc(100% - #{$base-px} * (6 + 2))
    }

    .is-container-for-participation-discovery .is-block-index-9 & {
      @include width-multi((
        medium: 6,
        large: 4,
        xlarge: 4
      ));

      @include breakpoint('small-') {
        width: 100%;
      }
    }
  }

  .yl-pillar-block-concept__round-button-container {
    display: flex;
    justify-content: center;
  }
</style>

<style lang="scss">
  .yl-pillar-block-concept__text {
    & .is-green {
      color: map-get($colors-list, "brand-green");
    }
  }
</style>
