/*eslint-disable*/
module.exports = {
  name: 'Yonex Legends',
  // Dictionary object is used to generate xml dictionary and template in src/task/layouts
  package: {
    name: 'Yonex Legends',
    exportName: 'YL_V', // filename used to generate package
    indexable: false, // Set true to remove meta robots
    // spa-pre rendering configuration
    pages: [
      {
        name: 'home',
        filename: '',
        gtmChapter: '',
        path: '/',
        output: 'index'
      }, {
        name: 'about',
        filename: '',
        gtmChapter: '',
        path: '/about',
        output: 'about'
      }, {
        name: 'ambassadors',
        filename: '',
        gtmChapter: '',
        path: '/ambassadors',
        output: 'ambassadors'
      }, {
        name: 'badminton-advancement',
        filename: '',
        gtmChapter: '',
        path: '/badminton-advancement',
        output: 'badminton-advancement'
      }, {
        name: 'community-enrichment',
        filename: '',
        gtmChapter: '',
        path: '/community-enrichment',
        output: 'community-enrichment'
      }, {
        name: 'events',
        filename: '',
        gtmChapter: '',
        path: '/events',
        output: 'events'
      }, {
        name: 'junior-development',
        filename: '',
        gtmChapter: '',
        path: '/junior-development',
        output: 'junior-development'
      }, {
        name: 'legacies',
        filename: '',
        gtmChapter: '',
        path: '/legacies',
        output: 'legacies'
      }, {
        name: 'legends',
        filename: '',
        gtmChapter: '',
        path: '/legends',
        output: 'legends'
      }, {
        name: 'participation-discovery',
        filename: '',
        gtmChapter: '',
        path: '/participation-discovery',
        output: 'participation-discovery'
      }
    ]
  }
}
