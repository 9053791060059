// Store
import store from '@/store'
import { GETTERS as G, MUTATIONS as M, ACTIONS as A } from '@/store/helpers'

export const getAllContent = async (to, from, next) => {
  store.commit(M.isTransitioning, 'is-in')

  if (!store.getters[G.contentIsFetched]) {
    try {
      await fetchAllContent()
      next()
    } catch (e) {
      store.commit(M.fetchError, e)
      store.commit(M.contentIsFetched, true)

      next()

      throw Error(e.message)
    }
  } else {
    setTimeout(() => {
      next()
    }, 1000)
  }
}

export const fetchAllContent = async () => {
  await store.dispatch(A.fetchEditableContent)

  await store.dispatch(A.fetchAmbassadors)
  await store.dispatch(A.fetchAmbassadorFeatured)
  await store.dispatch(A.fetchEvents)
  await store.dispatch(A.fetchEventFeatured)
  await store.dispatch(A.fetchLegacies)
  await store.dispatch(A.fetchLegacyFeatured)

  store.commit(M.contentIsFetched, true)
}
