<template functional>
  <div class="yl-media"
       :class="`yl-media--${props.type}`">
    <div class="yl-media__inner">
      <div class="yl-media__aspect-ratio" :style="{backgroundColor: props.aspectRatioBcg}">
        <div class="yl-media__aspect-ratio-content">
          <!-- @slot Media content inside aspect ratio box-->
          <slot></slot>
        </div>
      </div>
    </div>

    <div v-if="props.caption"
         class="yl-media__caption">
      <span v-html="props.caption"></span>
    </div>
  </div>
</template>

<script>
  /**
   * Media container.
   * Render a media component (Image / video...) inside an aspect ratio box from type.
   */
  export default {
    name: 'YLMedia',

    props: {
      /**
       * Aspect ratio type. Available values: full-width, large (960 * 535), video (1920 * 1080), Miny Story (375 * 500)
       */
      type: {
        type: String,
        required: true
      },
      /**
       * Background color for aspect ratio box
       */
      aspectRatioBcg: {
        type: String,
        default: 'transparent'
      },
      /**
       * Caption for media
       */
      caption: {
        type: [String, Boolean],
        default: false
      }
    }
  }
</script>

<style lang="scss">
  // Use style here for nested functionnal components
  // @see: https://github.com/vuejs/vue-loader/issues/1259
  .yl-media {
    &.yl-media--slide-intersticiel,
    &.yl-media--p-footer,
    &.yl-media--large,
    &.yl-media--video {
      @extend %container;
      @include breakpoint('medium-') {
        width: 100%;
      }
    }
  }

  .yl-media .yl-media--prologue {
    @extend %container;
    @include breakpoint('medium-') {
      width: 100%;
    }
  }
</style>

<style lang="scss" scoped>
  .yl-media {
    width: 100%;
    max-width: $max-width;
  }

  .yl-media__aspect-ratio {
    margin: 0 auto;

    max-width: $max-width;
    width: 100%;
    height: 100%;
  }

  .yl-media__aspect-ratio-content {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .yl-media__caption {
    text-align: center;
    color: map-get($colors-list, 'white-near');
  }
</style>
