<template>
  <section class="yl-menu-nav"
           :class="elClasses"
           :style="elStyle">
    <yl-menu-nav-item v-for="(page, pageIndex) in pages"
                      :key="`yl-menu-nav_item-${pageIndex}`"
                      :page="page"
                      @click="$emit('click', $event)">
    </yl-menu-nav-item>

    <div class="yl-menu-nav__sn-container u-marg-t-5">
      <yl-social-networks :items="socialNetworks"></yl-social-networks>
    </div>
  </section>
</template>

<script>
  // Components
  import YLMenuNavItem from './YLMenuNavItem'
  import YLSocialNetworks from '@/components/social-networks/YLSocialNetworks'

  export default {
    name: 'YLMenuNav',

    components: {
      'yl-menu-nav-item': YLMenuNavItem,
      'yl-social-networks': YLSocialNetworks
    },

    props: {
      /**
       * Array of pages
       * @param {Array} - *REQUIRED* pages
       **/
      pages: {
        type: Array,
        required: true
      },

      /**
       * Array of social-networks
       * @param {Array} - *REQUIRED* social-networks
       **/
      socialNetworks: {
        type: Array,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-menu-nav {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 5,
      large: 4,
      xlarge: 4
    ));
  }

  .yl-menu-nav__sn-container {
    @include width-multi((
      xsmall: 2,
      small: 2,
      medium: 2,
      large: 2,
      xlarge: 2
    ));

    @include breakpoint('small-') {
      margin-top: $base-px * 3;
    }
  }
</style>
