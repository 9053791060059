<template>
  <section class="yl-ambassadors-list-item u-marg-t-5 u-cursor-pointer"
           :class="elClasses"
           :style="elStyle"
           @click="click">
    <div class="yl-ambassadors-list-item__image-container u-align-center u-overflow-h">
      <yl-image-lazy-load :sources="ambassador.ACF.picture.bind"
                          klass="yl-ambassadors-list-item__image"
                          bcg-color="rgba(2, 2, 3, 0.5)"
                          :delay="`${index % 2 === 0 ? 0 : 0.15}s`" />
    </div>

    <p class="u-marg-t-2 f-body-subtitle f-color-brand-brown"
       v-html="ambassador.ACF.country.label">
    </p>

    <p class="f-body-big f-color-brand-white"
       v-html="ambassador.ACF.name">
    </p>
  </section>
</template>

<script>
  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  export default {
    name: 'YLAmbassadorsListItem',

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* ambassador: Object w/ content
       **/
      ambassador: {
        type: Object,
        required: true
      },

      /**
       * Index of the item on list
       * @param {Number} - index
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      click () {
        EventBus.$emit('YLPopups:open-ambassador', this.ambassador.id)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassadors-list-item {
    width: calc((100% - (#{$base-px} * 3)) / 2);

    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    @include breakpoint('small-') {
      width: 100%;

      &:nth-child(-n + 2) {
        margin-top: $base-px * 5;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .yl-ambassadors-list-item__image-container {
    position: relative;

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 512 * 608);
    }
  }

  .yl-ambassadors-list-item__image {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;

    object-fit: cover;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-ambassadors-list-item:hover & {
      transform: scale(1.1) translateZ(0);
    }
  }
</style>
