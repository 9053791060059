<template>
  <section class="yl-popups-item"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-popups-item__container">
      <div class="yl-popups-item__button-container">
        <yl-round-button size="60px"
                         icon="close"
                         @click="$emit('close')">
        </yl-round-button>
      </div>

      <div class="yl-popups-item__wrapper u-align-center">
        <div>
          <yl-image-lazy-load v-if="image !== null"
                              klass="yl-popups-item__image u-marg-x-auto"
                              :sources="image.bind" />

          <p v-if="popup.subtitle"
             class="u-marg-t-3 f-body-subtitle f-color-brand-cream">
            <yl-text-appearance :text="popup.subtitle"></yl-text-appearance>
          </p>

          <h3 v-if="popup.title"
              class="u-marg-t-1 f-h3 f-color-brand-white">
            <yl-text-appearance :text="popup.title"></yl-text-appearance>
          </h3>

          <div v-if="popup.text"
               class="u-marg-t-2 f-body-small f-color-brand-white">
            <yl-text-appearance :text="popup.text"></yl-text-appearance>
          </div>

          <div class="yl-popups-item__cta-container u-marg-t-3">
            <yl-appearance klass="yl-popups-item__button">
              <yl-cta :label="popup.button.label"
                      length="long"
                      :to="{ name: popup.button.to }">
              </yl-cta>
            </yl-appearance>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLCTA from '@/components/elements/YLCTA'
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLPopupsItem',

    components: {
      'yl-cta': YLCTA,
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       *
       **/
      popup: {
        type: Object,
        required: true
      },

      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      },

      /**
       *
       **/
      visible: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      image () {
        return this.popup.image ? getImage({
          filename: this.popup.image
        }) : null
      },

      elClasses () {
        return [
          `has-index-${this.index}`,
          this.visible ? 'is-visible' : 'is-hidden'
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-popups-item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $base-px * 6 $base-px * 6.4;

    overflow-y: scroll;

    opacity: 0;
    visibility: hidden;

    will-change: opacity, visibility;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('small-') {
      padding: $base-px * 3;
    }
  }

  .yl-popups-item__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 6,
      large: 6,
      xlarge: 6,
    ));
    height: 100%;
  }

  .yl-popups-item__button-container {
    display: flex;
    justify-content: flex-end;
  }

  .yl-popups-item__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - #{$base-px} * 6);
  }

  .yl-popups-item__image {
    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 2,
      large: 2,
      xlarge: 2,
    ));
  }

  .yl-popups-item__cta-container {
    display: flex;
    justify-content: center;
  }
</style>
