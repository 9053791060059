<template>
  <section class="yl-popups-ambassador"
           :class="elClasses"
           :style="elStyle">
  <div class="yl-popups-ambassador__button-container">
    <yl-round-button size="60px"
                     icon="close"
                     @click="$emit('close')">
    </yl-round-button>
  </div>

    <div class="yl-popups-ambassador__container">
      <div class="yl-popups-ambassador__wrapper u-marg-x-auto u-align-center">
        <div>
          <p v-if="ambassador.ACF.country.label"
             class="u-marg-t-3 f-body-subtitle f-color-brand-cream">
            <yl-text-appearance :text="ambassador.ACF.country.label"></yl-text-appearance>
          </p>

          <h3 v-if="ambassador.ACF.name"
              class="u-marg-t-1 f-h3 f-color-brand-white">
            <yl-text-appearance :text="ambassador.ACF.name"></yl-text-appearance>
          </h3>

          <div v-if="ambassador.ACF.description"
               class="u-marg-t-2 f-body-small f-color-brand-white">
            <yl-text-appearance :text="ambassador.ACF.description"></yl-text-appearance>
          </div>

          <div v-if="ambassador.ACF.legacy"
               class="u-marg-t-3 f-body-small f-color-brand-white u-align-center">
            <yl-appearance>
              <p>
                Legacy:
              </p>

              <div>
                <router-link class="yl-popups-ambassador__cta"
                             :to="{ name: 'legacies', query: { id: ambassador.ACF.legacy.ID } }">
                  <span v-html="ambassador.ACF.legacy.ACF.title"></span>

                  <div class="yl-popups-ambassador__cta__border-bottom"></div>
                </router-link>
              </div>
            </yl-appearance>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLPopupsAmbassador',

    components: {
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       *
       **/
      ambassador: {
        type: Object,
        required: true
      },

      /**
       *
       **/
      visible: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      elClasses () {
        return [
          this.visible ? 'is-visible' : 'is-hidden'
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-popups-ambassador {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: $base-px * 6 + $base-px * 6; /* button's top + button's size */
    padding-bottom: $base-px * 6;

    overflow-y: scroll;

    opacity: 0;
    visibility: hidden;

    will-change: opacity, visibility;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('small-') {
      padding-top: $base-px * 3 + $base-px * 6; /* button's top + button's size */
    }
  }

  .yl-popups-ambassador__button-container {
    position: absolute;
    top: $base-px * 6;
    right: $base-px * 6.4;

    display: flex;
    justify-content: flex-end;

    @include breakpoint('small-') {
      top: $base-px * 3;
      right: $base-px * 3;
    }
  }

  .yl-popups-ambassador__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 6,
      large: 6,
      xlarge: 6,
    ));
    height: 100%;
  }

  .yl-popups-ambassador__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 4,
      large: 4,
      xlarge: 4
    ));
    height: 100%;
  }

  .yl-popups-ambassador__cta {
    position: relative;

    display: inline-block;
  }

  .yl-popups-ambassador__cta__border-bottom {
    transform: translateZ(0);
    transform-origin: 50% 50%;

    width: 100%;
    height: 1px;

    background-color: map-get($colors-list, "brand-green");

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-popups-ambassador__cta:hover & {
      transform: scaleX(0.9) translateZ(0);
    }
  }
</style>
