<template>
  <section class="yl-menu"
           :class="elClasses"
           :style="elStyle">
    <header class="yl-menu__header">
      <yl-round-button size="60px"
                       icon="close"
                       @click="close">
      </yl-round-button>
    </header>

    <div class="yl-menu__container">
      <yl-menu-pillars :pillars="editableContent['pages']"
                       @click="click">
      </yl-menu-pillars>

      <yl-menu-nav :pages="editableContent['nav']"
                   :social-networks="editableContent['social-networks']"
                   @click="click">
      </yl-menu-nav>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S, MUTATIONS as M } from '@/store/helpers'

  // Helpers
  import { enableScroll } from '@/helpers/scroll'
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'
  // Menu
  import YLMenuPillars from './YLMenuPillars'
  import YLMenuNav from './YLMenuNav'

  export default {
    name: 'YLMenu',

    components: {
      'yl-round-button': YLRoundButton,
      // Menu
      'yl-menu-pillars': YLMenuPillars,
      'yl-menu-nav': YLMenuNav
    },

    computed: {
      isMobileDevice () {
        return this.$store.state[S.isMobileDevice]
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      },

      editableContent () {
        return this.$store.state[S.editableContent]['nav']
      }
    },

    created () {
      // Events
      EventBus.$on('keyup', this.keyup)
    },

    beforeDestroy () {
      // Events
      EventBus.$off('keyup', this.keyup)
    },

    methods: {
      // Events
      close () {
        this.$store.commit(M.menuIsOpen, false)

        if (this.$route.meta.hasScroll || (this.isMobileDevice && this.$route.meta.hasScrollOnMobile)) {
          enableScroll()
        }
      },

      click (event) {
        if (this.$route.name === event) {
          this.close()
        }
      },

      keyup (event) {
        if (event.keyCode === 27) {
          this.close()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-menu {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    transform: translateY(-100%) translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background-color: white;
    opacity: 0;
    visibility: hidden;

    transition: transform 1s $ease-out-quint, opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: transform, opacity, visibility;

    .has-menu-open & {
      transform: translateY(0) translateZ(0);

      opacity: 1;
      visibility: visible;
    }
  }

  .yl-menu__header {
    position: fixed;
    top: $base-px * 6;
    left: 50%;
    transform: translateX(-50%) translateZ(0);
    z-index: 5;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - (2 * #{$base-px} * 6.4));

    @include breakpoint('small-') {
      top: $base-px * 3;

      @include width-multi((
        xsmall: 6,
        small: 6
      ));
    }
  }

  .yl-menu__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 10,
      large: 10,
      xlarge: 10
    ));
    height: calc(100% - (2 * #{$base-px} * 6 * 2));

    @include breakpoint('small-') {
      height: calc(100% - (2 * #{$base-px} * 3) - (2 * #{$base-px} * 6));
    }
  }
</style>
