<template>
  <section class="pabout">
    <yl-about-header :header="editableContent['header']"></yl-about-header>

    <yl-about-quote :content="editableContent['first-quote']"></yl-about-quote>

    <yl-about-content :content="editableContent['content']"></yl-about-content>

    <div class="pabout__push-pages-container u-marg-t-12 u-marg-x-auto">
      <yl-pillar-push-pages :content="editableContent['push-pages']"></yl-pillar-push-pages>
    </div>

    <yl-about-quote :content="editableContent['second-quote']"></yl-about-quote>

    <yl-about-statistics :statistics="editableContent['statistics']"></yl-about-statistics>

    <yl-about-featured :content="editableContent['featured']"></yl-about-featured>
  </section>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Components
  import YLAboutHeader from '@/components/about/YLAboutHeader'
  import YLAboutQuote from '@/components/about/YLAboutQuote'
  import YLAboutContent from '@/components/about/YLAboutContent'
  import YLPillarPushPages from '@/components/pillar/YLPillarPushPages'
  import YLAboutStatistics from '@/components/about/YLAboutStatistics'
  import YLAboutFeatured from '@/components/about/YLAboutFeatured'

  export default {
    name: 'PAbout',
    pageName: 'about',
    mixins: [PageMixin],

    components: {
      'yl-about-header': YLAboutHeader,
      'yl-about-quote': YLAboutQuote,
      'yl-about-content': YLAboutContent,
      'yl-pillar-push-pages': YLPillarPushPages,
      'yl-about-statistics': YLAboutStatistics,
      'yl-about-featured': YLAboutFeatured
    }
  }
</script>

<style lang="scss" scoped>
  .pabout {
    position: relative;
    min-height: 100vh;
  }

  .pabout__push-pages-container {
    padding-top: 1px;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10
    ));

    @include breakpoint('small-') {
      margin-top: 0;
    }
  }
</style>
