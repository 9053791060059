<template>
  <section class="yl-events-content u-marg-t-16"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-events-content__container u-marg-x-auto">
      <div class="yl-events-content__col-left">
        <yl-image-lazy-load klass="yl-events-content__image"
                            bcg-color="rgba(2, 2, 3, 0.5)"
                            :sources="images[0].bind" />
      </div>

      <div class="yl-events-content__center">
        <div>
          <h3 class="f-h4 f-color-brand-cream u-align-center">
            <yl-text-appearance :text="content.title"></yl-text-appearance>
          </h3>

          <div class="u-align-center">
            <div v-for="(p, pIndex) in blocks"
                :key="`yl-events-content_p-${pIndex}`"
                class="yl-events-content__block f-body-small f-color-brand-white">
              <div v-if="pIndex > 0"
                   class="yl-events-content__block__border-top">
              </div>

              <yl-text-appearance :text="p"></yl-text-appearance>
            </div>
          </div>
        </div>
      </div>

      <div class="yl-events-content__col-right">
        <yl-image-lazy-load v-if="isTabletDevice"
                            klass="yl-events-content__image"
                            bcg-color="rgba(2, 2, 3, 0.5)"
                            :sources="images[0].bind" />

        <yl-image-lazy-load klass="yl-events-content__image"
                            bcg-color="rgba(2, 2, 3, 0.5)"
                            :sources="images[1].bind" />
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLEventsContent',

    props: {
      /**
       * Object w/ all content
       * @param {Object} - *REQUIRED* content
       * @param {String} - *REQUIRED* content.title: Title of the content
       * @param {Array} - *REQUIRED* content.blocks: Array of content. Each item is separated by a line
       * @param {Array} - *REQUIRED* content.images: Array of images' name
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      images () {
        const images = []

        this.content.images.forEach(filename => {
          images.push(getImage({
            filename
          }))
        })

        return images
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      blocks () {
        const blocks = []

        this.content.blocks.forEach(block => {
          blocks.push(block.replace(/<green>/g, '<span class="is-green">').replace(/<\/green>/g, '</span>'))
        })

        return blocks
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-content {
    @include breakpoint('small-') {
      margin-top: $base-px * 10;
    }
  }

  .yl-events-content__container {
    position: relative;

    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $max-width;

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }

  .yl-events-content__col-left,
  .yl-events-content__center,
  .yl-events-content__col-right {
    display: flex;
  }

  .yl-events-content__col-left,
  .yl-events-content__col-right {
    @include width-multi((
      large: 3,
      xlarge: 3
    ));

    @include breakpoint('medium-') {
      width: 100%;
    }
  }

  .yl-events-content__col-left {
    align-items: flex-end;

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .yl-events-content__center {
    align-items: center;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 4,
      xlarge: 4
    ));
    padding-top: $base-px * 12;
    padding-bottom: $base-px * 12;

    @include breakpoint('medium-') {
      margin-right: auto;
      margin-left: auto;

      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .yl-events-content__col-right {
    align-items: flex-start;

    @include breakpoint('medium-') {
      justify-content: space-between;
    }
  }

  .yl-events-content__image {
    width: 100%;

    @include breakpoint('medium-') {
      @include width-multi((
        xsmall: 3,
        small: 3,
        medium: 5
      ));

      &:nth-child(2) {
        margin-top: $base-px * 6;
      }
    }
  }

  .yl-events-content__block {
    position: relative;

    padding-top: $base-px * 5;
    padding-bottom: $base-px * 5;
  }

  .yl-events-content__block__border-top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateZ(0);

    @include width-multi((
      xsmall: 2,
      small: 2,
      medium: 2,
      large: 2,
      xlarge: 2
    ));
    height: 1px;

    background-color: map-get($colors-list, "brand-cream");
  }
</style>

<style lang="scss">
  .yl-events-content {
    & .is-green {
      color: map-get($colors-list, "brand-green");
    }
  }
</style>
