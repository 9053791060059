export const randomUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)

    return v.toString(16)
  })
}

export const randomHex = length => {
  let output = ''

  for (let i = 0; i < length; ++i) {
    output += (Math.floor(Math.random() * 16)).toString(16)
  }

  return output
}
