<template>
  <section class="yl-legacies-header"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legacies-header__container">
      <h1 class="yl-legacies-header__title f-page-title u-align-center">
        <yl-text-appearance :text="header.title"></yl-text-appearance>
      </h1>

      <h3 class="yl-legacies-header__subtitle u-marg-t-3 f-body-big f-color-brand-brown u-align-center u-marg-x-auto">
        <yl-text-appearance :text="header.text"></yl-text-appearance>
      </h3>
    </div>

    <div v-if="illustration"
         class="yl-legacies-header__illustration-container">
      <yl-image-lazy-load :sources="illustration.bind" />
    </div>

    <div ref="button"
         class="yl-legacies-header__button-container">
      <yl-round-button size="100px"
                       icon="full-arrow--bottom"
                       @click="scroll">
      </yl-round-button>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Behaviors
  import RAF from '@/behavior/RAF'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { getImage } from '@/helpers/assets'
  import { scrollTo } from '@/helpers/scroll'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLLegaciesHeader',

    components: {
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* header: Object w/ content
       * @param {String} - *REQUIRED* header.title: Title used for header
       * @param {String} - *REQUIRED* header.text: Text used for header
       **/
      header: {
        type: Object,
        required: true
      }
    },

    computed: {
      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      illustration () {
        return getImage({
          filename: 'legacies/yonex-legends_legacies_background.png'
        })
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()

        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)

      this.stopRAF()
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      },

      // HTML events
      scroll () {
        scrollTo(this.windowH)
      },

      // RAF
      rafCallback () {
        const $ref = this.$refs['button']
        if (!$ref) return

        if (this.scrollTop > (this.windowH / 100 * 25)) {
          if ($ref.className.indexOf('is-hidden') === -1) {
            $ref.classList.add('is-hidden')
          }
        } else {
          if ($ref.className.indexOf('is-hidden') > -1) {
            $ref.classList.remove('is-hidden')
          }
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-header {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    overflow: hidden;
  }

  .yl-legacies-header__container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));
  }

  .yl-legacies-header__title {
    @include text-stroke(1px, "brand-green");
  }

  .yl-legacies-header__subtitle {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 8,
      large: 8,
      xlarge: 8,
    ));
  }

  .yl-legacies-header__illustration-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: 100%;

    opacity: 0.2;

    @include breakpoint('small-') {
      width: 200%;
    }
  }

  .yl-legacies-header__button-container {
    position: fixed;
    right: 64px;
    bottom: 60px;
    transform: translateZ(0);

    opacity: 1;
    visibility: visible;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    @include breakpoint('small-') {
      display: none;
    }
  }
</style>
