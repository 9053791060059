<template>
  <section class="yl-legacies-list-legacy"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legacies-list-legacy__content f-body-subtitle f-color-brand-cream">
      <yl-text-appearance :text="legacy.ACF.location"></yl-text-appearance>
    </div>

    <h4 class="yl-legacies-list-legacy__content u-marg-t-1 f-h4 f-color-brand-cream">
      <yl-text-appearance :text="legacy.ACF.title"></yl-text-appearance>
    </h4>

    <div class="yl-legacies-list-legacy__content-arrow u-marg-t-3">
      <div class="yl-legacies-list-legacy__content f-body f-color-brand-white">
        <yl-text-appearance :text="legacy.ACF.subtitle"></yl-text-appearance>
      </div>

      <div class="yl-legacies-list-legacy__arrow-container">
        <yl-round-button size="60px"
                         icon="rafter-down"
                         icon-color="brand-cream"
                         :outline="true"
                         :active="active"
                         @click="click">
        </yl-round-button>
      </div>
    </div>

    <div ref="description"
         class="yl-legacies-list-legacy__content yl-legacies-list-legacy__description">
      <div class="o-content f-body-small f-color-brand-white"
           v-html="legacy.ACF.description">
      </div>

      <div v-if="legacy.ACF.ambassadors && legacy.ACF.ambassadors.length > 0"
           class="u-marg-t-6">
        <div class="f-body-small f-color-brand-white"
             v-html="ambassadorsLabel">
        </div>

        <div class="f-body-small f-color-brand-green"
             v-html="ambassadorsNames">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { scrollTo } from '@/helpers/scroll'
  import { getAbsoluteBoundingRect } from '@/helpers/dom'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLLegaciesListLegacy',

    components: {
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       * Object of legacy
       * @param {Object} - *REQUIRED* legacy: Object w/ legacy's content from API
       **/
      legacy: {
        type: Object,
        required: true
      },

      /**
       * @param {Booelan} - active: If the legacy is active (= open)
       */
      active: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ambassadorsLabel () {
        return this.$store.state[S.editableContent]['legacies']['list']['ambassadorsLabel']
      },

      ambassadorsNames () {
        const names = []

        if (this.legacy.ACF.ambassadors) {
          this.legacy.ACF.ambassadors.forEach(ambassador => {
            names.push(ambassador.name)
          })
        }

        return names.join(', ')
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      elClasses () {
        return [
          this.active ? 'is-active' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      click () {
        this.$emit('click')

        setTimeout(() => {
          if (this.active) {
            scrollTo(getAbsoluteBoundingRect(this.$el).top)
          }
        }, 100)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-list-legacy {
    padding-top: $base-px * 6;
    padding-bottom: $base-px * 6;

    border-bottom: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.3);

    @include breakpoint('medium-') {
      padding-top: $base-px * 4;
      padding-bottom: $base-px * 4;
    }
  }

  .yl-legacies-list-legacy__content {
    @include width-multi((
      large: 5,
      xlarge: 5
    ));

    @include breakpoint('medium-') {
      width: 100%;
      padding-right: $base-px * 3;
    }

    @include breakpoint('small-') {
      padding-right: $base-px;
    }

    .yl-legacies-list-legacy__content-arrow & {
      @include breakpoint('medium-') {
        width: calc(100% - #{$base-px} * 6);
      }
    }
  }

  .yl-legacies-list-legacy__content-arrow {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .yl-legacies-list-legacy__arrow-container {
    display: flex;
    justify-content: flex-end;

    @include breakpoint('medium-') {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%) translateZ(0);
    }
  }

  .yl-legacies-list-legacy__description {
    transform: translateZ(0);

    max-height: 0;

    opacity: 0;
    visibility: hidden;

    transition: margin-top 1s 0s, max-height 1s 0s, opacity 0.55s, visibility 0.55s;
    will-change: margin-top, max-height, opacity, visibility;

    .is-active & {
      margin-top: $base-px * 6;

      max-height: 1000px;

      opacity: 1;
      visibility: visible;

      transition: margin-top 1s, max-height 1s, opacity 0.55s 0s, visibility 0.55s 0s;
    }
  }
</style>
