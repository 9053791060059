<template>
  <section class="yl-footer"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-footer__container u-marg-x-auto">
      <div class="yl-footer__content">
        <div v-for="(column, columnIndex) in columns"
             :key="`yl-footer-column-${columnIndex}`"
             class="yl-footer__content__column">
          <div v-for="(page, pageIndex) in column"
               :key="`yl-footer-column-${columnIndex}-page-${pageIndex}`"
               class="yl-footer__item u-marg-t-2">
            <router-link :to="page.to"
                         class="yl-footer__item__link">
              <span class="f-body-small f-color-brand-cream">
                <yl-text-appearance :text="page.label"
                                    :transition-delay="columnIndex * 30">
                </yl-text-appearance>
              </span>

              <div class="yl-footer__item__border-bottom"></div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="yl-footer__sn-container">
        <yl-social-networks :items="editableContent['social-netorks']"></yl-social-networks>
      </div>
    </div>

    <p class="yl-footer__copyright u-marg-t-5 u-marg-x-auto f-footer-copyright f-color-brand-white u-opacity-40"
       v-html="editableContent['copyright']">
    </p>
  </section>
</template>

<script>
  // Store
  import { STATE as S, MUTATIONS as M } from '@/store/helpers'

  // Components
  import YLSocialNetworks from '@/components/social-networks/YLSocialNetworks'

  export default {
    name: 'YLFooter',

    components: {
      'yl-social-networks': YLSocialNetworks
    },

    computed: {
      editableContent () {
        return this.$store.state[S.editableContent]['footer']
      },

      columns () {
        const columns = []
        const fColumn = []
        const sColumn = []

        if (this.editableContent.pages) {
          const nOfPages = this.editableContent.pages.length
          const isEven = nOfPages % 2 === 0

          this.editableContent.pages.forEach((page, index) => {
            if (isEven) {
              if (index >= nOfPages / 2) {
                sColumn.push(page)
              } else {
                fColumn.push(page)
              }
            } else {
              if (index >= Math.ceil(nOfPages / 2)) {
                sColumn.push(page)
              } else {
                fColumn.push(page)
              }
            }
          })
        }

        columns.push(fColumn)
        if (sColumn.length) {
          columns.push(sColumn)
        }

        return columns
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    mounted () {
      this.$nextTick(() => {
        this.$store.commit(M.footerHeight, this.$el.offsetHeight)
        this.$store.commit(M.getPageFullHeight)
      })
    }
  }
</script>

<style lang="scss" scoped>
  .yl-footer {
    padding-top: $base-px * 10;
    padding-bottom: $base-px * 10;

    background-color: map-get($colors-list, "brand-black");

    @include breakpoint('small-') {
      padding-top: $base-px * 9;
      padding-bottom: $base-px * 2;
    }
  }

  .yl-footer__container {
    display: flex;
    justify-content: space-between;
     @include width-multi((
      xsmall: 6,
      mall: 6,
      medium: 12,
      large: 10,
      xlarge: 10,
    ));
    padding-left: $base-px * 10;

    @include breakpoint('medium-') {
      padding-left: 0;
    }

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }

  .yl-footer__content {
    display: flex;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 6,
      large: 4,
      xlarge: 4,
    ));

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }

  .yl-footer__content__column {
    width: 50%;

    @include breakpoint('small-') {
      margin-top: $base-px * 2;

      width: 100%;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .yl-footer__item {
    &:first-child {
      margin-top: 0;
    }
  }

  .yl-footer__item__link {
    position: relative;

    display: inline-block;
    height: 100%;
  }

  .yl-footer__item__border-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scaleX(0) translateZ(0);
    transform-origin: 0 50%;

    width: 100%;
    height: 1px;

    background-color: map-get($colors-list, "brand-green");

    transition: transform 0.55s $ease-out-quint;
    will-change: transform;

    .yl-footer__item__link:hover & {
      transform: scaleX(1) translateZ(0);
    }
  }

  .yl-footer__sn-container {
    display: flex;
    justify-content: flex-end;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 4,
      large: 2,
      xlarge: 2,
    ));

    @include breakpoint('small-') {
      margin-top: $base-px * 5;

      justify-content: flex-start;
    }
  }

  .yl-footer__copyright {
     @include width-multi((
      xsmall: 6,
      mall: 6,
      medium: 12,
      large: 10,
      xlarge: 10,
    ));
    padding-left: $base-px * 10;

    @include breakpoint('medium-') {
      padding-left: 0;
    }

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }
</style>
