<template>
  <section class="yl-cta u-cursor-pointer"
           :class="elClasses"
           :style="elStyle"
           @click="$emit('click')">
    <router-link v-if="elTag === 'router-link'"
                 class="yl-cta__link"
                 :to="to">
      <span class="f-button f-color-brand-white"
            v-html="label">
      </span>
    </router-link>

    <a v-else-if="elTag === 'a'"
       class="yl-cta__link"
       :href="href"
       target="_blank"
       rel="noopener">
      <span class="f-button f-color-brand-white"
            v-html="label">
      </span>

      <div v-if="icon"
           class="yl-cta__icon-container u-marg-l-5 f-color-brand-white">
        <yl-svg-icon :icon="icon"></yl-svg-icon>
      </div>
    </a>

    <div v-else-if="elTag === 'div'"
         class="yl-cta__link">
      <span class="f-button f-color-brand-white"
            v-html="label">
      </span>

      <div v-if="icon && !loading"
           class="yl-cta__icon-container u-marg-l-5 f-color-brand-white">
        <yl-svg-icon :icon="icon"></yl-svg-icon>
      </div>

      <div v-if="loading"
           class="yl-cta__loader-container u-marg-l-5">
        <div class="yl-cta__loader"></div>
      </div>
    </div>

    <div class="yl-cta__border"></div>

    <div class="yl-cta__background"></div>
  </section>
</template>

<script>
  export default {
    name: 'YLCTA',

    props: {
      /**
       *
       **/
      label: {
        type: String,
        default: ''
      },

      /**
       *
       **/
      to: {
        type: Object,
        default: null
      },

      /**
       *
       **/
      href: {
        type: String,
        default: null
      },

      /**
       *
       **/
      textColor: {
        type: String,
        default: 'brand-cream'
      },

      /**
       *
       **/
      backgroundColor: {
        type: String,
        default: 'brand-green'
      },

      /**
       *
       **/
      length: {
        type: String,
        default: 'short'
      },

      /**
       *
       **/
      icon: {
        type: String,
        default: null
      },

      /**
       *
       **/
      loading: {
        type: Boolean,
        default: null
      }
    },

    computed: {
      elClasses () {
        return [
          `has-bg-${this.backgroundColor}`,
          `is-color-${this.textColor}`,
          `is-length-${this.length}`,
          this.icon ? 'has-icon' : '',
          this.loading ? 'is-loading' : ''
        ]
      },

      elStyle () {
        return {}
      },

      elTag () {
        return this.to !== null ? 'router-link' : this.href !== null ? 'a' : 'div'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-cta {
    position: relative;

    display: inline-block;
    min-width: $base-px * 20;
    min-height: $base-px * 5;
    padding-right: $base-px * 3;
    padding-left: $base-px * 3;

    &.is-loading {
      cursor: not-allowed !important;
    }

    &.is-length-short {
      min-width: $base-px * 20;

      @include breakpoint('small-') {
        min-width: 100%;
      }
    }

    &.is-length-long {
      min-width: $base-px * 25;

      @include breakpoint('small-') {
        min-width: 100%;
      }
    }

    @include breakpoint('small-') {
      min-height: $base-px * 6;
    }
  }

  .yl-cta__link {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: $base-px * 5;

    @include breakpoint('small-') {
      min-height: $base-px * 6;
    }
  }

  .yl-cta__icon-container {
    transform: translateX(0) traslateZ(0);

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-cta:hover & {
      transform: translateX($base-px) translateZ(0);
    }
  }

  .yl-cta__loader,
  .yl-cta__loader:after {
    width: $base-px * 2.5;
    height: $base-px * 2.5;

    border-radius: 50%;
  }

  .yl-cta__loader {
    position: relative;
    transform: translateZ(0);

    text-indent: -9999em;
    border-top: 2px solid rgba(white, 0.2);
    border-right: 2px solid rgba(white, 0.2);
    border-bottom: 2px solid rgba(white, 0.2);
    border-left: 2px solid white;

    animation: animate-loader 1.1s infinite linear;
  }

  .yl-cta__border {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(1) translateZ(0);

    width: 100%;
    height: 100%;

    border-radius: $base-px * 2.5;
    border: 1px solid map-get($colors-list, "brand-green");

    .is-loading & {
      border-color: map-get($colors-list, "gray-darker") !important;
    }

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-cta:not(.is-loading):hover & {
      transform: scale(1.1) translateZ(0);
    }

    @include breakpoint('small-') {
      border-radius: $base-px * 6;
    }
  }

  .yl-cta__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(1) translateZ(0);

    width: 100%;
    height: 100%;

    border-radius: $base-px * 2.5;
    background-color: 1px solid map-get($colors-list, "brand-green");
    opacity: 0;
    visibility: hidden;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    .yl-cta:not(.is-loading):hover & {
      transform: scale(1.1) translateZ(0);
    }

    .yl-cta:not(.is-loading):active & {
      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('small-') {
      border-radius: $base-px * 6;
    }
  }

  @each $key,$val in $colors-list {
    .yl-cta__border {
      .has-bg-#{$key} & {
        border-color: #{$val};
      }
    }

    .yl-cta__background {
      .has-bg-#{$key} & {
        background-color: #{$val};
      }
    }
  }
</style>

<style lang="scss">
  .yl-cta__border {
    .yl-home-pillar-panels-item:hover & {
      transform: scale(1.1) translateZ(0);
    }
  }

  @keyframes animate-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>
