<template>
  <section class="yl-about-content"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-about-content__text-container u-marg-x-auto f-body f-color-brand-brown u-align-center">
      <yl-text-appearance :text="content.text"></yl-text-appearance>
    </div>

    <div class="yl-about-content__images-container u-marg-t-8 u-marg-x-auto">
      <div v-for="(image, imageIndex) in images"
           :key="`yl-about-content_image-${imageIndex}`"
           class="yl-about-content__image">
        <yl-image-lazy-load :sources="image.bind"
                            klass="yl-about-content__image-el"
                            bcg-color="rgba(2, 2, 3, 0.5)"
                            :delay="`${imageIndex * 0.15}s`" />
      </div>
    </div>

    <div class="yl-about-content__content-container u-marg-t-8 u-marg-x-auto">
      <h3 class="f-h4 f-color-brand-green">
        <yl-text-appearance :text="content.title"></yl-text-appearance>
      </h3>

      <div class="u-marg-t-1 f-body f-color-brand-white">
        <yl-text-appearance :text="content.content"></yl-text-appearance>
      </div>
    </div>

    <div class="yl-about-content__icon-container">
      <img v-bind="icon.bind" />
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLAboutContent',

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* content.text: Text used for the "chapeau"
       * @param {Array} - *REQUIRED* content.images: Array of images
       * @param {String} - *REQUIRED* content.icon: Icon used in the background
       * @param {String} - *REQUIRED* content.title: Title used below images
       * @param {String} - *REQUIRED* content.content: Text used below title
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      images () {
        const images = []

        this.content.images.forEach(image => {
          images.push(getImage({
            filename: image
          }))
        })

        return images
      },

      icon () {
        return getImage({
          filename: this.content.icon
        })
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-about-content {
    position: relative;
    margin-top: $base-px * 26;

    @include breakpoint('medium-') {
      margin-top: $base-px * 10;
    }
  }

  .yl-about-content__text-container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8
    ));

    @include breakpoint('medium-') {
      text-align: left;
    }
  }

  .yl-about-content__images-container {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $max-width;

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }

  .yl-about-content__image {
    width: calc((100% - (3 - 1) * #{$base-px} * 5) / 3);

    @include breakpoint('medium-') {
      margin-top: $base-px * 2;
      display: flex;

      width: 100%;
    }

    &:first-child {
      @include breakpoint('medium-') {
        margin-top: 0;

        justify-content: flex-start;
      }
    }

    &:nth-child(2) {
      @include breakpoint('medium-') {
        justify-content: center;
      }
    }

    &:last-child {
      @include breakpoint('medium-') {
        justify-content: flex-end;
      }
    }
  }

  .yl-about-content__image-el {
    @include breakpoint('medium-') {
      width: 50%;
    }
  }

  .yl-about-content__content-container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 3;
    }
  }

  .yl-about-content__icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8
    ));

    opacity: 0.15;
  }
</style>
