<template>
  <section class="yl-pillar-sidebar"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-sidebar__background">
      <img class="yl-pillar-sidebar__background__image"
           v-bind="background.bind" />
    </div>

    <div class="yl-pillar-sidebar__container">
      <yl-pillar-sidebar-item v-for="(page, pageIndex) in pages"
                              :key="`yl-pillar-sidebar-item-${pageIndex}`"
                              :to="page"
                              :index="pageIndex"
                              :active="page === name">
      </yl-pillar-sidebar-item>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLPillarSidebarItem from './YLPillarSidebarItem'

  export default {
    name: 'YLPillarSidebar',

    components: {
      'yl-pillar-sidebar-item': YLPillarSidebarItem
    },

    props: {
      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      },

      /**
       *
       **/
      name: {
        type: String,
        required: true
      }
    },

    data () {
      return {
        pages: ['junior-development', 'community-enrichment', 'participation-discovery', 'badminton-advancement']
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      },

      background () {
        return getImage({
          filename: 'yonex-legends_pillar_sidebar-lines.png'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-sidebar {
    display: inline-block;
  }

  .yl-pillar-sidebar__background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .yl-pillar-sidebar__background__image {
    height: 100%;
  }

  .yl-pillar-sidebar__container {
    $item-width: $base-px * 3;
    $first-line-left: 94px;

    position: absolute;
    top: 50%;
    left: ($first-line-left - $item-width) / 2;
    transform: translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 1,
      large: 1,
      xlarge: 1,
    ));
  }
</style>
