<template>
  <section class="pevents">
    <yl-events-header :header="editableContent['header']"></yl-events-header>

    <yl-events-statistics :content="editableContent['statistics']"></yl-events-statistics>

    <div class="pevents__video-container u-marg-t-4 u-marg-x-auto">
      <yl-pillar-block-video :block="editableContent['video']"></yl-pillar-block-video>
    </div>

    <yl-events-content :content="editableContent['content']"></yl-events-content>

    <yl-events-list-with-sidebar :content="editableContent['list']"></yl-events-list-with-sidebar>

    <yl-events-featured :content="editableContent['featured']"></yl-events-featured>
  </section>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Components
  import YLEventsHeader from '@/components/events/YLEventsHeader'
  import YLEventsStatistics from '@/components/events/YLEventsStatistics'
  import YLPillarBlockVideo from '@/components/pillar/blocks/YLPillarBlockVideo'
  import YLEventsContent from '@/components/events/YLEventsContent'
  import YLEventsListWithSidebar from '@/components/events/YLEventsListWithSidebar'
  import YLEventsFeatured from '@/components/events/YLEventsFeatured'

  export default {
    name: 'PEvents',
    pageName: 'events',
    mixins: [PageMixin],

    components: {
      'yl-events-header': YLEventsHeader,
      'yl-events-statistics': YLEventsStatistics,
      'yl-pillar-block-video': YLPillarBlockVideo,
      'yl-events-content': YLEventsContent,
      'yl-events-list-with-sidebar': YLEventsListWithSidebar,
      'yl-events-featured': YLEventsFeatured
    }
  }
</script>

<style lang="scss" scoped>
  .pevents {
    position: relative;

    min-height: 100vh;
  }

  .pevents__video-container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 9,
      large: 7,
      xlarge: 7
    ));

    @include breakpoint('small-') {
      margin-top: $base-px * 8;
    }
  }
</style>
