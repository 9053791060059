<template>
  <div class="yl-round-button"
           :class="elClasses"
           :style="elStyle"
           @click="$emit('click')">
    <div class="yl-round-button__background"></div>

    <div class="yl-round-button__icon">
      <yl-svg-icon :icon="icon"></yl-svg-icon>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YLRoundButton',

    props: {
      /**
       *
       **/
      size: {
        type: String,
        default: '100%'
      },

      /**
       *
       **/
      backgroundColor: {
        type: String,
        default: 'brand-cream'
      },

      /**
       *
       **/
      icon: {
        type: String,
        default: 'full-arrow--right'
      },

      /**
       *
       **/
      iconColor: {
        type: String,
        default: 'brand-black'
      },

      /**
       *
       **/
      cursor: {
        type: String,
        default: 'pointer'
      },

      /**
       *
       **/
      active: {
        type: Boolean,
        default: false
      },

      /**
       *
       **/
      outline: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      elClasses () {
        return [
          `has-bg-${this.backgroundColor}`,
          `has-icon-${this.icon}`,
          `is-color-${this.iconColor}`,
          `has-cursor-${this.cursor}`,
          this.active ? 'is-active' : '',
          this.outline ? 'is-outline' : ''
        ]
      },

      elStyle () {
        return {
          'width': this.size
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-round-button {
    position: relative;
    transform: scale(1) translateZ(0);

    will-change: transform;

    @each $key,$val in $colors-list {
      &.is-color-#{$key} {
        color: #{$val};
      }
    }

    &.has-cursor-pointer {
      cursor: pointer;
    }

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: 100%;
    }

    .yl-round-button:hover & {
      transform: scale(1.1) translateZ(0);
    }

    @include breakpoint('small-') {
      width: $base-px * 6 !important;
    }
  }

  .yl-round-button__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(1) translateZ(0);

    width: 100%;
    height: 100%;

    border-radius: 50%;

    transition: transform 0.55s $ease-out-quint;
    will-change: transform;

    @each $key,$val in $colors-list {
      .has-bg-#{$key} & {
        background-color: #{$val};
        border: 1px solid #{$val};
      }
    }

    .is-outline & {
      background-color: transparent;
    }

    .yl-round-button:hover & {
      transform: scale(1.1) translateZ(0);
    }

    .yl-round-button.is-active & {
      transform: scale(0.9) translateZ(0);
    }
  }

  .yl-round-button__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);
    z-index: 2;

    transition: transform 0.55s $ease-out-quint;
    will-change: transform;

    .has-icon-play & {
      left: calc(50% + 2px);
    }

    .yl-round-button.is-active & {
      transform: translateX(-50%) translateY(-50%) rotate(-180deg) translateZ(0);
    }
  }
</style>

<style lang="scss">
  .yl-round-button__background {
    .yl-pillar-block-video__wrapper:hover & {
      transform: scale(1.1) translateZ(0);
    }
  }
</style>
