<template>
  <section class="yl-legends-covers"
           :class="elClasses"
           :style="elStyle">
    <yl-legends-covers-item v-for="(cover, coverIndex) in _covers"
                            :key="`yl-legends-covers_cover-${coverIndex}`"
                            :cover="cover"
                            :active="activeLegend !== null ? activeLegend === cover.id : null">
    </yl-legends-covers-item>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLLegendsCoversItem from './YLLegendsCoversItem'

  export default {
    name: 'YLLegendsCovers',

    components: {
      'yl-legends-covers-item': YLLegendsCoversItem
    },

    props: {
      /**
       * Array with a list of ID's for order
       * @param {Array} - *REQUIRED* covers
       **/
      covers: {
        type: Array,
        required: true
      },

      /**
       * Active Legend's ID (or null)
       * @param {String} - *REQUIRED* activeLegend
       **/
      activeLegend: {
        type: null,
        default: null
      }
    },

    computed: {
      _covers () {
        const covers = []

        this.covers.forEach(cover => {
          covers.push({
            id: cover,
            image: getImage({
              filename: `legends/yonex-legends_legends_${cover}_cover.png`
            })
          })
        })

        return covers
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legends-covers {
    position: relative;
    transform: translateY(0) translateZ(0);
    z-index: 1;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    transition: transform 1s $ease-out-quint;
    will-change: transform;

    .has-landing-visible & {
      transform: translateY($base-px * 2 * -1) translateZ(0);
    }
  }
</style>
