<template>
  <section class="yl-pillar-block-text-image"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-block-text-image__header">
      <p class="yl-pillar-block-text-image__text f-body f-color-brand-white">
        <yl-text-appearance :text="block.text"></yl-text-appearance>
      </p>

      <div class="yl-pillar-block-text-image__icon">
        <yl-image-lazy-load :sources="icon.bind" />
      </div>
    </div>

    <div class="yl-pillar-block-text-image__image-container">
      <div class="yl-pillar-block-text-image__image-wrapper">
        <yl-image-lazy-load klass="yl-pillar-block-text-image__image"
                            bcg-color="rgba(2, 2, 3, 0.5)"
                            :sources="image.bind" />
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLPillarBlockTextImage',

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.text - text for the block.
       * @param {String} block.icon - Name (w/ extension) of the icon.
       * @param {String} block.image - Name (w/ extension) of the image.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    computed: {
      icon () {
        return getImage({
          filename: this.block.icon
        })
      },

      image () {
        return getImage({
          filename: this.block.image
        })
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-text-image {

  }

  .yl-pillar-block-text-image__header {
    position: relative;
    z-index: 2;

    display: flex;
  }

  .yl-pillar-block-text-image__text {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 5,
      xlarge: 5,
    ));
  }

  .yl-pillar-block-text-image__icon {
    position: absolute;
    top: -50;
    right: 0;
    transform: translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 4,
      xlarge: 4,
    ));

    opacity: 0.6;
  }

  .yl-pillar-block-text-image__image-container {
    position: relative;
    margin-top: -$base-px * 6;

    display: flex;
    justify-content: flex-end;
  }

  .yl-pillar-block-text-image__image-wrapper {
    position: relative;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 837 * 538);
    }
  }

  .yl-pillar-block-text-image__image {
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
