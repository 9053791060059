<template>
  <section class="yl-pillar-block"
           :class="elClasses"
           :style="elStyle">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: 'YLPillarBlock',

    props: {
      /**
       *
       **/
      type: {
        type: String,
        required: true
      },

      /**
       *
       **/
      marginBottom: {
        type: String,
        default: null
      },

      /**
       *
       **/
      marginTop: {
        type: String,
        default: null
      },

      /**
       * Index of this block
       * @param {Number} - *REQUIRED* index
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      elClasses () {
        return [
          `is-type-${this.type}`,
          `is-block-index-${this.index}`
        ]
      },

      elStyle () {
        return {
          'margin-bottom': `${this.marginBottom} !important`,
          'margin-top': `${this.marginTop} !important`
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block {
    &.is-type-h2-icon-text-image {
      margin-top: $base-px * 24;

      @include breakpoint('medium-') {
        margin-top: $base-px * 20;
      }

      .is-container-for-junior-development & {
        &.is-block-index-5 {
          margin-top: $base-px * 35;

          @include breakpoint('large-') {
            margin-top: $base-px * 25;
          }
        }
      }

      .is-container-for-community-enrichment & {
        &.is-block-index-6 {
          margin-top: $base-px * 30;

          @include breakpoint('medium-') {
            margin-top: $base-px * 16;
          }
        }
      }

      .is-container-for-participation-discovery & {
        &.is-block-index-8 {
          @include breakpoint('medium-') {
            margin-top: $base-px * 11;
          }
        }
      }

      .is-container-for-badminton-advancement & {
        &.is-block-index-2 {
          @include breakpoint('medium-') {
            margin-top: $base-px * 14;
          }
        }
      }
    }

    &.is-type-text-image {
      margin-bottom: $base-px * 16;
    }

    &.is-type-quote {
      margin-top: $base-px * 18;

      .is-container-for-junior-development & {
        &.is-block-index-4 {
          margin-top: $base-px * 40;

          @include breakpoint('large-') {
            margin-top: $base-px * 25 !important;
          }

          @include breakpoint('medium-') {
            margin-top: $base-px * 8 !important;
          }
        }
      }

      .is-container-for-community-enrichment & {
        &.is-block-index-5 {
          margin-top: $base-px * 30;

          @include breakpoint('medium-') {
            margin-top: $base-px * 16 !important;
          }
        }
      }

      .is-container-for-participation-discovery & {
        &.is-block-index-4 {
          margin-top: $base-px * 30;

          @include breakpoint('medium-') {
            margin-top: $base-px * 13 !important;
          }
        }
      }

      .is-container-for-badminton-advancement & {
        &.is-block-index-1 {
          margin-top: $base-px * 30;
        }
      }
    }

    &.is-type-case-study {
      margin-top: $base-px * 24;

      .is-container-for-community-enrichment & {
        &.is-block-index-3 {
          margin-bottom: $base-px * 9;

          @include breakpoint('medium-') {
            margin-top: $base-px * 14;
          }
        }

        &.is-block-index-7 {
          @include breakpoint('medium-') {
            margin-top: $base-px * 13;
          }
        }
      }

      .is-container-for-participation-discovery & {
        &.is-block-index-3 {
          @include breakpoint('medium-') {
            margin-top: $base-px * 25;
          }
        }

        &.is-block-index-6 {
          @include breakpoint('medium-') {
            margin-top: $base-px * 12;
          }
        }
      }

      .is-container-for-badminton-advancement & {
        &.is-block-index-3 {
          margin-top: $base-px * 27;

          @include breakpoint('medium-') {
            margin-top: $base-px * 30;
          }
          @include breakpoint('small-') {
            margin-top: $base-px * 20;
          }
        }
      }
    }

    &.is-type-concept {
      margin-top: $base-px * 30;

      .is-container-for-junior-development & {
        &.is-block-index-6 {
          @include breakpoint('large-') {
            margin-top: $base-px * 20;
          }
        }
      }

      .is-container-for-participation-discovery & {
        &.is-block-index-9 {
          margin-top: $base-px * 20;

          @include breakpoint('medium-') {
            margin-top: $base-px * 10;
          }
        }
      }
    }

    &.is-type-video {
      margin-top: $base-px * 15;

      .is-container-for-junior-development & {
        &.is-block-index-7 {
          @include breakpoint('medium-') {
            margin-top: $base-px * 10;
          }
        }
      }
    }

    &.is-type-key-figures {
      margin-top: $base-px * 8;
    }

    &:first-child {
      margin-top: 0;
    }
  }
</style>
