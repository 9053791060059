<template>
  <section class="yl-pillar-header"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-header__cover-container">
      <yl-appearance klass="yl-pillar-header__cover-overlay"></yl-appearance>

      <yl-image-lazy-load klass="yl-pillar-header__cover"
                          bcg-color="rgba(2, 2, 3, 0.5)"
                          :sources="cover.bind" />

      <div class="yl-pillar-header__number f-pillar-number-big">
        <yl-text-appearance :text="(index + 1)"></yl-text-appearance>
      </div>
    </div>

    <div class="yl-pillar-header__wrapper">
      <div class="yl-pillar-header__curved-text-container">
        <yl-pillar-curved-text :index="index"></yl-pillar-curved-text>
      </div>

      <h1 class="yl-pillar-header__title f-h1 f-color-brand-white">
        <yl-text-appearance :text="content.title"></yl-text-appearance>
      </h1>

      <p class="yl-pillar-header__subtitle f-subtitle f-color-brand-brown">
        <yl-text-appearance :text="content.subtitle"></yl-text-appearance>
      </p>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  // Pillar
  import YLPillarCurvedText from './YLPillarCurvedText'

  export default {
    name: 'YLPillarHeader',

    components: {
      // Pillar
      'yl-pillar-curved-text': YLPillarCurvedText
    },

    props: {
      /**
       *
       **/
      content: {
        type: Object,
        default: () => {}
      },

      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      },

      cover () {
        return getImage({
          filename: this.content.image
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-header {
    display: flex;
    align-items: flex-start;

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }

  .yl-pillar-header__cover-container {
    position: relative;

    display: block;
    @include width-multi((
      large: 5,
      xlarge: 5,
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 1600 * 2000);

      @include breakpoint('medium-') {
        padding-top: calc(100% / 375 * 463);
      }
    }

    @include breakpoint('medium-') {
      width: 100%;
    }
  }

  .yl-pillar-header__cover-overlay {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 2 !important;

    width: 100% !important;
    height: 100% !important;

    background-image: linear-gradient(to left, #A8A8A8, rgba(#A8A8A8, 0)) !important;
    opacity: 0.5 !important;

    @include breakpoint('medium-') {
      background-image: linear-gradient(to top, #A8A8A8, rgba(#A8A8A8, 0)) !important;
    }
  }

  .yl-pillar-header__cover {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
  }

  .yl-pillar-header__number {
    position: absolute;
    right: $base-px * 6;
    bottom: $base-px * 6;
    z-index: 3;

    display: none;

    @include text-stroke(1px, "brand-green");

    @include breakpoint('medium-') {
      display: block;
    }
  }

  .yl-pillar-header__wrapper {
    position: relative;
    margin-left: colspan(0.25, 'xsmall', 0px, true);
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 5,
      xlarge: 5,
    ));

    @include breakpoint('medium-') {
      margin-top: -$base-px * 6;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .yl-pillar-header__curved-text-container {
    margin-top: -$base-px * 6;

    display: flex;
    justify-content: flex-end;

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .yl-pillar-header__title {
    margin-top: $base-px * 3;

    @include breakpoint('medium-') {
      margin-top: 0;
    }
  }

  .yl-pillar-header__subtitle {
    margin-top: $base-px * 2;
  }
</style>
