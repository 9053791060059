<template>
  <section class="yl-about-statistics"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-about-statistics__container u-marg-x-auto">
      <yl-about-statistics-item v-for="(statistic, statisticIndex) in _statistics"
                                :key="`yl-about-statistics_statistic-${statisticIndex}`"
                                :statistic="statistic">
      </yl-about-statistics-item>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Components
  import YLAboutStatisticsItem from './YLAboutStatisticsItem'

  export default {
    name: 'YLAboutStatistics',

    components: {
      'yl-about-statistics-item': YLAboutStatisticsItem
    },

    props: {
      /**
       * Array w/ statistics
       * @param {Array} - *REQUIRED* statistics: Array w/ statistics
       **/
      statistics: {
        type: Array,
        required: true
      }
    },

    computed: {
      counts () {
        return {
          ambassadors: this.$store.state[S.ambassadors] !== null ? this.$store.state[S.ambassadors].length : 25,
          events: this.$store.state[S.events] !== null ? this.$store.state[S.events].length : 9,
          legacies: this.$store.state[S.legacies] !== null ? this.$store.state[S.legacies].length : 10
        }
      },

      _statistics () {
        const s = []

        this.statistics.forEach(stat => {
          if (stat.hasOwnProperty('endpoint')) {
            stat.title = this.counts[stat.endpoint]
          }

          s.push(stat)
        })

        return s
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-about-statistics {
    margin-top: $base-px * 23;

    @include breakpoint('small-') {
      margin-top: $base-px * 10;
    }
  }

  .yl-about-statistics__container {
    display: flex;
    justify-content: center;
    align-items: center;
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 10,
      large: 10,
      xlarge: 10
    ));

    @include breakpoint('medium-') {
      flex-wrap: wrap;
    }

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }
</style>
