import Vue from 'vue'
import Vuex from 'vuex'
import VueYoutube from 'vue-youtube'

import getCurrentMediaQuery from '@area17/a17-helpers/src/utility/getCurrentMediaQuery'

// Helpers
import { isTouchDevice, tabletBreakpoints, mobileBreakpoints } from '@/helpers/globals'
import { getColorScheme } from '@/helpers/dark-mode'
import { getLang } from '@/helpers/lang'
import Support from '@/helpers/support'
import { isGPDRAccepted, acceptGPDR } from '@/helpers/cookies'
import { STATE, MUTATIONS, GETTERS, ACTIONS } from '@/store/helpers'

// Data
import imagesData from '@/data/images'
import globalData from '@/data/global'

// Controllers
import { fetchAmbassadors, fetchAmbassadorFeatured } from '@/controllers/AmbassadorsController'
import { fetchEvents, fetchEventFeatured } from '@/controllers/EventsController'
import { fetchLegacies, fetchLegacyFeatured } from '@/controllers/LegaciesController'
import { fetchEditableContent } from '@/controllers/ContentController'

const config = require('../../app.config.js')

Vue.use(Vuex)
Vue.use(VueYoutube)

export const state = {
  [STATE.support]: new Support(),
  [STATE.lang]: getLang(),
  [STATE.mediaQuery]: getCurrentMediaQuery(),
  [STATE.isTabletDevice]: tabletBreakpoints.includes(getCurrentMediaQuery()),
  [STATE.isMobileDevice]: mobileBreakpoints.includes(getCurrentMediaQuery()),
  [STATE.isTransitioning]: 'is-up',
  [STATE.isTouchDevice]: isTouchDevice(),
  [STATE.isRetina]: window.devicePixelRatio > 1,
  [STATE.direction]: 'ltr',
  [STATE.headerHeight]: 0,
  [STATE.footerHeight]: 0,
  [STATE.pageHeight]: 0,
  [STATE.pageFullHeight]: 0,
  [STATE.scrollTop]: 0,
  [STATE.scrollDirection]: 'static',
  [STATE.canAutoPlayVideo]: false,
  [STATE.lang]: 'en',
  [STATE.windowW]: window.innerWidth,
  [STATE.windowH]: window.innerHeight,
  [STATE.enableLazyLoad]: true,
  [STATE.documentHeight]: 0,
  [STATE.gtmEventsSent]: [],
  [STATE.colorScheme]: getColorScheme(),
  [STATE.images]: imagesData(),
  [STATE.globalData]: globalData(),
  [STATE.contentIsFetched]: false,
  [STATE.numberOfContentToFetch]: 7,
  [STATE.numberOfContentFetched]: 0,
  [STATE.gpdrAccepted]: isGPDRAccepted(),
  [STATE.ambassadors]: null,
  [STATE.ambassadorFeatured]: null,
  [STATE.events]: null,
  [STATE.eventFeatured]: null,
  [STATE.legacies]: null,
  [STATE.legacyFeatured]: null,
  [STATE.pages]: config.package.pages,
  [STATE.menuIsOpen]: false
}

export const getters = {
  [GETTERS.support]: state => state.support,
  [GETTERS.lang]: state => state.lang,
  [GETTERS.isGtmEventSent]: state => eventUid => state[STATE.gtmEventsSent].includes(eventUid),
  [GETTERS.colorScheme]: state => state.colorScheme,
  [GETTERS.images]: state => state.images,
  [GETTERS.globalData]: state => state.globalData,
  [GETTERS.contentIsFetched]: state => state.contentIsFetched,
  [GETTERS.ambassadors]: state => state.ambassadors,
  [GETTERS.ambassadorFeatured]: state => state.ambassadorFeatured,
  [GETTERS.events]: state => state.events,
  [GETTERS.eventFeatured]: state => state.eventFeatured,
  [GETTERS.legacies]: state => state.legacies,
  [GETTERS.legacyFeatured]: state => state.legacyFeatured
}

export const mutations = {
  [MUTATIONS.support] (state, support) {
    state[STATE.support] = support
  },
  [MUTATIONS.lang] (state, lang) {
    state[STATE.lang] = lang
  },
  [MUTATIONS.mediaQuery] (state) {
    state[STATE.mediaQuery] = getCurrentMediaQuery()
    state[STATE.isTabletDevice] = tabletBreakpoints.includes(state[STATE.mediaQuery])
    state[STATE.isMobileDevice] = mobileBreakpoints.includes(state[STATE.mediaQuery])
  },
  [MUTATIONS.isTransitioning] (state, isTransitioning) {
    state[STATE.isTransitioning] = isTransitioning
  },
  [MUTATIONS.headerHeight] (state, headerHeight) {
    state[STATE.headerHeight] = headerHeight
  },
  [MUTATIONS.footerHeight] (state, footerHeight) {
    state[STATE.footerHeight] = footerHeight
  },
  [MUTATIONS.pageHeight] (state, pageHeight) {
    state[STATE.pageHeight] = pageHeight
  },
  [MUTATIONS.getPageFullHeight] (state) {
    state[STATE.pageFullHeight] = state[STATE.pageHeight] + state[STATE.footerHeight]
  },
  [MUTATIONS.scrollTop] (state, scrollTop) {
    state[STATE.scrollTop] = scrollTop
  },
  [MUTATIONS.scrollDirection] (state, scrollDirection) {
    state[STATE.scrollDirection] = scrollDirection
  },
  [MUTATIONS.documentHeight] (state, documentHeight) {
    state[STATE.documentHeight] = documentHeight
  },
  [MUTATIONS.direction] (state, direction) {
    state[STATE.direction] = direction
  },
  [MUTATIONS.autoplayVideo] (state, autoplay) {
    state[STATE.canAutoPlayVideo] = autoplay
  },
  [MUTATIONS.lang] (state, lang) {
    state[STATE.lang] = lang
  },
  [MUTATIONS.windowW] (state) {
    state[STATE.windowW] = window.innerWidth
  },
  [MUTATIONS.windowH] (state) {
    state[STATE.windowH] = window.innerHeight
  },
  [MUTATIONS.enableLazyLoad] (state) {
    state[STATE.enableLazyLoad] = true
  },
  [MUTATIONS.sendGtmEvent] (state, eventUid) {
    state[STATE.gtmEventsSent].push(eventUid)
  },
  [MUTATIONS.colorScheme] (state, colorScheme) {
    state[STATE.colorScheme] = colorScheme
  },
  [MUTATIONS.images] (state, images) {
    state[STATE.images] = images
  },
  [MUTATIONS.globalData] (state, globalData) {
    state[STATE.globalData] = globalData
  },
  [MUTATIONS.contentIsFetched] (state, contentIsFetched) {
    state[STATE.contentIsFetched] = contentIsFetched
  },
  [MUTATIONS.numberOfContentFetched] (state, numberOfContentFetched) {
    state[STATE.numberOfContentFetched] = numberOfContentFetched
  },
  [MUTATIONS.fetchError] (state, fetchError) {
    state[STATE.fetchError] = fetchError
  },
  [MUTATIONS.gpdrAccepted] (state, gpdrAccepted) {
    state[STATE.gpdrAccepted] = gpdrAccepted
    acceptGPDR()
  },
  [MUTATIONS.ambassadors] (state, ambassadors) {
    state[STATE.ambassadors] = ambassadors
  },
  [MUTATIONS.ambassadorFeatured] (state, ambassadorFeatured) {
    state[STATE.ambassadorFeatured] = ambassadorFeatured
  },
  [MUTATIONS.events] (state, events) {
    state[STATE.events] = events
  },
  [MUTATIONS.eventFeatured] (state, eventFeatured) {
    state[STATE.eventFeatured] = eventFeatured
  },
  [MUTATIONS.legacies] (state, legacies) {
    state[STATE.legacies] = legacies
  },
  [MUTATIONS.legacyFeatured] (state, legacyFeatured) {
    state[STATE.legacyFeatured] = legacyFeatured
  },
  [MUTATIONS.editableContent] (state, editableContent) {
    state[STATE.editableContent] = editableContent
  },
  [MUTATIONS.menuIsOpen] (state, menuIsOpen) {
    state[STATE.menuIsOpen] = menuIsOpen
  }
}

export const actions = {
  async [ACTIONS.fetchEditableContent] ({ commit, state }) {
    const content = await fetchEditableContent()
    commit(MUTATIONS.editableContent, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  },

  async [ACTIONS.fetchAmbassadors] ({ commit, state }) {
    const content = await fetchAmbassadors()
    commit(MUTATIONS.ambassadors, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  },

  async [ACTIONS.fetchAmbassadorFeatured] ({ commit, state }) {
    const content = await fetchAmbassadorFeatured()
    commit(MUTATIONS.ambassadorFeatured, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  },

  async [ACTIONS.fetchEvents] ({ commit, state }) {
    const content = await fetchEvents()
    commit(MUTATIONS.events, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  },

  async [ACTIONS.fetchEventFeatured] ({ commit, state }) {
    const content = await fetchEventFeatured()
    commit(MUTATIONS.eventFeatured, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  },

  async [ACTIONS.fetchLegacies] ({ commit, state }) {
    const content = await fetchLegacies()
    commit(MUTATIONS.legacies, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  },

  async [ACTIONS.fetchLegacyFeatured] ({ commit, state }) {
    const content = await fetchLegacyFeatured()
    commit(MUTATIONS.legacyFeatured, content)
    commit(MUTATIONS.numberOfContentFetched, state[STATE.numberOfContentFetched] + 1)
  }
}

export default new Vuex.Store({
  state: state,
  getters: getters,
  mutations: mutations,
  actions
})
