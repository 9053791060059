<template>
  <section class="yl-header-burger-menu u-cursor-pointer"
           @click="open">
    <div class="yl-header-burger-menu__bar yl-header-burger-menu__bar--top"></div>

    <div class="yl-header-burger-menu__bar yl-header-burger-menu__bar--middle"></div>

    <div class="yl-header-burger-menu__bar yl-header-burger-menu__bar--bottom"></div>

    <div class="yl-header-burger-menu__hover-state">
      <div class="yl-header-burger-menu__hover-state__bar yl-header-burger-menu__hover-state__bar--hidden"></div>

      <div class="yl-header-burger-menu__hover-state__bar"></div>

      <div class="yl-header-burger-menu__hover-state__bar"></div>

      <div class="yl-header-burger-menu__hover-state__bar"></div>

      <div class="yl-header-burger-menu__hover-state__bar yl-header-burger-menu__hover-state__bar--hidden"></div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S, MUTATIONS as M } from '@/store/helpers'

  // Helpers
  import { disableScroll } from '@/helpers/scroll'

  export default {
    name: 'YLHeaderBurgerMenu',

    computed: {
      menuIsOpen () {
        return this.$store.state[S.menuIsOpen]
      }
    },

    methods: {
      // HTML Events
      open () {
        disableScroll()
        this.$store.commit(M.menuIsOpen, !this.menuIsOpen)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-header-burger-menu {
    position: absolute;
    top: (43px - 13px) / 2;
    right: 0;
    transform: translateZ(0);

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    width: $base-px * 4;
    height: 13px;

    opacity: 1;

    transition: opacity 0.65s $ease-out-quint;
    will-change: opacity;

    .has-home-landing-word-hover & {
      opacity: 0;
    }

    @include breakpoint('medium-') {
      top: (33px - 19px) / 2;

      width: $base-px * 4.8;
      height: 19px;
    }
  }

  .yl-header-burger-menu__bar {
    width: 100%;
    height: 1px;

    background-color: map-get($colors-list, "brand-cream");

    &--top,
    &--bottom {
      width: $base-px * 3;
    }

    &--middle {
      transform: scaleX(1) translateZ(0);
      transform-origin: 100% 0;

      transition: transform 0.55s;
      will-change: transform;

      .yl-header-burger-menu:hover & {
        transform: scaleX(0.75) translateZ(0);
      }
    }
  }

  .yl-header-burger-menu__hover-state {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateZ(0);

    display: flex;
    justify-content: space-between;
    width: $base-px * 3;
    height: $base-px * 3;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.55s, visibility 0.55s;
    will-change: opacity, visibility;

    .yl-header-burger-menu:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-header-burger-menu__hover-state__bar {
    width: 1px;
    height: 100%;

    background-color: map-get($colors-list, "brand-cream");

    &--hidden {
      background-color: transparent;
    }
  }
</style>
