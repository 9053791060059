<template>
  <section class="yl-legends-covers-and-titles"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legends-covers-and-titles__container">
      <yl-legends-covers :covers="covers"
                         :activeLegend="activeLegend">
      </yl-legends-covers>

      <div class="yl-legends-covers-and-titles__titles u-marg-x-auto u-align-center">
        <div class="yl-legends-covers-and-titles__title-wrapper u-marg-x-auto u-align-center"
             :class="[ activeLegend === titles[0].id ? 'is-visible' : '' ]">
          <h1 class="yl-legends-covers-and-titles__first-title f-page-title-small"
            v-html="titles[0].title">
          </h1>

          <div v-if="activeLegend === null"
              class="yl-legends-covers-and-titles__subtitle u-marg-t-2 u-marg-x-auto f-body-big u-align-center"
              v-html="subtitle">
          </div>
        </div>

        <h2 v-for="(title, titleIndex) in titles.filter((t, i) => i > 0)"
            :key="`yl-legends-covers-and-titles_title-${titleIndex}`"
            class="yl-legends-covers-and-titles__title u-marg-x-auto f-page-title-big u-align-center"
            :class="[ activeLegend === title.id ? 'is-visible' : '' ]"
            v-html="title.title">
        </h2>
      </div>
    </div>

    <div class="yl-legends-covers-and-titles__icon-container">
      <yl-image-lazy-load :sources="icon.bind" />
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLLegendsCovers from './YLLegendsCovers'

  export default {
    name: 'YLLegendsLanding',

    components: {
      'yl-legends-covers': YLLegendsCovers
    },

    props: {
      /**
       * Array of cover's ID
       * @param {Array} - *REQUIRED* covers
       **/
      covers: {
        type: Array,
        required: true
      },

      /**
       * Array of Legends's name
       * @param {Array} - *REQUIRED* titles
       **/
      titles: {
        type: Array,
        required: true
      },

      /**
       * Active Legend's ID (or null)
       * @param {String | null} - *REQUIRED* activeLegend
       **/
      activeLegend: {
        type: null,
        default: null
      },

      /**
       * Subtitle
       * @param {String} - *REQUIRED* subtitle
       **/
      subtitle: {
        type: String,
        default: ''
      }
    },

    computed: {
      icon () {
        return getImage({
          filename: 'legends/yonex-legends_legends_landing_icon.png'
        })
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legends-covers-and-titles {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    opacity: 1;
    visibility: visible;

    transform: opacity, visibility;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  .yl-legends-covers-and-titles__container {
    position: relative;
    z-index: 2;
    transform: translateY(-10%) translateZ(0);

    width: 100%;
    max-width: $max-width;
  }

  .yl-legends-covers-and-titles__titles {
    position: absolute;
    bottom: $base-px * 5;
    left: 50%;
    transform: translateX(-50%) translateZ(0);
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 11,
      xlarge: 9
    ));

    transition: transform 1s $ease-out-quint;
    will-change: transform;

    .has-landing-visible & {
      transform: translateX(-50%) translateY(100%) translateY(0) translateZ(0);
    }
  }

  .yl-legends-covers-and-titles__title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;

    transition: opacity 1s $ease-out-quint, visibility 1s;
    will-change: opacity, visibility;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-legends-covers-and-titles__first-title {
    @include text-stroke(1px, "brand-green");
  }

  .yl-legends-covers-and-titles__title {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;
    height: 100%;

    @include text-stroke(1px, "brand-green");
    opacity: 0;
    visibility: hidden;

    transition: opacity 1s $ease-out-quint, visibility 1s;
    will-change: opacity, visibility;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-legends-covers-and-titles__subtitle {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6
    ));

    color: map-get($colors-list, "brand-cream");
  }

  .yl-legends-covers-and-titles__icon-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-4%) translateY(-50%) translateZ(0);

    width: 90%;

    opacity: 0;
    visibility: hidden;

    transition: opacity 1s $ease-out-quint, visibility 1s;
    will-change: opacity, visibility;

    .has-landing-visible & {
      opacity: 0.7;
      visibility: visible;
    }
  }
</style>
