<template>
  <section class="yl-legends-informations"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legends-informations__container u-marg-x-auto">
      <div class="yl-legends-informations__col-left">
        <div class="yl-legends-informations__col-left__items-container u-marg-t-14">
          <div v-for="(legend, legendIndex) in legends"
               :key="`yl-legends-informations_col-left_item-${legendIndex}`"
               class="yl-legends-informations__item f-legends-information"
               :class="[ activeLegend === legend.id ? 'is-visible' : '' ]">
            <div v-if="legend.born">
              <span class="f-color-brand-cream"
                    v-html="legend.born.title">
              </span>&nbsp;<span class="f-color-brand-white"
                    v-html="legend.born.content">
              </span>
            </div>

            <div v-if="legend.awards && legend.awards.length > 0">
              <div v-for="(award, awardIndex) in legend.awards"
                   :key="`yl-legends-informations_col-left_item-${legendIndex}_award-${awardIndex}`"
                   class="u-marg-t-1">
                <div v-for="(line, lineIndex) in award"
                     :key="`yl-legends-informations_col-left_item-${legendIndex}_award-${awardIndex}_line-${lineIndex}`"
                     :class="[ `f-color-${line.color}` ]"
                     v-html="line.content">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="yl-legends-informations__col-right">
        <div class="yl-legends-informations__col-right__items-container u-marg-t-15">
          <div v-for="(legend, legendIndex) in legends"
               :key="`yl-legends-informations_col-left_item-${legendIndex}`"
               class="yl-legends-informations__item f-legends-information"
               :class="[ activeLegend === legend.id ? 'is-visible' : '' ]">
            <div v-if="legend.caracteristics && legend.caracteristics.length > 0">
              <div v-for="(caracteristic, caracteristicIndex) in legend.caracteristics"
                   :key="`yl-legends-informations_col-left_item-${legendIndex}_caracteristic-${caracteristicIndex}`">
                <span class="f-color-brand-cream"
                      v-html="caracteristic.title">
                </span>&nbsp;<span class="f-color-brand-white"
                      v-html="caracteristic.content">
                </span>
              </div>
            </div>

            <div v-if="legend.video && legend.video.url"
                 class="yl-legends-informations__video-container u-marg-t-7">
              <a :href="legend.video.url"
                 target="_blank"
                 rel="noopener"
                 class="yl-legends-informations__video-wrapper">
                <yl-video-loop-container :video="_getVideoAsset(legend.video.asset)"></yl-video-loop-container>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getVideo } from '@/helpers/assets'

  // Components
  import YLVideoLoopContainer from '@/components/media/YLVideoLoopContainer'

  export default {
    name: 'YLLegendsInformations',

    components: {
      'yl-video-loop-container': YLVideoLoopContainer
    },

    props: {
      /**
       * Array of Legends
       * @param {Array} - *REQUIRED* legends
       **/
      legends: {
        type: Array,
        required: true
      },

      /**
       * Active Legend's ID (or null)
       * @param {String | null} - *REQUIRED* activeLegend
       **/
      activeLegend: {
        type: null,
        default: null
      }
    },

    computed: {
      windowH () {
        return this.$store.state[S.windowH]
      },

      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {
          height: `${this.windowH - this.headerHeight - 60}px` /* 60: header's margin-top */
        }
      }
    },

    methods: {
      // HTML Functions
      _getVideoAsset (asset) {
        return getVideo({
          filename: asset,
          formats: ['mp4']
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legends-informations {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    width: 100%;
  }

  .yl-legends-informations__container {
    display: flex;
    justify-content: space-between;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10
    ));
    height: 100%;
  }

  .yl-legends-informations__col-left {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 3,
      large: 3,
      xlarge: 3
    ));
  }

  .yl-legends-informations__col-right {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 3,
      large: 3,
      xlarge: 3
    ));
  }

  .yl-legends-informations__col-left__items-container,
  .yl-legends-informations__col-right__items-container {
    position: relative;
  }

  .yl-legends-informations__item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY($base-px) translateZ(0);

    width: 100%;

    opacity: 0;
    visibility: hidden;

    transition: transform 1s $ease-out-quint, opacity 1s $ease-out-quint, visibility 1s;
    will-change: transform, opacity, visibility;

    &.is-visible {
      transform: translateY(0) translateZ(0);

      opacity: 1;
      visibility: visible;
    }
  }

  .yl-legends-informations__video-container {
    display: flex;
    justify-content: flex-end;
  }

  .yl-legends-informations__video-wrapper {
    position: relative;

    @include width-multi((
      medium: 2,
      large: 2,
      xlarge: 2
    ));

    @include aspect-ratio(198, 111);

    transition: opacity 0.55s $ease-out-quint;
    will-change: opacity;

    &:hover {
      opacity: 0.5;
    }
  }
</style>
