<template>
  <section class="yl-home-pillar-panels">
    <div class="yl-home-pillar-panels__container">
      <yl-home-pillar-panels-item v-for="(item, itemIndex) in editableContent.list"
                                  :key="`yl-home-pillar-panels_item-${itemIndex}`"
                                  :index="itemIndex"
                                  :item="item"
                                  class="">
      </yl-home-pillar-panels-item>
    </div>
  </section>
</template>

<script>
  // Components
  import YLHomePillarPanelsItem from './YLHomePillarPanelsItem'

  export default {
    name: 'YLHomePillarPanels',

    components: {
      'yl-home-pillar-panels-item': YLHomePillarPanelsItem
    },

    props: {
      /**
       *
       **/
      content: {
        type: Object,
        default: () => {}
      },

      /**
       *
       **/
      editableContent: {
        type: Object,
        default: () => {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-home-pillar-panels {
    position: relative;
    transform: translateZ(0);
    z-index: 2;

    height: 100%;

    opacity: 1;
    visibility: visible;

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: oapcity, visibility;

    .has-home-landing-word-hover & {
      opacity: 0;
      visibility: hidden;
    }

    @include breakpoint('medium-') {
      margin-top: $base-px * 4;

      height: auto;

      overflow: hidden;
    }
  }

  .yl-home-pillar-panels__container {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    height: 100%;

    @include breakpoint('medium-') {
      margin-right: auto;
      margin-left: auto;

      flex-direction: column;
      @include width-multi((
        xsmall: 6,
        small: 6
      ));
      height: auto;
    }
  }
</style>
