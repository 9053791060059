<template>
  <section class="pambassadors">
    <yl-ambassadors-header :header="editableContent['header']"></yl-ambassadors-header>

    <yl-ambassadors-statistics :statistics="editableContent['statistics']"></yl-ambassadors-statistics>

    <yl-ambassadors-list-with-map :content="editableContent['map']"></yl-ambassadors-list-with-map>

    <yl-ambassadors-featured :content="editableContent['featured']"></yl-ambassadors-featured>
  </section>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Components
  import YLAmbassadorsHeader from '@/components/ambassadors/YLAmbassadorsHeader'
  import YLAmbassadorsStatistics from '@/components/ambassadors/YLAmbassadorsStatistics'
  import YLAmbassadorsListWithMap from '@/components/ambassadors/YLAmbassadorsListWithMap'
  import YLAmbassadorsFeatured from '@/components/ambassadors/YLAmbassadorsFeatured'

  export default {
    name: 'PAmbassadors',
    pageName: 'ambassadors',
    mixins: [PageMixin],

    components: {
      'yl-ambassadors-header': YLAmbassadorsHeader,
      'yl-ambassadors-statistics': YLAmbassadorsStatistics,
      'yl-ambassadors-list-with-map': YLAmbassadorsListWithMap,
      'yl-ambassadors-featured': YLAmbassadorsFeatured
    }
  }
</script>

<style lang="scss" scoped>
  .pambassadors {
    position: relative;
    min-height: 100vh;
  }
</style>
