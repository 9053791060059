<template>
  <section class="yl-menu-pillars"
           :class="elClasses"
           :style="elStyle">
    <yl-menu-pillars-item v-for="(pillar, pillarIndex) in pillars"
                          :key="`yl-menu-pillars_pillar-${pillarIndex}`"
                          :pillar="pillar"
                          @click="$emit('click', $event)">
    </yl-menu-pillars-item>
  </section>
</template>

<script>
  // Components
  import YLMenuPillarsItem from './YLMenuPillarsItem'

  export default {
    name: 'YLMenuPillars',

    components: {
      'yl-menu-pillars-item': YLMenuPillarsItem
    },

    props: {
      /**
       * Array of pillars
       * @param {Array} - *REQUIRED* pillars
       **/
      pillars: {
        type: Array,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-menu-pillars {
    display: flex;
    flex-wrap: wrap;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 4,
      large: 4,
      xlarge: 4
    ));

    @include breakpoint('small-') {
      display: none;
    }
  }
</style>
