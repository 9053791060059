<template>
  <div class="yl-video-loop-container">
    <yl-media type="video">
      <div class="yl-video-loop-container__inner">
        <yl-video-loop v-if="isVisible"
                       :video="video"/>
      </div>
    </yl-media>
  </div>
</template>

<script>
  // Components
  import YLVideoLoop from '@/components/media/YLVideoLoop'

  // Store
  import { STATE as S } from '@/store/helpers'

  /**
   * Video Loop Container
   * Display video inside container with define aspect ratio (1920 * 1080)
   */
  export default {
    name: 'YLVideoLoopContainer',

    components: {
      'yl-video-loop': YLVideoLoop
    },

    props: {
      /**
       * Video Object. Should have .mp4 key and .webm key.
       */
      video: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        isVisible: true,
        vRootMargin: `${this.$store.state[S.windowH] * 1.5}px`
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-video-loop-container__inner {
    width: 100%;
  }
</style>
