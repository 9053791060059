import { render, staticRenderFns } from "./PEvents.vue?vue&type=template&id=ec8a24ae&scoped=true&"
import script from "./PEvents.vue?vue&type=script&lang=js&"
export * from "./PEvents.vue?vue&type=script&lang=js&"
import style0 from "./PEvents.vue?vue&type=style&index=0&id=ec8a24ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec8a24ae",
  null
  
)

export default component.exports