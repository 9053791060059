<template>
  <router-link class="yl-menu-nav-item u-marg-t-4"
               :to="{ name: page.to }"
               :class="elClasses"
               :style="elStyle"
               @click.native="$emit('click', page.to)">
    <div class="yl-menu-nav-item__container">
      <span class="f-menu-page f-color-brand-black"
            v-html="page.label">
      </span>

      <div class="yl-menu-nav-item__border-bottom"></div>
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'YLMenuNavItem',

    props: {
      /**
       * Object of page
       * @param {Object} - *REQUIRED* page
       * @param {String} - *REQUIRED* page.label: Label of the page
       * @param {String} - *REQUIRED* page.to: Name of the page (in Vue-Router)
       **/
      page: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-menu-nav-item {
    position: relative;

    display: block;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 3,
      large: 3,
      xlarge: 3
    ));

    &:before {
      content: "";

      position: absolute;
      top: -$base-px;
      right: -$base-px;
      bottom: -$base-px;
      left: -$base-px;

      width: calc(100% + (2 * #{$base-px}));
      height: calc(100% + (2 * #{$base-px}));
    }

    &:first-child {
      margin-top: 0;
    }

    @include breakpoint('small-') {
      margin-top: $base-px * 2;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .yl-menu-nav-item__container {
    position: relative;

    display: inline-block;
  }

  .yl-menu-nav-item__border-bottom {
    position: absolute;
    bottom: -$base-px;
    left: 0;
    transform: scaleX(0) translateZ(0);
    transform-origin: 0 50%;

    width: 100%;
    height: 1px;

    background-color: map-get($colors-list, "brand-green");

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-menu-nav-item.router-link-exact-active &,
    .yl-menu-nav-item:hover & {
      transform: scaleX(1) translateZ(0);
    }
  }
</style>
