// Helpers
import { getImage } from '@/helpers/assets'

export default () => {
  return {
    'logotype': getImage({
      filename: 'yonex-legends_logo.png'
    })
  }
}
