<template functional>
 <span class="icon"
       :class="`icon--${props.icon}`">
    <svg>
      <title>{{ props.icon }}</title>

      <use :xlink:href="`/static/icons/icons.svg#icon--${props.icon}`"></use>
    </svg>
  </span>
</template>

<script>
  export default {
    name: 'WIPSvgIcon',

    props: {
      icon: {
        type: String,
        required: true
      }
    }
  }
</script>
