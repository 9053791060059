<template>
  <div class="yl-parallax-item">
    <slot></slot>
  </div>
</template>

<script>
  // Behavior
  import RAF from '@/behavior/RAF'

  export default {
    name: 'YLParallaxItem',

    props: {
      /**
       *
       **/
      value: {
        type: Number,
        default: 0
      },

      /**
       *
       **/
      ratio: {
        type: Number,
        default: 1
      },

      /**
       *
       **/
      direction: {
        type: String,
        default: 'x'
      }
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()
      })
    },

    beforeDestroy () {
      this.stopRAF()
    },

    methods: {
      // RAF
      rafCallback () {
        if (this.direction === 'x') {
          const translateX = Math.round(this.value * this.ratio * 100) / 100

          this.$el.style.transform = `translateX(${translateX}px) translateZ(0)`
        } else if (this.direction === 'y') {
          const translateY = Math.round(this.value * this.ratio * 100) / 100

          this.$el.style.transform = `translateY(${translateY}px) translateZ(0)`
        } else if (this.direction === 'xy') {
          const translateX = Math.round(0 * 100) / 100
          const translateY = Math.round(0 * 100) / 100

          this.$el.style.transform = `translateX(${translateX}px) translateY(${translateY}px) translateZ(0)`
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-parallax-item {
    position: relative;
    transform: translateZ(0);

    will-change: transform;

    .is-sprite & {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      overflow: hidden;
    }
  }
</style>
