<template>
  <section class="yl-pillar-block-h2"
           :class="elClasses"
           :style="elStyle">
    <h2 class="yl-pillar-block-h2__element f-h2 f-color-brand-green">
      <yl-text-appearance :text="block.h2"></yl-text-appearance>
    </h2>
  </section>
</template>

<script>
  // Helpers
  // import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLPillarBlockH2',

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.h2 - h2 for the block.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-h2 {
    position: relative;
  }

  .yl-pillar-block-h2__element {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));
  }
</style>
