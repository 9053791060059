<template>
  <section class="yl-home-pillar-panels-item"
           :class="elClasses">
    <div class="yl-home-pillar-panels-item__illustrations-container">
      <div v-for="(illustration, illustrationIndex) in illustrations"
           :key="`yl-home-pillar-panels-item_illustration-${illustrationIndex}`"
           class="yl-home-pillar-panels-item__illustration-wrapper"
           :class="[ illustration.sprite ? 'is-sprite' : '' ]">
        <yl-parallax-item :value="scroll"
                          :ratio="illustration.ratio">
          <img class="yl-home-pillar-panels-item__illustration"
               v-bind="illustration.image.bind" />
        </yl-parallax-item>
      </div>
    </div>

    <router-link class="yl-home-pillar-panels-item__link"
                 :to="{ name: item.button.to }">
      <div class="yl-home-pillar-panels-item__cover-container">
        <div class="yl-home-pillar-panels-item__cover-wrapper">
          <img class="yl-home-pillar-panels-item__cover"
               v-bind="cover.bind" />
        </div>

        <div class="yl-home-pillar-panels-item__cover-wrapper-mobile">
          <yl-image-lazy-load klass="yl-home-pillar-panels-item__cover"
                              bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="cover.bind" />
        </div>

        <div v-if="isTabletDevice"
             class="yl-home-pillar-panels-item__curved-text-container">
          <yl-pillar-curved-text :index="index"></yl-pillar-curved-text>
        </div>

        <div class="yl-home-pillar-panels-item__cover-decorations">
          <div class="yl-home-pillar-panels-item__cover-decorations__header f-home-pillar-card-header f-color-brand-white"
              v-html="`${item.header} ......${String(index + 1).padStart(2, '0')}`">
          </div>

          <div class="yl-home-pillar-panels-item__cover-decorations__footer f-home-pillar-card-footer f-color-brand-white"
              v-html="item.footer">
          </div>
        </div>
      </div>

      <div class="yl-home-pillar-panels-item__wrapper">
        <div v-if="!isTabletDevice"
             class="yl-home-pillar-panels-item__curved-text-container">
          <yl-pillar-curved-text :index="index"></yl-pillar-curved-text>
        </div>

        <div class="yl-home-pillar-panels-item__content">
          <div class="yl-home-pillar-panels-item__content-wrapper">
            <h3 class="f-h3 f-color-brand-white"
                v-html="item.title">
            </h3>

            <yl-cta class="yl-home-pillar-panels-item__button"
                    :label="item.button.label"
                    :to="{ name: item.button.to }">
            </yl-cta>
          </div>
        </div>
      </div>
    </router-link>
  </section>
</template>

<script>
  // Sotre
  import { STATE as S } from '@/store/helpers'

  // Components
  import YLCTA from '@/components/elements/YLCTA'
  import YLPillarCurvedText from '@/components/pillar/YLPillarCurvedText'
  import YLParallaxItem from '@/components/parallax/YLParallaxItem'

  // Helpers
  import { getImage } from '@/helpers/assets'
  import { EventBus } from '@/helpers/event-bus'

  export default {
    name: 'YLHomePillarPanelsItem',

    components: {
      'yl-cta': YLCTA,
      'yl-pillar-curved-text': YLPillarCurvedText,
      'yl-parallax-item': YLParallaxItem
    },

    props: {
      /**
       *
       **/
      item: {
        type: Object,
        required: true
      },

      /**
       *
       **/
      index: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        scroll: 0
      }
    },

    computed: {
      illustrations () {
        const illustrations = []

        this.item.illustrations.forEach(image => {
          illustrations.push({
            image: getImage({
              filename: `home/yonex-legends_home_parallax-object-${image.n}${image.sprite ? '_sprite' : ''}.png`
            }),
            sprite: image.sprite,
            ratio: image.ratio
          })
        })

        return illustrations
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      elClasses () {
        return [
          `is-index-${this.index}`
        ]
      },

      cover () {
        return getImage({
          filename: this.item.image
        })
      }
    },

    created () {
      // Events
      EventBus.$on('YLScrollHContainer:raf', this._onRAF)
    },

    beforeDestroy () {
      // Events
      EventBus.$off('YLScrollHContainer:raf', this._onRAF)
    },

    methods: {
      // Events
      _onRAF (scroll) {
        this.scroll = scroll.scrollEased
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-home-pillar-panels-item {
    position: relative;
    margin-right: colspan(1);

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 9,
      large: 8,
      xlarge: 8,
    ));

    @include breakpoint('medium-') {
      margin-bottom: $base-px * 12;
      margin-right: 0;

      &:last-child {
        margin-bottom: $base-px * 28;
      }
    }
  }

  .yl-home-pillar-panels-item__illustrations-container {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  .yl-home-pillar-panels-item__illustration-wrapper {
    position: absolute;

    .is-index-0 & {
      &:nth-child(1) {
        left: 50%;
        bottom: 0;
        transform: translateX(50%) translateY(100%) translateZ(0);

        width: 12vw;
        @include width-multi((
          xsmall: 3,
          small: 3,
          medium: 4
        ));

        @include breakpoint('medium-') {
          right: 0;
          left: unset;
          transform: translateX(-25%) translateY(100%) translateZ(0);
        }
      }
    }

    .is-index-1 & {
      &:nth-child(1) {
        top: $base-px * 4;
        left: 0;
        transform: translateX(-50%) translateY(0) translateZ(0);

        width: 46vw;

        @include breakpoint('medium-') {
          top: unset;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(50%) translateZ(0);

          width: 75vw;
        }

        @include breakpoint('small-') {
          top: unset;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(50%) translateZ(0);

          width: 125vw;
        }
      }

      &:nth-child(2) {
        bottom: 0;
        left: 0;
        transform: translateX(-100%) translateY(0) translateZ(0);

        width: 12vw;

        &.is-sprite {
          &:before {
            content: "";

            position: relative;

            display: block;
            width: 100%;
            padding-top: calc(100% / 313 * 900);
          }
        }

        @include breakpoint('medium-') {
          right: 0;
          left: unset;
          transform: translateY(25%) translateZ(0);

          @include width-multi((
            xsmall: 2,
            small: 2
          ));
        }
      }

      &:nth-child(3) {
        top: 0;
        right: 0;
        transform: translateX(50%) translateY(-50%) translateZ(0);

        width: 25vw;

        &.is-sprite {
          &:before {
            content: "";

            position: relative;

            display: block;
            width: 100%;
            padding-top: calc(100% / 604 * (11476 / 38));
          }
        }

        @include breakpoint('medium-') {
          display: none;
        }
      }
    }

    .is-index-2 & {
      &:nth-child(1) {
        bottom: 0;
        left: 0;
        transform: translateX(-82%) translateY(72%) translateZ(0);

        width: 11vw;

        &.is-sprite {
          &:before {
            content: "";

            position: relative;

            display: block;
            width: 100%;
            padding-top: calc(100% / 290 * (30882 / 47));
          }
        }

        @include breakpoint('medium-') {
          right: 0;
          left: unset;
          transform: translateY(25%) translateZ(0);

          @include width-multi((
            xsmall: 2,
            small: 2
          ));
        }
      }

      &:nth-child(2) {
        top: 0;
        right: 0;
        transform: translateX(50%) translateY(-50%) translateZ(0);

        width: 9vw;

        @include breakpoint('medium-') {
          display: none;
        }
      }
    }

    .is-index-3 & {
      &:nth-child(1) {
        bottom: 0;
        right: 0;
        transform: translateX(0) translateY(50%) translateZ(0);

        width: 53vw;

        opacity: 0.3;

        @include breakpoint('medium-') {
          bottom: -$base-px * 28;
          transform: translateZ(0);

          @include width-multi((
            xsmall: 6,
            small: 6
          ));
        }
      }

      &:nth-child(2) {
        top: 50%;
        right: 0;
        transform: translateX(100%) translateY(50%) translateZ(0);

        width: 10vw;

        @include breakpoint('medium-') {
          display: none;
        }
      }
    }
  }

  .yl-home-pillar-panels-item__illustration {
    .is-sprite & {
      opacity: 0.3;
    }

    .is-sprite:nth-child(3) & {
      .is-index-0 & {
        $n: 48;
        position: absolute;
        top: 0;
        left: 0;

        width: (100% * $n) !important;
        max-width: none !important;

        $duration: ($n / 24) + 's';
        animation: sprite-v #{$duration} steps(#{$n}) infinite;
        animation-direction: alternate;
      }

      .is-index-1 & {
        $n: 38;
        position: absolute;
        top: 0;
        left: 0;

        height: (100% * $n) !important;
        max-height: none !important;

        $duration: ($n / 24) + 's';
        animation: sprite-v #{$duration} steps(#{$n}) infinite;
        animation-direction: alternate;
      }
    }

    .is-sprite:nth-child(2) & {
      .is-index-1 & {
        $n: 48;
        position: absolute;
        top: 0;
        left: 0;

        width: (100% * $n) !important;
        max-width: none !important;

        $duration: ($n / 24) + 's';
        animation: sprite-h #{$duration} steps(#{$n}) infinite;
        animation-direction: alternate;
      }
    }

    .is-sprite:nth-child(1) & {
      .is-index-2 & {
        $n: 26;
        position: absolute;
        top: 0;
        left: 0;

        height: (100% * $n) !important;
        max-height: none !important;

        $duration: ($n / 24) + 's';
        animation: sprite-v #{$duration} steps(#{$n}) infinite;
        animation-direction: alternate;
      }
    }
  }

  .yl-home-pillar-panels-item__link {
    position: relative;
    z-index: 2;

    display: flex;
    width: 100%;

    @include breakpoint('medium-') {
      align-items: flex-start;
    }

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }

  .yl-home-pillar-panels-item__cover-container {
    position: relative;

    @include width-multi((
      xsmall: 4,
      small: 4,
      medium: 6,
      large: 4,
      xlarge: 4,
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 1600 * 2000);
    }
  }

  .yl-home-pillar-panels-item__cover-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .yl-home-pillar-panels-item__cover-wrapper-mobile {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: none;
    width: 100%;
    height: 100%;

    overflow: hidden;

    @include breakpoint('medium-') {
      display: block;
    }
  }

  .yl-home-pillar-panels-item__cover {
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1) translateZ(0);
    z-index: 2;

    max-width: 100%;

    transition: transform 0.65s $ease-out-quint;
    will-change: transform;

    .yl-home-pillar-panels-item:hover & {
      transform: scale(1.1) translateZ(0);
    }
  }

  .yl-home-pillar-panels-item__cover-decorations {
    $offset: $base-px;

    position: absolute;
    top: -#{$offset};
    right: -#{$offset};
    bottom: -#{$offset};
    left: -#{$offset};

    width: calc(100% + 2 * #{$offset});
    height: calc(100% + 2 * #{$offset});

    border: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .yl-home-pillar-panels-item__cover-decorations__header {
    position: relative;
    left: $base-px * 2;
    transform: translateY(-100%) translateZ(0);

    display: inline-block;
    padding-right: $base-px;
    padding-left: $base-px;

    background-color: #151515;
    border-right: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);
    border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);
  }

  .yl-home-pillar-panels-item__cover-decorations__footer {
    position: absolute;
    right: -1px;
    bottom: 0;
    left: -1px;
    transform: translateY(100%) translateZ(0);

    width: calc(100% + 2 * 1px);
    padding: 4px $base-px;

    border-right: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);
    border-bottom: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);
    border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);
  }

  .yl-home-pillar-panels-item__wrapper {
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 5,
      large: 5,
      xlarge: 5,
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 3;
    }
  }

  .yl-home-pillar-panels-item__curved-text-container {
    margin-top: -73px;
    margin-left: -38px;

    @include breakpoint('medium-') {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      transform: translateX(75%) translateY($base-px * 3) translateZ(0);
      z-index: 2
    }
  }

  .yl-home-pillar-panels-item__content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: colspan(0.25);

    @include breakpoint('medium-') {
      padding-left: 0;
    }
  }

  .yl-home-pillar-panels-item__button {
    margin-top: $base-px * 4;
  }
</style>

<style lang="scss">
  @keyframes sprite-h {
    from {
      transform: translateX(0) translateZ(0);
    }
    to {
      transform: translateX(-100%) translateZ(0);
    }
  }

  @keyframes sprite-v {
    from {
      transform: translateY(0) translateZ(0);
    }
    to {
      transform: translateY(-100%) translateZ(0);
    }
  }
</style>
