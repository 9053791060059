<template>
  <section class="yl-pillar-push-pages"
           :class="elClasses"
           :style="elStyle">
    <h4 class="yl-pillar-push-pages__title f-h4 f-color-brand-cream"
        v-html="content.h4">
    </h4>

    <div class="yl-pillar-push-pages__items-container u-marg-t-4">
      <yl-pillar-push-pages-item v-for="(item, itemIndex) in content.pages"
                                :key="`yl-pillar-push-pages-item-${itemIndex}`"
                                class=""
                                :item="item"
                                :index="itemIndex">
      </yl-pillar-push-pages-item>
    </div>
  </section>
</template>

<script>
  // Components
  import YLPillarPushPagesItem from './YLPillarPushPagesItem'

  export default {
    name: 'YLPillarPushPages',

    components: {
      'yl-pillar-push-pages-item': YLPillarPushPagesItem
    },

    props: {
      /**
       *
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return [
          `has-${this.content.pages.length}-pages`
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-push-pages {
    margin-top: $base-px * 15;

    @include breakpoint('medium-') {
      margin-top: $base-px * 10;
    }
  }

  .yl-pillar-push-pages__title {
    .has-4-pages & {
      color: map-get($colors-list, "brand-green") !important;
    }
  }

  .yl-pillar-push-pages__items-container {
    display: flex;
    justify-content: space-between;

    .has-4-pages & {
      @include breakpoint('medium-') {
        flex-wrap: wrap;
      }
    }

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }
</style>
