<template>
  <section class="yl-pillar-curved-text"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-curved-text__container f-color-brand-cream"
         ref="curved-text">
      <yl-svg-icon :icon="`yonex-legends_pilar-0${index}_subtitle-curved`"></yl-svg-icon>
    </div>

    <span class="yl-pillar-curved-text__number f-pillar-number"
          v-html="(index + 1)">
    </span>
  </section>
</template>

<script>
  export default {
    name: 'YLPillarCurvedText',

    props: {
      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-curved-text {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: $base-px * 20;
    height: $base-px * 20;

    @include breakpoint('medium-') {
      width: $base-px * 17;
      height: $base-px * 17;
    }

    @include breakpoint('small-') {
      width: $base-px * 14;
      height: $base-px * 14;
    }
  }

  .yl-pillar-curved-text__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: 100%;
    height: 100%;

    animation-name: turn-text;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .yl-pillar-curved-text__number {
    @include text-stroke(2px, "brand-green");
  }
</style>

<style lang="scss">
  @keyframes turn-text {
    0% {
      transform: translateX(-50%) translateY(-50%) rotate(0) translateZ(0);
    }
    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg) translateZ(0);
    }
  }
</style>
