<template>
  <section class="yl-background"
           :class="elClasses"
           :style="elStyle">

  </section>
</template>

<script>
  export default {
    name: 'YLBackground',

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-background {
    position: fixed;
    top: 0;
    right: 0;
    z-index: -1;

    width: 100vw;
    height: 100vh;

    background-image: url('~@/assets/images/yonex-legends_background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
