<template>
  <section class="yl-legends-legends u-pad-b-10"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legends-legends__name-container u-marg-x-auto">
      <div v-for="(legend, legendIndex) in legends"
           :key="`yl-legends-legends_name-${legendIndex}`"
           class="yl-legends-legends__name-container__item u-marg-t-2 f-body-subtitle-medium f-color-brand-white u-align-center"
           v-html="legend.name_formatted"
           @click="goTo(legendIndex)">
      </div>
    </div>

    <div class="yl-legends-legends__container u-marg-x-auto">
      <div v-for="(legend, legendIndex) in legends"
           :key="`yl-legends-legends_legend-${legendIndex}`"
           :ref="`yl-legends-legends_legend-${legendIndex}`"
           class="yl-legends-legends__legend u-marg-t-10">
        <div class="yl-legends-legends__legend__cover-container u-marg-x-auto">
          <yl-image-lazy-load :sources="images[legendIndex].bind" />
        </div>

        <h2 class="yl-legends-legends__legend__title f-page-title u-align-center">
          <yl-text-appearance :text="legend.name"></yl-text-appearance>
        </h2>

        <div class="u-marg-t-5 f-legends-information">
          <yl-appearance v-if="legend.born">
            <div>
              <span class="f-color-brand-cream"
                    v-html="legend.born.title">
              </span>&nbsp;<span class="f-color-brand-white"  v-html="legend.born.content"></span>
            </div>
          </yl-appearance>

          <div v-if="legend.awards && legend.awards.length > 0">
            <yl-appearance v-for="(award, awardIndex) in legend.awards"
                           :key="`yl-legends-legends_legends-${legendIndex}_award-${awardIndex}`"
                           klass="u-marg-t-1">
              <div>
                <div v-for="(line, lineIndex) in award"
                     :key="`yl-legends-legends_legends-${legendIndex}_award-${awardIndex}_line-${lineIndex}`"
                     :class="[ `f-color-${line.color}` ]"
                     v-html="line.content">
                </div>
              </div>
            </yl-appearance>
          </div>
        </div>

        <div class="yl-legends-legends__legend__video-container u-marg-t-5">
          <yl-appearance klass="yl-legends-legends__legend__video-wrapper">
            <div>
              <yl-video-loop-container :video="videos[legendIndex]"></yl-video-loop-container>
            </div>
          </yl-appearance>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getImage, getVideo } from '@/helpers/assets'
  import { scrollTo } from '@/helpers/scroll'

  // Components
  import YLVideoLoopContainer from '@/components/media/YLVideoLoopContainer'

  export default {
    name: 'YLLegendsLegends',

    components: {
      'yl-video-loop-container': YLVideoLoopContainer
    },

    props: {
      /**
       * Array w/ Legends
       * @param {Array} - *REQUIRED* legends
       **/
      legends: {
        type: Array,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      images () {
        const images = []

        this.legends.forEach(legend => {
          images.push(getImage({
            filename: `legends/yonex-legends_legends_${legend.id}_cover.png`
          }))
        })

        return images
      },

      videos () {
        const videos = []

        this.legends.forEach(legend => {
          videos.push(getVideo({
            filename: `yonex-legends_legends_${legend.id}_video`,
            formats: ['mp4']
          }))
        })

        return videos
      }
    },

    methods: {
      // HTML Events
      goTo (index) {
        const $ref = this.$refs[`yl-legends-legends_legend-${index}`][0]

        if ($ref) {
          const rect = $ref.getBoundingClientRect()

          scrollTo(rect.top + this.scrollTop)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legends-legends {

  }

  .yl-legends-legends__name-container {
    @include width-multi((
      xsmall: 4,
      small: 4,
      medium: 8
    ));
  }

  .yl-legends-legends__name-container__item {
    &:first-child {
      margin-top: 0;
    }
  }

  .yl-legends-legends__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12
    ));
  }

  .yl-legends-legends__legend {
    @include breakpoint('medium') {
      margin-top: $base-px * 20;

      &:first-child {
        margin-top: $base-px * 10;
      }
    }
  }

  .yl-legends-legends__legend__cover-container {
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 5
    ));
  }

  .yl-legends-legends__legend__title {
    position: relative;
    margin-top: -$base-px * 5;
    z-index: 2;

    @include text-stroke(1px, "brand-green");
  }

  .yl-legends-legends__legend__video-container {
    display: flex;
    justify-content: flex-end;
  }

  .yl-legends-legends__legend__video-wrapper {
    position: relative;

    @include width-multi((
      xsmall: 4,
      small: 4,
      medium: 6
    ));

    @include aspect-ratio(198, 111);
  }
</style>
