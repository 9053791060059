<template>
  <section class="yl-pillar-block-quote"
           :class="elClasses"
           :style="elStyle"
           @click="open">
    <div class="yl-pillar-block-quote__container">
      <blockquote class="f-quote f-color-brand-white u-align-center">
        <yl-appearance>
          <span class="f-color-brand-green">“</span>&nbsp;<span v-html="block.quote"></span>&nbsp;<span class="f-color-brand-green">”</span>
        </yl-appearance>
      </blockquote>

      <p class="u-marg-t-2 f-quote-author f-color-brand-green u-align-center">
        <yl-text-appearance :text="`- ${block.author}`"></yl-text-appearance>
      </p>

      <div v-if="isTabletDevice"
           class="yl-pillar-block-quote__button-container u-marg-t-4">
        <yl-round-button size="60px"
                         icon="plus"
                         @click="open">
        </yl-round-button>
      </div>
    </div>

    <div v-if="icon"
         class="yl-pillar-block-quote__icon-container">
      <yl-image-lazy-load :sources="icon.bind" />
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { getImage } from '@/helpers/assets'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLPillarBlockQUote',

    components: {
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.quote - quote for the block.
       * @param {Boolean} block.popupID - If quote open popup
       **/
      block: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        isHover: false,
        active: false
      }
    },

    computed: {
      icon () {
        if (this.block.icon) {
          return getImage({
            filename: this.block.icon
          })
        }

        return null
      },

      hasPopup () {
        let has = false

        if (this.block.popupID || this.block.popupID === 0) {
          has = true
        }

        return has
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      elClasses () {
        return [
          this.hasPopup ? 'has-popup' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      open () {
        if (this.hasPopup) {
          EventBus.$emit('YLPopups:open', this.block.popupID)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-quote {
    position: relative;

    display: flex;
    justify-content: center;

    &.has-popup {
      cursor: url('~@/assets/images/yonex-legends_cursor_plus.png'), auto;
    }
  }

  .yl-pillar-block-quote__container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6,
    ));
  }

  .yl-pillar-block-quote__button-container {
    display: flex;
    justify-content: center;
  }

  .yl-pillar-block-quote__icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));

    opacity: 0.3;

    .is-container-for-junior-development .is-block-index-4 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6,
      ));
    }

    .is-container-for-community-enrichment .is-block-index-5 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6,
      ));
    }

    .is-container-for-participation-discovery .is-block-index-4 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6,
      ));
    }

    .is-container-for-badminton-advancement .is-block-index-1 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6,
      ));
    }
  }
</style>
