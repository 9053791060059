import 'es6-promise/auto'
import Vue from 'vue'

import App from './App.vue'
import router from './router/router'
import store from './store'

// Components
import YLLazyLoad from '@/components/media/YLImageLazyLoad'
import YLMedia from '@/components/media/YLMedia'
import YLImage from '@/components/media/YLImage'
import YLIcon from '@/components/svg/YLSvgSIcon'
import YLText from '@/components/text/YLText'
import YLTextAppearance from '@/components/text/YLTextAppearance'
import YLAppearance from '@/components/elements/YLAppearance'

import { ObserveVisibility } from 'vue-observe-visibility'

require('intersection-observer')
require('@/polyfills/customEvent')

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production'

Vue.directive('observe-visibility', ObserveVisibility)
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },

  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

Vue.component('yl-image-lazy-load', YLLazyLoad)
Vue.component('yl-media', YLMedia)
Vue.component('yl-image', YLImage)
Vue.component('yl-svg-icon', YLIcon)
Vue.component('yl-text', YLText)
Vue.component('yl-text-appearance', YLTextAppearance)
Vue.component('yl-appearance', YLAppearance)

// Hack to fix ie11 bcg bug
if (navigator.userAgent.match(/Trident\/7\./)) {
  const fixScroll = (event) => {
    event.preventDefault()
    var wd = event.wheelDelta
    var csp = window.pageYOffset
    window.scrollTo(0, csp - wd)
  }

  document.addEventListener('app:in-menu', () => {
    document.body.removeEventListener('mousewheel', fixScroll)
  })

  document.addEventListener('app:out-menu', () => {
    document.body.addEventListener('mousewheel', fixScroll)
  })

  document.body.addEventListener('mousewheel', fixScroll)
}

const app = new Vue({
  router,
  store,
  render: h => h(App)
})

document.addEventListener('DOMContentLoaded', function () {
  app.$mount('#app')
})
