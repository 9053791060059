<template>
  <router-link class="yl-menu-pillars-item u-marg-t-4 u-marg-l-4"
               :class="`is-${pillar.to}`"
               :to="{ name: pillar.to }"
               @click.native="$emit('click', pillar.to)">
    <p class="yl-menu-pillars-item__title f-body-subtitle f-color-brand-cream"
        v-html="pillar.title">
    </p>

    <div class="yl-menu-pillars-item__image-container">
      <div class="yl-menu-pillars-item__image-wrapper">
        <img v-bind="image.bind" />
      </div>

      <div class="yl-menu-pillars-item__number f-pillar-menu-number"
            v-html="pillar.number">
      </div>
    </div>
  </router-link>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLMenuPillarsItem',

    props: {
      /**
       * Object of one pillar
       * @param {Object} - *REQUIRED* pillar
       * @param {String} - *REQUIRED* pillar.title: Title for pillar
       * @param {String} - *REQUIRED* pillar.image: URL of image
       * @param {String} - *REQUIRED* pillar.to: Name of the page (in Vue-Router)
       * @param {String | Number} - *REQUIRED* pillar.number: Number shown above the image
       **/
      pillar: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      },

      image () {
        return getImage({
          filename: this.pillar.image
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-menu-pillars-item {
    display: flex;
    flex-direction: column-reverse;
    width: calc((100% - (#{$base-px} * 4)) / 2);

    text-align: left;

    &:nth-child(-n + 2) {
      margin-top: 0;

      display: block;
    }

    &:nth-child(2n + 1) {
      margin-left: 0;

      text-align: right;
    }
  }

  .yl-menu-pillars-item__title {
    margin-top: $base-px * 2;

    .yl-menu-pillars-item:nth-child(-n + 2) & {
      margin-top: 0;
    }
  }

  .yl-menu-pillars-item__image-container {
    position: relative;
    transform: scale(1) translateZ(0);

    overflow: hidden;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-menu-pillars-item:nth-child(-n + 2) & {
      margin-top: $base-px * 2;
    }

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 171 * 213);
    }

    .yl-menu-pillars-item:hover & {
      transform: scale(0.8) translateZ(0);
    }
  }

  .yl-menu-pillars-item__image-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1) translateZ(0);

    width: 100%;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-menu-pillars-item:hover & {
      transform: translateX(-50%) translateY(-50%) scale(1.3) translateZ(0);
    }
  }

  .yl-menu-pillars-item__number {
    position: absolute;
    z-index: 2;

    @include text-stroke(1px, "brand-green");

    $offset: $base-px * 2;

    .yl-menu-pillars-item:nth-child(1) & {
      right: $offset;
      bottom: $offset;
    }

    .yl-menu-pillars-item:nth-child(2) & {
      bottom: $offset;
      left: $offset;
    }

    .yl-menu-pillars-item:nth-child(3) & {
      top: $offset;
      right: $offset;
    }

    .yl-menu-pillars-item:nth-child(4) & {
      top: $offset;
      left: $offset;
    }
  }
</style>
