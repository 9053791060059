import BaseRepository from './BaseRepository'
import AmbassadorsRepository from './AmbassadorsRepository'
import AmbassadorFeaturedRepository from './AmbassadorFeaturedRepository'
import EventsRepository from './EventsRepository'
import EventFeaturedRepository from './EventFeaturedRepository'
import LegaciesRepository from './LegaciesRepository'
import LegacyFeaturedRepository from './LegacyFeaturedRepository'

const repositories = {
  base: BaseRepository,
  ambassadors: AmbassadorsRepository,
  ambassador_featured: AmbassadorFeaturedRepository,
  events: EventsRepository,
  event_featured: EventFeaturedRepository,
  legacies: LegaciesRepository,
  legacy_featured: LegacyFeaturedRepository
}

export const RepositoryFactory = {
  get: name => repositories[name]
}
