<template>
  <div class="yl-pillar-sidebar-item u-cursor-pointer"
           :class="elClasses"
           :style="elStyle">
    <router-link class="yl-pillar-sidebar-item__link"
                 :to="{ name: to }">
      <div class="yl-pillar-sidebar-item__background"></div>

      <div class="yl-pillar-sidebar-item__border"></div>

      <span class="yl-pillar-sidebar-item__content f-pillar-sidebar-number f-color-brand-white u-align-center"
            v-html="(index + 1)">
      </span>
    </router-link>

    <div class="yl-pillar-sidebar-item__name f-pillar-sidebar-name f-color-brand-white">
      <span v-html="to.replace('-', ' ')"></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YLPillarSidebarItem',

    props: {
      /**
       *
       **/
      to: {
        type: String,
        required: true
      },

      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      },

      /**
       *
       **/
      active: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      elClasses () {
        return [
          this.active ? 'is-active' : ''
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-sidebar-item {
    position: relative;
    margin-top: $base-px * 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: $base-px * 3;
    height: $base-px * 3;

    &:first-child {
      margin-top: 0;
    }
  }

  .yl-pillar-sidebar-item__link {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .yl-pillar-sidebar-item__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(1) translateZ(0);

    width: 100%;
    height: 100%;

    border-radius: 50%;
    background-color: map-get($colors-list, "brand-cream");
    opacity: 0;
    visibility: hidden;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-pillar-sidebar-item:hover & {
      transform: scale(1.3) translateZ(0);
    }

    .is-active & {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-pillar-sidebar-item__border {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(1) translateZ(0);

    width: 100%;
    height: 100%;

    border-radius: 50%;
    border: 1px solid map-get($colors-list, "brand-white");
    opacity: 0.3;

    transition: transform 0.45s $ease-out-quint, opacity 0.45s $ease-out-quint;
    will-change: transform, opacity;

    .yl-pillar-sidebar-item:hover & {
      transform: scale(1.3) translateZ(0);

      opacity: 1;
    }

    .is-active & {
      border: 1px solid map-get($colors-list, "brand-cream");
    }
  }

  .yl-pillar-sidebar-item__content {
    position: relative;
    z-index: 2;

    .is-active & {
      color: map-get($colors-list, "brand-black");
    }
  }

  .yl-pillar-sidebar-item__name {
    position: absolute;
    top: 50%;
    right: -$base-px * 1;
    transform: translateX(100%) translateY(-50%) translateZ(0);

    opacity: 0;
    visibility: hidden;

    transition: transform 0.45s $ease-out-quint, opacity 0.45s $ease-out-quint;
    will-change: transform, opacity;

    .yl-pillar-sidebar-item:hover & {
      transform: translateX(100%) translateX($base-px) translateY(-50%) translateZ(0);

      opacity: 1;
      visibility: visible;
    }
  }
</style>
