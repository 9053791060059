<template lang="html">
  <section class="yl-loader"
           :class="elClasses">
    <div ref="container"
         class="yl-loader__container u-marg-x-auto">
      <canvas ref="canvas"
              class="yl-loader__canvas">
      </canvas>

      <div ref="illustration"
           class="yl-loader__illustration-container">
        <img v-bind="illustration.bind" />
      </div>
    </div>
  </section>
</template>

<script>
  // Mixins
  import BaseMixin from '@/mixins/base'

  // Store
  import { GETTERS as G, STATE as S } from '@/store/helpers'

  // Behavior
  import RAF from '@/behavior/RAF'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { getImage } from '@/helpers/assets'

  // Data
  import loaderData from '@/data/loader'

  export default {
    name: 'YLLoader',

    mixins: [BaseMixin],

    data () {
      return {
        isVisible: true,
        content: loaderData(),
        startedAt: null
      }
    },

    computed: {
      elClasses () {
        return [
          this.isVisible ? 'is-visible' : 'is-hidden'
        ]
      },

      numberOfContentFetched () {
        return this.$store.state[S.numberOfContentFetched]
      },

      numberOfContentToFetch () {
        return this.$store.state[S.numberOfContentToFetch]
      },

      illustration () {
        return getImage({
          filename: 'loader/yonex-legends_loader_illustration.png'
        })
      },

      images () {
        return this.$store.getters[G.images]
      }
    },

    created () {
      // Variables
      this.hidden = false
      this.angle = -Math.PI / 2

      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.minimumDuration = 0 // in ms
      this.startedAt = new Date()

      this.$nextTick(() => {
        this._initCanvas()

        this._onResize()

        this.startRAF()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)

      this.stopRAF()
    },

    methods: {
      // Events
      hide () {
        this.hidden = true
        const now = new Date()
        const waitingTime = this.minimumDuration - (now - this.startedAt)

        setTimeout(() => {
          this.isVisible = false

          this.$emit('loaded')
        }, waitingTime)
      },

      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)

        if (this.canvas) {
          this.canvas.width = this.$refs['container'].offsetWidth
          this.canvas.height = this.$refs['container'].offsetHeight
        }
      },

      // Canvas
      _initCanvas () {
        this.canvas = this.$refs['canvas']
        this.context = this.canvas.getContext('2d')
      },

      _drawCircles () {
        const ratio = this.numberOfContentFetched / this.numberOfContentToFetch
        const angleMax = Math.PI * 2
        const angleMin = -Math.PI / 2
        const angle = Math.round(((angleMax - angleMin) * ratio + angleMin) * 100) / 100
        this.angle += (angle - this.angle) * 0.05

        if (Math.abs(angle - this.angle) < 0.05 && ratio >= 1) {
          this.hide()
        }

        if (this.$refs['illustration']) {
          const rotate = (360 - 0) * ratio + 0
          this.$refs['illustration'].style.transform = `translateX(-50%) translateY(-50%) rotate(${rotate}deg) translateZ(0)`
        }

        this.context['imageSmoothingEnabled'] = false /* standard */
        this.context['mozImageSmoothingEnabled'] = false /* Firefox */
        this.context['oImageSmoothingEnabled'] = false /* Opera */
        this.context['webkitImageSmoothingEnabled'] = false /* Safari */
        this.context['msImageSmoothingEnabled'] = false

        this.context.globalAlpha = 0.4
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.context.beginPath()
        this.context.arc(this.canvas.width / 2, this.canvas.height / 2, this.canvas.width / 2 / 100 * 99, angleMin, angleMax)
        this.context.lineWidth = this.windowW > 450 ? 4 : 2
        this.context.strokeStyle = '#E9F3ED'
        this.context.stroke()

        this.context.globalAlpha = 1
        this.context.beginPath()
        this.context.arc(this.canvas.width / 2, this.canvas.height / 2, this.canvas.width / 2 / 100 * 99, angleMin, this.angle)
        this.context.lineWidth = this.windowW > 450 ? 4 : 2
        this.context.strokeStyle = '#E9F3ED'
        this.context.stroke()
      },

      // RAF
      rafCallback () {
        if (!this.hidden) {
          this._drawCircles()
        } else {
          this.stopRAF()
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
    transform: translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    opacity: 1;
    visibility: visible;
    background-color: map-get($colors-list, "brand-black");

    transition: opacity 1s $ease-out-quint, visibility 1s;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  .yl-loader__container {
    position: relative;

    @include width-multi((
      xsmall: 4,
      small: 4,
      medium: 4,
      large: 4,
      xlarge: 4
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: 100%;
    }
  }

  .yl-loader__canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(4) translateZ(0);

    width: 100%;
    height: 100%;

    transition: transform 1s $ease-out-quint;
    will-change: transform;

    .is-visible & {
      transform: translateZ(0);
    }
  }

  .yl-loader__illustration-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(0deg) translateZ(0);
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;

    transition: transform 0.5s $ease-out-quint;
    will-change: transform;
  }
</style>
