<template>
  <section class="yl-pillar-block-video"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-block-video__container">
      <youtube ref="youtube"
               class="yl-pillar-block-video__player"
               width="100%"
               height="100%"
               :video-id="block.youtubeID">
      </youtube>

      <div class="yl-pillar-block-video__wrapper u-cursor-pointer"
           @click="playVideo">
        <div class="yl-pillar-block-video__cover"
             :style="coverStyle">
        </div>

        <div class="yl-pillar-block-video__overlay"></div>

        <div class="yl-pillar-block-video__content">
          <div class="f-color-brand-black">
            <yl-appearance>
              <yl-round-button size="100px"
                               icon="play"
                               @click="playVideo">
              </yl-round-button>
            </yl-appearance>
          </div>

          <div class="yl-pillar-block-video__subtitle-container u-marg-t-3">
            <p class="f-body-subtitle f-color-brand-cream u-align-center">
              <yl-text-appearance :text="block.subtitle"
                                 :transition-delay="15">
              </yl-text-appearance>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLPillarBlockVideo',

    components: {
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.subtitle - Subtitle for the block.
       * @param {String} block.youtubeID - Video's ID on YouTube.
       * @param {String} block.image - Cover image before playing.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        isPlaying: false
      }
    },

    computed: {
      player () {
        return this.$refs.youtube.player
      },

      elClasses () {
        return [
          this.isPlaying ? 'is-playing' : ''
        ]
      },

      elStyle () {
        return {}
      },

      coverStyle () {
        return {
          'background-image': `url(${getImage({
            filename: this.block.image
          }).bind.src})`
        }
      }
    },

    methods: {
      // HTML Events
      playVideo () {
        this.isPlaying = true
        this.player.playVideo()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-video {
    display: flex;
    justify-content: center;
  }

  .yl-pillar-block-video__container {
    position: relative;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 734 * 413);
    }
  }

  .yl-pillar-block-video__wrapper {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    transform: translateZ(0);
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 2px);
    height: calc(100% + 2px);

    opacity: 1;
    visibility: visible;

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: opacity, visibility;

    .is-playing & {
      opacity: 0;
      visibility: hidden;
    }
  }

  .yl-pillar-block-video__cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;
    height: 100%;

    opacity: 1;
    visibility: visible;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: opacity, visibility;

    .is-playing & {
      opacity: 0;
      visibility: hidden;
    }
  }

  .yl-pillar-block-video__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 2;

    width: 100%;
    height: 100%;

    opacity: 0.65;
    visibility: visible;
    background-color: map-get($colors-list, "brand-black");

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: opacity, visibility;

    .is-playing & {
      opacity: 0;
      visibility: hidden;
    }
  }

  .yl-pillar-block-video__content {
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 4,
      xlarge: 4,
    ));
  }

  .yl-pillar-block-video__subtitle-container {
    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 6
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 2;
    }
  }
</style>

<style lang="scss">
  .yl-pillar-block-video__player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: 100%;
  }
</style>
