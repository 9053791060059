<template>
  <section class="yl-legacies-list"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legacies-list__container u-marg-x-auto">
      <yl-legacies-list-category v-for="(category, categoryIndex) in categories"
                                :key="`yl-legacies-list_category-${categoryIndex}`"
                                :ref="`yl-legacies-list_category-${categoryIndex}`"
                                :category="category"
                                :active-legacy="activeLegacy !== null && activeLegacy[0] === categoryIndex ? activeLegacy : null"
                                @click="click($event, categoryIndex)">
      </yl-legacies-list-category>
    </div>
  </section>
</template>

<script>
  // Components
  import YLLegaciesListCategory from './YLLegaciesListCategory'

  export default {
    name: 'YLLegaciesList',

    components: {
      'yl-legacies-list-category': YLLegaciesListCategory
    },

    props: {
      /**
       * Array of categories
       * @param {Array} - *REQUIRED* categories: Array of categories
       **/
      categories: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        activeLegacy: null
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      click (legacyIndex, categoryIndex) {
        if (this.activeLegacy !== null && categoryIndex === this.activeLegacy[0] && legacyIndex === this.activeLegacy[1]) {
          this.activeLegacy = null
        } else {
          this.activeLegacy = [ categoryIndex, legacyIndex ]
        }
      },

      _getCategoriesBoundingRect () {
        const rects = []

        this.categories.forEach((category, categoryIndex) => {
          const $ref = this.$refs[`yl-legacies-list_category-${categoryIndex}`][0]

          if ($ref) {
            rects.push({
              slug: category.slug,
              rect: $ref._getBoundingRect()
            })
          }
        })

        return rects
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-list {
    display: flex;
    justify-content: center;

    $colspan: colspan(1.5);
    width: calc(100% - #{$colspan});

    @include breakpoint('medium-') {
      width: 100%;
    }
  }

  .yl-legacies-list__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 7,
      xlarge: 7
    ));
  }
</style>
