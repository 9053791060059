<template>
  <section class="yl-events-list"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-events-list__container">
      <div v-if="filter === null"
           class="yl-events-list__next-event-container">
        <p class="f-body-subtitle-medium f-color-brand-cream"
           v-html="content.upNextLabel">
        </p>

        <div class="yl-events-list__event-wrapper u-marg-t-3">
          <yl-appearance v-if="nextEvent"
                         :block="true">
            <yl-events-list-next-item :event="nextEvent"
                                      :content="content">
            </yl-events-list-next-item>
          </yl-appearance>

          <div v-else
               class="f-event-h2 f-color-brand-green">
            <yl-text-appearance :text="content.noNextEventMessage"></yl-text-appearance>
          </div>
        </div>
      </div>

      <div class="yl-events-list__events-container u-marg-t-11">
        <p class="f-body-subtitle-medium f-color-brand-cream">
          <yl-text-appearance :text="content.allLabel"></yl-text-appearance>
        </p>

        <div class="yl-events-list__events-wrapper u-marg-t-3">
          <yl-events-list-item v-for="(event, eventIndex) in eventsLoaded"
                               :key="`yl-events-list_event-${eventIndex}`"
                               :event="event"
                               :content="content">
          </yl-events-list-item>
        </div>
      </div>

      <div v-if="!allEventsAreLoaded"
           class="yl-events-list__button-container u-marg-t-10 u-align-center">
        <yl-cta class="yl-ambassador-featured__button"
                :label="content['loadMoreLabel']"
                background-color="brand-white"
                @click="loadMore">
        </yl-cta>
      </div>
    </div>
  </section>
</template>

<script>
  // Components
  import YLEventsListNextItem from './YLEventsListNextItem'
  import YLEventsListItem from './YLEventsListItem'
  import YLCTA from '@/components/elements/YLCTA'

  export default {
    name: 'YLEventsList.',

    components: {
      'yl-events-list-next-item': YLEventsListNextItem,
      'yl-events-list-item': YLEventsListItem,
      'yl-cta': YLCTA
    },

    props: {
      /**
       * Array of past events
       * @param {Array} - pastEvents
       **/
      pastEvents: {
        type: Array,
        default: () => []
      },

      /**
       * Object for next event
       * @param {Object} - nextEvent
       **/
      nextEvent: {
        type: Object,
        default: null
      },

      /**
       * Array of next events
       * @param {Array} - nextEvents
       **/
      nextEvents: {
        type: Array,
        default: () => []
      },

      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content
       **/
      content: {
        type: Object,
        required: true
      },

      /**
       * Object or null for filter
       * @param {Object | null} - *REQUIRED* filter
       **/
      filter: {
        type: null,
        default: null
      }
    },

    data () {
      return {
        indexOfEventsLoaded: 1,
        numberOfItemsStep: 9
      }
    },

    computed: {
      events () {
        const nextEvents = this.nextEvents && this.nextEvents.length > 0 ? this.nextEvents : []
        const pastEvents = this.pastEvents && this.pastEvents.length > 0 ? this.pastEvents : []
        return nextEvents.concat(pastEvents)
      },

      eventsFiltered () {
        const events = this.events.filter((e, i) => this.filter === null ? true : e.ACF.category.term_id === this.filter)

        let indexes = {}
        events.forEach(event => {
          if (indexes.hasOwnProperty(event.ACF.category.slug)) {
            indexes[event.ACF.category.slug] += 1
            event.idInCategory = indexes[event.ACF.category.slug]
          } else {
            indexes[event.ACF.category.slug] = 0
            event.idInCategory = indexes[event.ACF.category.slug]
          }
        })

        return events
      },

      eventsLoaded () {
        const events = []

        let numberOfEventsToLoad = this.numberOfItemsStep * this.indexOfEventsLoaded
        if (numberOfEventsToLoad > this.eventsFiltered.length) {
          numberOfEventsToLoad = this.eventsFiltered.length
        }
        for (let i = 0; i < numberOfEventsToLoad; i += 1) {
          events.push(this.eventsFiltered[i])
        }

        return events
      },

      allEventsAreLoaded () {
        return this.numberOfItemsStep * this.indexOfEventsLoaded >= this.eventsFiltered.length
      },

      elClasses () {
        return [
          `has-filter-${this.filter}`
        ]
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      loadMore () {
        this.indexOfEventsLoaded += 1
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-list {
    display: flex;
    justify-content: center;
    $colspan: colspan(1.5);
    width: calc(100% - #{$colspan});

    @include breakpoint('medium-') {
      margin-top: $base-px * 5;
      margin-right: auto;
      margin-left: auto;

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12
      ));
    }
  }

  .yl-events-list__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 7
    ));
  }

  .yl-events-list__events-container {
    .yl-events-list:not(.has-filter-null) & {
      margin-top: 0;

      @include breakpoint('medium-') {
        margin-top: 0;
      }
    }

    @include breakpoint('medium-') {
      margin-top: $base-px * 7;
    }
  }

  .yl-events-list__events-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .yl-events-list__button-container {
    @include breakpoint('medium-') {
      margin-top: $base-px * 5;
    }
  }
</style>
