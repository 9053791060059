<template>
  <section v-if="ambassadors !== null"
           class="yl-ambassadors-list-with-map"
           :class="elClasses"
           :style="elStyle"
           v-observe-visibility="visibilityOpts">
    <div class="yl-ambassadors-list-with-map__container u-marg-x-auto">
      <yl-ambassadors-list ref="list"></yl-ambassadors-list>

      <yl-ambassadors-map :content="content"
                          @change="change"
                          @clear="clear">
      </yl-ambassadors-map>
    </div>
  </section>
</template>

<script>
  // Mixins
  import visibilityMixin from '@/mixins/visibility'

  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { scrollTo } from '@/helpers/scroll'

  // Components
  import YLAmbassadorsList from './YLAmbassadorsList'
  import YLAmbassadorsMap from './YLAmbassadorsMap'

  export default {
    name: 'YLAmbassadorsListWithMap',

    mixins: [visibilityMixin],

    components: {
      'yl-ambassadors-list': YLAmbassadorsList,
      'yl-ambassadors-map': YLAmbassadorsMap
    },

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* header.title: Title used for the map
       * @param {String} - *REQUIRED* header.select-label: Label used for countries selection
       **/
      content: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        vRootMargin: this.rootMargin,
        vThresold: 0.1,
        visible: false
      }
    },

    computed: {
      ambassadors () {
        return this.$store.state[S.ambassadors]
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      elClasses () {
        return [
          this.visible ? 'is-visible' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      change (event) {
        this.$refs['list'].change(event)

        scrollTo(this.$el.getBoundingClientRect().top + this.scrollTop - 60 - this.headerHeight - 60)
      },

      clear () {
        this.$refs['list'].clear()
      },

      // Visibility
      isVisibleCallback () {
        this.visible = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassadors-list-with-map__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include breakpoint('medium-') {
      flex-direction: column-reverse;
    }
  }
</style>
