// Repositories
import { RepositoryFactory } from '@/repositories/RepositoryFactory.js'

// Models
import EventModel from '@/models/EventModel'
import EventFeaturedModel from '@/models/EventFeaturedModel'

// Repositories
const Events = RepositoryFactory.get('events')
const EventFeatured = RepositoryFactory.get('event_featured')

export const fetchEvents = async () => {
  try {
    const response = await Events.get()
    const data = response && response.hasOwnProperty('data') ? response.data : null

    if (data !== null) {
      const events = data.map(event => {
        const Event = new EventModel()
        Event.setProperties(event)

        return Event
      })

      return events
    } else {
      return null
    }
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data
    } else {
      throw e.toJSON()
    }
  }
}

export const fetchEventFeatured = async () => {
  try {
    const response = await EventFeatured.get()
    const data = response && response.hasOwnProperty('data') ? response.data : null

    if (data !== null) {
      const events = data.map(event => {
        const Event = new EventFeaturedModel()
        Event.setProperties(event)

        return Event
      })

      return events[0]
    } else {
      return null
    }
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data
    } else {
      throw e.toJSON()
    }
  }
}
