<template>
  <section class="yl-legends-header"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legends-header__container">
      <div class="yl-legends-header__image-container">
        <img v-bind="image.bind" />
      </div>
      <h1 class="yl-legends-header__title f-page-title u-align-center"
          v-html="header.title">
      </h1>

      <h3 class="yl-legends-header__subtitle u-marg-t-3 f-body-big f-color-brand-brown u-align-center u-marg-x-auto"
          v-html="header.description">
      </h3>
    </div>

    <div v-if="illustration"
         class="yl-legends-header__illustration-container">
      <img v-bind="illustration.bind" />
    </div>
  </section>
</template>

<script>
  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLLegendsHeader',

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content
       **/
      header: {
        type: Object,
        required: true
      }
    },

    computed: {
      illustration () {
        return getImage({
          filename: 'legends/yonex-legends_legends_landing_icon.png'
        })
      },

      image () {
        return getImage({
          filename: 'legends/yonex-legends_legends_5-legends.png'
        })
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legends-header {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    overflow: hidden;
  }

  .yl-legends-header__container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));
  }

  .yl-legends-header__title {
    @include text-stroke(1px, "brand-green");
  }

  .yl-legends-header__subtitle {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 8,
      large: 8,
      xlarge: 8,
    ));
  }

  .yl-legends-header__illustration-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: 100%;

    opacity: 0.2;

    @include breakpoint('small-') {
      width: 200%;
    }
  }
</style>
