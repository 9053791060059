<template>
  <section class="yl-pillar-block-case-study"
           :class="elClasses"
           :style="elStyle">
    <div v-if="block.layout === 'chapeau-two-images'"
         class="yl-pillar-block-case-study__container">
      <div class="yl-pillar-block-case-study__wrapper">
        <p class="f-body-subtitle f-color-brand-cream">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
        </p>

        <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
          <yl-text-appearance :text="block.h4"></yl-text-appearance>
        </h4>

        <p class="u-marg-t-5 f-pillar-case-study-chapeau f-color-brand-cream">
          <yl-text-appearance :text="block.chapeau"></yl-text-appearance>
        </p>

        <div v-if="block.button" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>

        <div v-if="icon"
             class="yl-block-pillar-case-study__icon-container u-marg-t-7">
          <yl-image-lazy-load :sources="icon.bind" />
        </div>
      </div>

      <div class="yl-pillar-block-case-study__medias">
        <div v-for="(media, mediaIndex) in medias"
             :key="`yl-pillar-block-case-study-media-${mediaIndex}`"
             class="yl-pillar-block-case-study__media-container u-marg-t-4">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="media.bind" />
        </div>
      </div>
    </div>

    <div v-else-if="block.layout === 'text-two-images'"
         class="yl-pillar-block-case-study__container">
      <div class="yl-pillar-block-case-study__wrapper">
        <p class="f-body-subtitle f-color-brand-brown">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
        </p>

        <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
          <yl-text-appearance :text="block.h4"></yl-text-appearance>
        </h4>

        <p class="yl-pillar-block-case-study__text-container u-marg-t-5 f-body-small f-color-brand-white">
          <yl-text-appearance :text="text"></yl-text-appearance>
        </p>

        <div v-if="block.button && !isTabletDevice" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>

        <div v-if="icon && !isTabletDevice"
             class="yl-block-pillar-case-study__icon-container u-marg-t-7">
          <yl-image-lazy-load :sources="icon.bind" />
        </div>
      </div>

      <div class="yl-pillar-block-case-study__medias">
        <div v-for="(media, mediaIndex) in medias"
             :key="`yl-pillar-block-case-study-media-${mediaIndex}`"
             class="yl-pillar-block-case-study__media-container u-marg-t-4">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="media.bind" />
        </div>

        <div v-if="block.button && isTabletDevice" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>

        <div v-if="icon && isTabletDevice"
             class="yl-block-pillar-case-study__icon-container u-marg-t-7">
          <yl-image-lazy-load :sources="icon.bind" />
        </div>
      </div>
    </div>

    <div v-else-if="block.layout === 'medium-image'"
         class="yl-pillar-block-case-study__container">
      <div class="yl-pillar-block-case-study__medias">
        <div v-for="(media, mediaIndex) in medias"
             :key="`yl-pillar-block-case-study-media-${mediaIndex}`"
             class="yl-pillar-block-case-study__media-container u-marg-t-4">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="media.bind" />
        </div>
      </div>

      <div class="yl-pillar-block-case-study__wrapper">
        <p class="f-body-subtitle f-color-brand-cream">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
        </p>

        <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
          <yl-text-appearance :text="block.h4"></yl-text-appearance>
        </h4>

        <p class="yl-pillar-block-case-study__text-container u-marg-t-5 f-body-small f-color-brand-white">
          <yl-text-appearance :text="text"></yl-text-appearance>
        </p>

        <div v-if="block.button" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>

        <div v-if="icon"
             class="yl-block-pillar-case-study__icon-container u-marg-t-7">
          <yl-image-lazy-load :sources="icon.bind" />
        </div>
      </div>
    </div>

    <div v-else-if="block.layout === 'text-three-images'"
         class="yl-pillar-block-case-study__container">
      <div class="yl-pillar-block-case-study__medias">
        <div v-for="(media, mediaIndex) in medias"
             :key="`yl-pillar-block-case-study-media-${mediaIndex}`"
             class="yl-pillar-block-case-study__media-container u-marg-t-4">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="media.bind" />
        </div>

        <div v-if="block.button && isTabletDevice" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>
      </div>

      <div class="yl-pillar-block-case-study__wrapper">
        <p class="f-body-subtitle f-color-brand-cream">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
        </p>

        <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
          <yl-text-appearance :text="block.h4"></yl-text-appearance>
        </h4>

        <p class="yl-pillar-block-case-study__text-container u-marg-t-5 f-body-small f-color-brand-white">
          <yl-text-appearance :text="text"></yl-text-appearance>
        </p>

        <div v-if="block.button && !isTabletDevice" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>

        <div v-if="icon"
             class="yl-block-pillar-case-study__icon-container u-marg-t-7">
          <yl-image-lazy-load :sources="icon.bind" />
        </div>
      </div>
    </div>

    <div v-else-if="block.layout === 'big-image'"
         class="yl-pillar-block-case-study__container">
      <div class="yl-pillar-block-case-study__wrapper">
        <div class="yl-pillar-block-case-study__content">
          <p class="f-body-subtitle f-color-brand-cream">
            <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
          </p>

          <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
            <yl-text-appearance :text="block.h4"></yl-text-appearance>
          </h4>

          <p class="yl-pillar-block-case-study__text-container u-marg-t-5 f-body-small f-color-brand-white">
            <yl-text-appearance :text="text"></yl-text-appearance>
          </p>

          <div v-if="block.button" class="yl-pillar-block-case-study__button-container u-marg-t-3">
            <yl-appearance klass="yl-pillar-block-case-study__button">
              <yl-cta :label="block.button.label"
                      :to="{ name: block.button.to }">
              </yl-cta>
            </yl-appearance>
          </div>

          <div v-if="icon"
               class="yl-block-pillar-case-study__icon-container u-marg-t-7">
            <yl-image-lazy-load :sources="icon.bind" />
          </div>
        </div>
      </div>

      <div class="yl-pillar-block-case-study__medias">
        <div v-for="(media, mediaIndex) in medias"
             :key="`yl-pillar-block-case-study-media-${mediaIndex}`"
             class="yl-pillar-block-case-study__media-container u-marg-t-4">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="media.bind" />
        </div>
      </div>
    </div>

    <div v-else-if="block.layout === 'image-icon'"
         class="yl-pillar-block-case-study__container">
      <div class="yl-pillar-block-case-study__header">
        <div class="yl-pillar-block-case-study__content">
          <p class="f-body-subtitle f-color-brand-cream">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
          </p>

          <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
            <yl-text-appearance :text="block.h4"></yl-text-appearance>
          </h4>
        </div>

        <div v-if="icon"
             class="yl-pillar-block-case-study__icon-container">
          <yl-image-lazy-load :sources="icon.bind" />
        </div>
      </div>

      <div class="yl-pillar-block-case-study__content-wrapper u-marg-t-5">
        <div class="yl-pillar-block-case-study__medias">
          <div v-for="(media, mediaIndex) in medias"
               :key="`yl-pillar-block-case-study-media-${mediaIndex}`"
               class="yl-pillar-block-case-study__media-container u-marg-t-4">
            <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                                :sources="media.bind" />
          </div>
        </div>

        <div class="yl-pillar-block-case-study__content">
          <p class="f-body-small f-color-brand-white">
          <yl-text-appearance :text="text"></yl-text-appearance>
          </p>

          <div v-if="block.button" class="yl-pillar-block-case-study__button-container u-marg-t-3">
            <yl-appearance klass="yl-pillar-block-case-study__button">
              <yl-cta :label="block.button.label"
                      :to="{ name: block.button.to }">
              </yl-cta>
            </yl-appearance>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="block.layout === 'video'"
         class="yl-pillar-block-case-study__container">
      <div v-if="icon"
            class="yl-block-pillar-case-study__icon-container">
        <yl-image-lazy-load :sources="icon.bind" />
      </div>

      <div class="yl-pillar-block-case-study__wrapper">
        <p class="f-body-subtitle f-color-brand-cream">
          <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
        </p>

        <h4 class="u-marg-t-1 f-h4 f-color-brand-cream">
          <yl-text-appearance :text="block.h4"></yl-text-appearance>
        </h4>

        <p class="yl-pillar-block-case-study__text-container u-marg-t-5 f-body-small f-color-brand-white">
          <yl-text-appearance :text="text"></yl-text-appearance>
        </p>

        <div v-if="block.button" class="yl-pillar-block-case-study__button-container u-marg-t-3">
          <yl-appearance klass="yl-pillar-block-case-study__button">
            <yl-cta :label="block.button.label"
                    :to="{ name: block.button.to }">
            </yl-cta>
          </yl-appearance>
        </div>
      </div>

      <div class="yl-pillar-block-case-study__video-container u-marg-t-10 u-marg-x-auto">
        <div v-if="iconAfter"
              class="yl-block-pillar-case-study__icon-after-container">
          <yl-image-lazy-load :sources="iconAfter.bind" />
        </div>

        <yl-pillar-block-video :block="block.video"></yl-pillar-block-video>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLCTA from '@/components/elements/YLCTA'
  import YLPillarBlockVideo from './YLPillarBlockVideo'

  export default {
    name: 'YLPillarBlockCaseStudy',

    components: {
      'yl-cta': YLCTA,
      'yl-pillar-block-video': YLPillarBlockVideo
    },

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.h4 - h4 for the block.
       * @param {String} block.subtitle - subtitle for the block.
       * @param {String} block.chapeau - chapeau for the block.
       * @param {String} block.text - text for the block.
       * @param {String} block.button - button's label for the block.
       * @param {String} block.layout - layout of the block.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    computed: {
      medias () {
        const medias = []

        if (this.block.medias) {
          this.block.medias.forEach(filename => {
            medias.push(getImage({
              filename
            }))
          })
        }

        return medias
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      text () {
        return this.block.text ? this.block.text.replace(/<green>/g, '<span class="is-green">').replace(/<\/green>/g, '</span>') : null
      },

      icon () {
        return this.block.icon ? getImage({
          filename: this.block.icon
        }) : null
      },

      iconAfter () {
        return this.block['icon-after'] ? getImage({
          filename: this.block['icon-after']
        }) : null
      },

      elClasses () {
        return [
          `has-${this.block.layout}`
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-case-study {

  }

  .yl-pillar-block-case-study__container {
    position: relative;

    .has-chapeau-two-images &,
    .has-text-two-images &,
    .has-text-three-images &,
    .has-medium-image & {
      display: flex;
    }

    .has-text-two-images &,
    .has-text-three-images &,
    .has-medium-image & {
      justify-content: space-between;
    }

    .has-text-two-images & {
      @include breakpoint('medium-') {
        flex-direction: column;
      }
    }

    .has-text-three-images & {
      @include breakpoint('medium-') {
        flex-direction: column-reverse;
      }
    }

    .has-medium-image & {
      @include breakpoint('medium-') {
        flex-direction: column-reverse;
      }
    }
  }

  .yl-pillar-block-case-study__wrapper {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 5,
      xlarge: 5,
    ));

    .has-text-two-images &,
    .has-image-icon &,
    .has-text-three-images & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 4,
        xlarge: 4,
      ));
    }

    .has-video & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6,
      ));
    }

    .has-big-image & {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }

  .yl-pillar-block-case-study__header {
    position: relative;

    display: flex;
    justify-content: flex-end;
  }

  .yl-pillar-block-case-study__icon-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 6,
      large: 4,
      xlarge: 4,
    ));

    .is-container-for-participation-discovery .is-block-index-6 & {
      @include breakpoint('medium-') {
        display: none;
      }
    }
  }

  .yl-pillar-block-case-study__content {
    position: relative;
    z-index: 2;

    .has-big-image & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 4,
        xlarge: 4,
      ));
    }

    .has-image-icon & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 4,
        xlarge: 4,
      ));
    }

    .is-container-for-participation-discovery .is-block-index-6 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }
  }

  .yl-pillar-block-case-study__button-container {
    display: flex;

    @include breakpoint('medium-') {
      justify-content: center;
    }
  }

  .yl-block-pillar-case-study__icon-container {
    @include width-multi((
      xsmall: 4,
      small: 4,
      medium: 4,
      large: 4,
      xlarge: 4
    ));

    .has-text-two-images & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
        margin-right: auto;
        margin-left: auto;
      }
    }

    .is-container-for-badminton-advancement .is-block-index-3 & {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-50%) translateY($base-px * 5) translateZ(0);

      @include width-multi((
        xsmall: 4,
        small: 4,
        medium: 8,
        large: 4,
        xlarge: 4
      ));

      opacity: 0.4;
    }
  }

  .yl-pillar-block-case-study__content-wrapper {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: space-between;

    .is-container-for-participation-discovery .is-block-index-6 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;

        flex-direction: column;
      }
    }
  }

  .yl-pillar-block-case-study__medias {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 4,
      xlarge: 4,
    ));

    .has-text-two-images & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .has-text-three-images & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .has-medium-image & {
      margin-top: $base-px * 8 * -1;

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 3,
        xlarge: 3,
      ));

      @include breakpoint('medium-') {
        margin-top: $base-px * 5;
      }
    }

    .has-big-image & {
      margin-top: $base-px * 13 * -1;

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6,
      ));

      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .has-video & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 7,
        xlarge: 7,
      ));
    }
  }

  .yl-pillar-block-case-study__media-container {
    @include breakpoint('medium-') {
      margin-top: $base-px * 3;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  .yl-pillar-block-case-study__text-container {
    @include breakpoint('medium-') {
      margin-top: $base-px * 3;
    }
  }

  .yl-pillar-block-case-study__video-container {
    position: relative;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));
  }

  .yl-block-pillar-case-study__icon-after-container {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%) translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 1,
      small: 1,
      medium: 2,
      large: 1,
      xlarge: 1
    ));

    opacity: 0.4;

    .is-container-for-badminton-advancement .is-block-index-3 & {
      @include breakpoint('medium-') {
        transform: translateX(25%) translateY(-50%) translateZ(0);
      }

      @include breakpoint('small-') {
        transform: translateX(-100%) translateY(-50%) translateZ(0);
      }
    }
  }
</style>

<style lang="scss">
  .yl-pillar-block-case-study {
    & .is-green {
      color: map-get($colors-list, "brand-green");
    }
  }
</style>
