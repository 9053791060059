// Mixins
import GTMMixin from '@/mixins/gtm'

export default {
  mixins: [GTMMixin],

  computed: {

  },

  created () {
    // console.log(`${this.$options.name}:created`)
  },

  mounted () {
    // console.log(`${this.$options.name}:mounted`)
  },

  beforeDestroy () {
    // console.log(`${this.$options.name}:beforeDestroy`)
  }
}
