<template>
  <section class="yl-scroll-h-container"
           :style="elStyle">
    <slot></slot>
  </section>
</template>

<script>
  // Behavior
  import RAF from '@/behavior/RAF'

  // Helpers
  import { STATE as S, GETTERS as G } from '@/store/helpers'
  import { clampNumber } from '@/helpers/maths'
  import { EventBus } from '@/helpers/event-bus'

  export default {
    name: 'YLScrollHContainer',

    data () {
      return {
        scroll: 0,
        scrollEased: 0
      }
    },

    computed: {
      support () {
        return this.$store.getters[G.support]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      windowW () {
        return this.$store.state[S.windowW]
      },

      isTouchDevice () {
        return this.$store.state[S.isTouchDevice]
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      elStyle () {
        return {
          // 'height': `${this.windowH - this.headerHeight - 60}px` /* 60: header's margin-top */
        }
      }
    },

    created () {
      // Events
      window.addEventListener(this.support.firefox ? 'DOMMouseScroll' : 'mousewheel', this._onMousewheel)
      window.addEventListener('touchstart', this._onTouchstart)
      window.addEventListener('touchmove', this._onTouchmove)
      window.addEventListener('touchend', this._onTouchend)
      EventBus.$on('resize', this._onResize)
      EventBus.$on('YLScrollHContainer:scroll-to', this._onScrollTo)

      // Variables
      this.elWidth = 0
      this.drag = {
        active: false,
        start: 0,
        deltaX: 0,
        deltaXEased: 0,
        deltaXSaved: 0
      }
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()
      })

      this._onResize()
    },

    beforeDestroy () {
      // Events
      window.removeEventListener(this.support.firefox ? 'DOMMouseScroll' : 'mousewheel', this._onMousewheel)
      window.removeEventListener('touchstart', this._onTouchstart)
      window.removeEventListener('touchmove', this._onTouchmove)
      window.removeEventListener('touchend', this._onTouchend)
      EventBus.$off('resize', this._onResize)
      EventBus.$off('YLScrollHContainer:scroll-to', this._onScrollTo)

      this.stopRAF()

      // Variables
      this.elWidth = 0
    },

    methods: {
      // Events
      _onResize () {
        this.elWidth = this.$el.offsetWidth
      },

      _onScrollTo (value = 0) {
        this.scroll = clampNumber(value, (this.elWidth - this.windowW) * -1, 0)
      },

      // Mouse's events,
      _onMousewheel (event) {
        let delta = 0
        /* For IE. */
        if (!event) {
          event = window.event
        }

        if (event.wheelDelta) {
          /* IE/Opera. */
          delta = event.wheelDeltaY
        } else if (event.detail) {
          /** Mozilla case. */
          /** In Mozilla, sign of delta is different than in IE.
           * Also, delta is multiple of 3.
           */

          delta = -event.detail / 3
          delta *= 30
        }

        this.scroll += delta
        this.scroll = clampNumber(this.scroll, (this.elWidth - this.windowW) * -1, 0)
      },

      _onTouchstart (event) {
        if (this.drag.active) return

        this.drag.active = true
        this.drag.start = event.touches[0].clientX
      },

      _onTouchmove (event) {
        if (!this.drag.active) return

        this.drag.deltaX = event.touches[0].clientX - this.drag.start + this.drag.deltaXSaved
      },

      _onTouchend (event) {
        if (!this.drag.active) return

        this.drag.active = false
        this.drag.deltaXSaved = this.drag.deltaX
      },

      // RAF
      rafCallback () {
        if (!this.isTouchDevice) {
          if (!this.isTabletDevice) {
            this.scrollEased += (this.scroll - this.scrollEased) * 0.1
            this.scrollEased = Math.round(this.scrollEased * 100) / 100

            this.$el.style.transform = `translateX(${this.scrollEased}px) translateZ(0)`

            EventBus.$emit('YLScrollHContainer:raf', {
              scroll: this.scroll,
              scrollEased: this.scrollEased,
              maxScroll: (this.elWidth - this.windowW) * -1
            })
          }
        } else {
          if (!this.isTabletDevice) {
            this.drag.deltaXEased += (this.drag.deltaX - this.drag.deltaXEased) * 0.1

            this.drag.deltaXEased = clampNumber(this.drag.deltaXEased, (this.elWidth - this.windowW) * -1, 0)
            this.drag.deltaXEased = Math.round(this.drag.deltaXEased * 100) / 100

            const translateX = this.drag.deltaXEased
            this.$el.style.transform = `translateX(${translateX}px) translateZ(0)`

            EventBus.$emit('YLScrollHContainer:raf', {
              scroll: this.drag.deltaX,
              scrollEased: this.drag.deltaXEased,
              maxScroll: (this.elWidth - this.windowW) * -1
            })
          }
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-scroll-h-container {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    min-width: 100%;
    height: 100%;
  }
</style>
