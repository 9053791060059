export const STATE = {
  headerHeight: 'headerHeight',
  footerHeight: 'footerHeight',
  pageHeight: 'pageHeight',
  pageFullHeight: 'pageFullHeight',
  scrollTop: 'scrollTop',
  scrollDirection: 'scrollDirection',
  lang: 'lang',
  support: 'support',
  isTouchDevice: 'isTouchDevice',
  isTabletDevice: 'isTabletDevice',
  isMobileDevice: 'isMobileDevice',
  isInteracting: 'isInteracting',
  isTransitioning: 'isTransitioning',
  direction: 'direction',
  isRetina: 'isRetina',
  canAutoPlayVideo: 'canAutoPlayVideo',
  showLangSwitcher: 'showLangSwitcher',
  windowW: 'windowW',
  windowH: 'windowH',
  mediaQuery: 'mediaQuery',
  enableLazyLoad: 'enableLazyLoad',
  documentHeight: 'documentHeight',
  gtmEventsSent: 'gtmEventsSent',
  colorScheme: 'colorScheme',
  images: 'images',
  globalData: 'globalData',
  contentIsFetched: 'contentIsFetched',
  numberOfContentToFetch: 'numberOfContentToFetch',
  fetchError: 'fetchError',
  gpdrAccepted: 'gpdrAccepted',
  ambassadors: 'ambassadors',
  ambassadorFeatured: 'ambassadorFeatured',
  events: 'events',
  eventFeatured: 'eventFeatured',
  legacies: 'legacies',
  legacyFeatured: 'legacyFeatured',
  pages: 'pages',
  editableContent: 'editableContent',
  menuIsOpen: 'menuIsOpen'
}

export const GETTERS = {
  support: 'support',
  lang: 'lang',
  isGtmEventSent: 'isGtmEventSent',
  colorScheme: 'colorScheme',
  images: 'images',
  globalData: 'globalData',
  contentIsFetched: 'contentIsFetched',
  ambassadors: 'ambassadors',
  ambassadorFeatured: 'ambassadorFeatured',
  events: 'events',
  eventFeatured: 'eventFeatured',
  legacies: 'legacies',
  legacyFeatured: 'legacyFeatured'
}

export const MUTATIONS = {
  support: 'M_support',
  scrollTop: 'M_scrollTop',
  scrollDirection: 'M_scrollDirection',
  lang: 'M_lang',
  mediaQuery: 'M_mediaQuery',
  headerHeight: 'M_headerHeight',
  footerHeight: 'M_footerHeight',
  pageHeight: 'M_pageHeight',
  getPageFullHeight: 'M_getPageFullHeight',
  windowW: 'M_windowW',
  windowH: 'M_windowH',
  autoplayVideo: 'M_autoplayVideo',
  direction: 'M_direction',
  enableLazyLoad: 'M_ennableLazyLoad',
  documentHeight: 'M_documentHeight',
  sendGtmEvent: 'M_sendGtmEvent',
  isInteracting: 'M_isInteracting',
  isTransitioning: 'M_isTransitioning',
  colorScheme: 'M_colorScheme',
  images: 'M_images',
  globalData: 'M_globalData',
  contentIsFetched: 'M_contentIsFetched',
  numberOfContentFetched: 'M_numberOfContentFetched',
  fetchError: 'M_fetchError',
  gpdrAccepted: 'M_gpdrAccepted',
  ambassadors: 'M_ambassadors',
  ambassadorFeatured: 'M_ambassadorFeatured',
  events: 'M_events',
  eventFeatured: 'M_eventFeatured',
  legacies: 'M_legacies',
  legacyFeatured: 'M_legacyFeatured',
  editableContent: 'M_editableContent',
  menuIsOpen: 'M_menuIsOpen'
}

export const ACTIONS = {
  fetchAmbassadors: 'fetchAmbassadors',
  fetchAmbassadorFeatured: 'fetchAmbassadorFeatured',
  fetchEvents: 'fetchEvents',
  fetchEventFeatured: 'fetchEventFeatured',
  fetchLegacies: 'fetchLegacies',
  fetchLegacyFeatured: 'fetchLegacyFeatured',
  fetchEditableContent: 'fetchEditableContent'
}
