<template>
  <section class="yl-pillar-block-h2-icon-text-image"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-block-h2-icon-text-image__header">
      <h2 class="yl-pillar-block-h2-icon-text-image__title f-h2 f-color-brand-green">
        <yl-text-appearance :text="block.h2"></yl-text-appearance>
      </h2>

      <div v-if="icon"
           class="yl-pillar-block-h2-icon-text-image__icon-container">
        <yl-image-lazy-load :sources="icon.bind" />
      </div>
    </div>

    <div class="yl-pillar-block-h2-icon-text-image__wrapper">
      <div class="yl-pillar-block-h2-icon-text-image__text-container">
        <p class="yl-pillar-block-h2-icon-text-image__text f-body f-color-brand-white">
          <yl-text-appearance :text="block.text"></yl-text-appearance>
        </p>
      </div>

      <div class="yl-pillar-block-h2-icon-text-image__image-container">
        <yl-image-lazy-load klass="yl-pillar-block-h2-icon-text-image__image"
                            bcg-color="rgba(2, 2, 3, 0.5)"
                            :sources="image.bind" />

        <div v-if="iconAfter"
             class="yl-pillar-block-h2-icon-text-image__icon-after-container">
          <yl-image-lazy-load :sources="iconAfter.bind" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLPillarBlockH2IconTextImage',

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.layout - Layout of the block.
       * @param {String} block.h2 - h2 of the block.
       * @param {String} block.icon - Name of the icon.
       * @param {String} block.iconSize - Size of the icon.
       * @param {String} block.text - Text of the block.
       * @param {String} block.image - Image's name.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    computed: {
      icon () {
        return this.block['icon'] ? getImage({
          filename: this.block.icon
        }) : null
      },

      iconAfter () {
        return this.block['icon-after'] ? getImage({
          filename: this.block['icon-after']
        }) : null
      },

      image () {
        return getImage({
          filename: this.block.image
        })
      },

      elClasses () {
        return [
          `has-${this.block.layout}`,
          this.block.iconSize ? `has-image-${this.block.iconSize}` : ''
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-h2-icon-text-image {

  }

  .yl-pillar-block-h2-icon-text-image__header {
    position: relative;

    .is-container-for-junior-development .is-block-index-0 & {
      display: flex;
      flex-direction: column;
    }

    .is-container-for-community-enrichment .is-block-index-0 & {
      display: flex;
      flex-direction: column;
    }

    .is-container-for-participation-discovery .is-block-index-0 & {
      display: flex;
      flex-direction: column;
    }
  }

  .yl-pillar-block-h2-icon-text-image__title {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 9,
      xlarge: 9,
    ));
  }

  .yl-pillar-block-h2-icon-text-image__icon-container {
    position: absolute;

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));

    .is-container-for-junior-development .is-block-index-0 & {
      right: 0;
      bottom: 0;
      transform: translateY(100%) translateZ(0);

      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 6,
        large: 3,
        xlarge: 3
      ));

      @media screen and (min-width: 1440px) {
        transform: translateY(50%) translateZ(0);
      }

      @include breakpoint('medium-') {
        position: relative;
        margin-top: $base-px * 2;
        transform: translateZ(0);
      }

      @include breakpoint('small-') {
        margin-top: 0;
      }
    }

    .is-container-for-junior-development .is-block-index-2 & {
      top: 0;
      right: 0;

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 8,
        large: 7,
        xlarge: 7
      ));

      opacity: 0.4;

      @include breakpoint('large-') {
        transform: translateY(-25%) translateZ(0);
      }

      @include breakpoint('medium-') {
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-junior-development .is-block-index-5 & {
      top: 0;
      right: 0;

      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 10,
        large: 5,
        xlarge: 5
      ));

      opacity: 0.4;

      @include breakpoint('medium-') {
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-community-enrichment .is-block-index-0 & {
      right: 0;
      bottom: 0;
      transform: translateY(100%) translateZ(0);

      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 6,
        large: 4,
        xlarge: 4
      ));

      @include breakpoint('medium-') {
        position: relative;
        margin-top: $base-px * 2;
        transform: translateZ(0);
      }

      @include breakpoint('small-') {
        margin-top: 0;
      }
    }

    .is-container-for-community-enrichment .is-block-index-2 & {
      top: -$base-px * 6;
      right: colspan(0.25);

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 6,
        large: 6,
        xlarge: 6
      ));

      opacity: 0.35;

      @include breakpoint('medium-') {
        right: 0;
        transform: translateY(-35%) translateZ(0);
      }

      @include breakpoint('small-') {
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-community-enrichment .is-block-index-6 & {
      top: 0;
      right: colspan(0.25);
      transform: translateZ(0);

      @include width-multi((
        xsmall: 2,
        small: 2,
        medium: 2,
        large: 2,
        xlarge: 2
      ));

      opacity: 0.6;

      @include breakpoint('medium-') {
        right: 0;
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-participation-discovery .is-block-index-0 & {
      right: 0;
      bottom: 0;
      transform: translateY(100%) translateZ(0);

      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 6,
        large: 3,
        xlarge: 3
      ));

      @include breakpoint('medium-') {
        position: relative;
        margin-top: $base-px * 2;
        margin-right: auto;
        margin-left: auto;
        transform: translateZ(0);
      }
    }

    .is-container-for-participation-discovery .is-block-index-2 & {
      top: 0;
      right: 0;
      transform: translateY(-25%) translateZ(0);

      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 6,
        large: 3,
        xlarge: 3
      ));

      opacity: 0.4;

      @include breakpoint('medium-') {
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-participation-discovery .is-block-index-5 & {
      right: colspan(0.25);
      bottom: 0;
      transform: translateZ(0);

      @include width-multi((
        xsmall: 2,
        small: 2,
        medium: 1.5,
        large: 1.5,
        xlarge: 1.5
      ));

      @include breakpoint('medium-') {
        right: 0;
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-participation-discovery .is-block-index-8 & {
      right: 0;
      bottom: 0;
      transform: translateY(100%) translateZ(0);

      @include width-multi((
        xsmall: 2,
        small: 2,
        medium: 4,
        large: 2,
        xlarge: 2
      ));

      opacity: 0.6;

      @include breakpoint('medium-') {
        transform: translateY(-50%) translateZ(0);
      }
    }

    .is-container-for-badminton-advancement .is-block-index-2 & {
      top: 0;
      right: 0;

      @include width-multi((
        xsmall: 3,
        small: 3,
        medium: 3,
        large: 2,
        xlarge: 2
      ));

      opacity: 0.3;
    }

    @include breakpoint('medium-') {
      transform: translateY(-25%) translateZ(0);
    }

    @include breakpoint('small-') {
      transform: translateY(-50%) translateZ(0);
    }
  }

  .yl-pillar-block-h2-icon-text-image__icon-after-container {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(50%) translateZ(0);

    @include width-multi((
      xsmall: 2,
      small: 2,
      medium: 4,
      large: 2,
      xlarge: 2
    ));

    .is-container-for-junior-development .is-block-index-2 & {
      @include breakpoint('medium-') {
        @include width-multi((
          xsmall: 4,
          small: 4,
          medium: 4
        ));
      }
    }

    .is-container-for-participation-discovery .is-block-index-2 & {
      right: unset;
      left: 0;
      transform: translateY(200%) translateZ(0);

      @include breakpoint('medium-') {
        transform: translateY(100%) translateY($base-px * 5) translateZ(0);

        @include width-multi((
          xsmall: 4,
          small: 4,
          medium: 4
        ));
      }
    }

    .is-container-for-badminton-advancement .is-block-index-0 & {
      right: unset;
      left: 0;
      transform: translateY(50%) translateZ(0);

      @include width-multi((
        xsmall: 4,
        small: 4,
        medium: 4,
        large: 3,
        xlarge: 3
      ));

      opacity: 0.6;

      @include breakpoint('medium-') {
        transform: translateY(100%) translateY($base-px * 2) translateZ(0);
      }
    }
  }

  .yl-pillar-block-h2-icon-text-image__wrapper {
    position: relative;
    margin-top: $base-px * 6;
    z-index: 2;

    .is-container-for-junior-development .is-block-index-2 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-junior-development .is-block-index-5 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-community-enrichment .is-block-index-2 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-community-enrichment .is-block-index-6 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-participation-discovery .is-block-index-2 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-participation-discovery .is-block-index-5 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-participation-discovery .is-block-index-8 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-badminton-advancement .is-block-index-0 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }

    .is-container-for-badminton-advancement .is-block-index-2 & {
      @include breakpoint('medium-') {
        margin-top: $base-px * 3;
      }
    }
  }

  .yl-pillar-block-h2-icon-text-image__text-container {
    position: relative;
    z-index: 3;

    display: flex;

    .has-image-on-left & {
      justify-content: flex-end;
    }
  }

  .yl-pillar-block-h2-icon-text-image__text {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 5,
      xlarge: 5,
    ));

    .is-container-for-participation-discovery .is-block-index-0 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 4,
        xlarge: 4,
      ));
    }

    .is-container-for-participation-discovery .is-block-index-2 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 4,
        xlarge: 4,
      ));
    }
  }

  .yl-pillar-block-h2-icon-text-image__image-container {
    position: relative;

    display: flex;

    @include breakpoint('medium-') {
      align-items: flex-start;
    }

    .has-image-on-right & {
      justify-content: flex-end;
    }

    .is-container-for-junior-development .is-block-index-0 & {
      margin-top: $base-px * 10 * -1;

      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }

    .is-container-for-junior-development .is-block-index-2 & {
      margin-top: $base-px * 10 * -1;

      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }

    .is-container-for-junior-development .is-block-index-5 & {
      margin-top: $base-px * 10 * -1;

      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }

    .is-container-for-community-enrichment .is-block-index-0 & {
      margin-top: $base-px * 8;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 4;
      }
    }

    .is-container-for-community-enrichment .is-block-index-2 & {
      margin-top: $base-px * 10 * -1;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 4;
      }
    }

    .is-container-for-community-enrichment .is-block-index-6 & {
      margin-top: $base-px * 7;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 4;
      }
    }

    .is-container-for-participation-discovery .is-block-index-0 & {
      @include breakpoint('medium-') {
        margin-top: $base-px;
      }
    }

    .is-container-for-participation-discovery .is-block-index-2 & {
      margin-top: -$base-px * 20;

      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }

    .is-container-for-participation-discovery .is-block-index-5 & {
      margin-top: $base-px * 7 * -1;

      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }

    .is-container-for-participation-discovery .is-block-index-8 & {
      margin-top: $base-px * 14;

      justify-content: center;

      @include breakpoint('medium-') {
        margin-top: $base-px * 2;
      }
    }

    .is-container-for-badminton-advancement .is-block-index-0 & {
      margin-top: $base-px * 10 * -1;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 4;
      }
    }

    .is-container-for-badminton-advancement .is-block-index-2 & {
      margin-top: $base-px * 10 * -1;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 2;
      }
    }
  }

  .yl-pillar-block-h2-icon-text-image__image {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6,
    ));

    .is-container-for-junior-development .is-block-index-0 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 8,
        xlarge: 8,
      ));
    }

    .is-container-for-junior-development .is-block-index-2 & {
      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 10
      ));
    }

    .is-container-for-participation-discovery .is-block-index-0 & {
      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 10,
        large: 7,
        xlarge: 7,
      ));
    }

    .is-container-for-participation-discovery .is-block-index-2 & {
      @include width-multi((
        xsmall: 5,
        small: 5,
        medium: 10,
        large: 5,
        xlarge: 5
      ));
    }

    .is-container-for-badminton-advancement .is-block-index-0 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 5,
        xlarge: 5,
      ));
    }

    .is-container-for-badminton-advancement .is-block-index-2 & {
      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 5,
        xlarge: 5,
      ));
    }
  }
</style>
