<template>
  <section class="plegends"
           :class="elClasses">
    <yl-legends-covers-and-titles v-if="!isTabletDevice"
                                  :covers="covers"
                                  :titles="pageTitles"
                                  :activeLegend="activeLegend"
                                  :subtitle="editableContent['landing'].description">
    </yl-legends-covers-and-titles>

    <yl-legends-informations v-if="!isTabletDevice"
                             :legends="legends"
                             :activeLegend="activeLegend">
    </yl-legends-informations>

    <div v-if="!isTabletDevice"
         class="plegends__fixed-elements">
      <div class="plegends__dots">
        <div v-for="(page, pageIndex) in pageTitles"
             :key="`plegends_dot-${pageIndex}`"
             class="plegends__dot u-marg-t-2 u-cursor-pointer"
             :class="[ activeLegend === page.id ? 'is-active' : '' ]"
             @click="wheelID = pageIndex - 1">
        </div>
      </div>

      <yl-round-button class="plegends__fixed-elements__button"
                       :class="[ activeLegend !== null ? 'is-hidden' : '' ]"
                       size="100px"
                       icon="full-arrow--bottom"
                       @click="wheel">
      </yl-round-button>
    </div>

    <yl-legends-header v-if="isTabletDevice"
                       :header="editableContent['landing']">
    </yl-legends-header>

    <yl-legends-legends v-if="isTabletDevice"
                        :legends="legends">
    </yl-legends-legends>
  </section>
</template>

<script>
  // Store
  import { STATE as S, GETTERS as G } from '@/store/helpers'

  // Mixins
  import PageMixin from '@/mixins/page'

  // Helpers
  import { enableScroll } from '@/helpers/scroll'
  import { EventBus } from '@/helpers/event-bus'
  import { throttle } from '@/helpers/globals'

  // Components
  import YLLegendsHeader from '@/components/legends/YLLegendsHeader'
  import YLLegendsLegends from '@/components/legends/YLLegendsLegends'
  import YLLegendsCoversAndTitles from '@/components/legends/YLLegendsCoversAndTitles'
  import YLLegendsInformations from '@/components/legends/YLLegendsInformations'
  // Elements
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'PLegends',
    pageName: 'legends',
    mixins: [PageMixin],

    components: {
      'yl-legends-header': YLLegendsHeader,
      'yl-legends-legends': YLLegendsLegends,
      'yl-legends-covers-and-titles': YLLegendsCoversAndTitles,
      'yl-legends-informations': YLLegendsInformations,
      // Elements
      'yl-round-button': YLRoundButton
    },

    data () {
      return {
        wheelID: -1,
        lastActiveLegend: null
      }
    },

    computed: {
      support () {
        return this.$store.getters[G.support]
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      legends () {
        return this.editableContent['legends']
      },

      pageTitles () {
        return [{
          title: this.editableContent['landing'].title,
          id: null
        }].concat(this.legends.map(l => {
          return {
            title: l.name,
            id: l.id
          }
        }))
      },

      activeLegend () {
        return this.wheelID === -1 ? null : this.legends[this.wheelID].id
      },

      covers () {
        return this.editableContent['covers']
      },

      activeCover () {
        return this.activeLegend === null ? null : this.covers.filter(c => c === this.activeLegend)[0]
      },

      elClasses () {
        return [
          this.activeLegend !== null ? `has-${this.activeLegend}-visible` : 'has-landing-visible',
          this.lastActiveLegend !== null ? `was-${this.lastActiveLegend}-visible` : 'was-landing-visible'
        ]
      }
    },

    created () {
      // Variables
      this.dragStart = 0

      // Events
      EventBus.$on('resize', this._onResize)
      window.addEventListener('touchstart', this._onTouchstart)
      window.addEventListener('touchend', this._onTouchend)
      window.addEventListener(this.support.firefox ? 'DOMMouseScroll' : 'mousewheel', throttle(this.onWheel, 1000), false)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)
      window.removeEventListener('touchstart', this._onTouchstart)
      window.removeEventListener('touchend', this._onTouchend)
      window.removeEventListener(this.support.firefox ? 'DOMMouseScroll' : 'mousewheel', throttle(this.onWheel, 1000), false)
    },

    methods: {
      // Events
      _onResize () {
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)

        if (this.isTabletDevice) {
          enableScroll()
        }
      },

      // Touch
      _onTouchstart (event) {
        this.dragStart = event.touches[0].clientY
      },

      _onTouchend (event) {
        const deltaY = event.changedTouches[0].clientY - this.dragStart

        if (deltaY < -200) {
          this.wheel(1)
        } else if (deltaY > 200) {
          this.wheel(-1)
        }
      },

      wheel (value = 1) {
        this.lastActiveLegend = this.activeLegend
        this.wheelID += value
        if (this.wheelID === this.legends.length) {
          this.wheelID = 0
        } else if (this.wheelID < -1) {
          this.wheelID = -1
        }
      },

      // Wheel RAF
      onWheel (event) {
        let delta = 0
        if (event.wheelDelta) {
          /* IE/Opera. */
          delta = event.wheelDeltaY
        } else if (event.detail) {
          /** Mozilla case. */
          /** In Mozilla, sign of delta is different than in IE.
           * Also, delta is multiple of 3.
           */

          delta = -event.detail / 3
        }

        this.wheel(Math.sign(delta) * -1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .plegends {
    position: relative;
    min-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    @include breakpoint('medium-') {
      height: auto;
    }
  }

  .plegends__fixed-elements {
    position: fixed;
    bottom: $base-px * 6;
    left: 50%;
    transform: translateX(-50%) translateZ(0);
    z-index: 3;

    width: calc(100% - (64px * 2));
  }

  .plegends__dots {
    position: absolute;
    bottom: 0;
    left: 0;

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .plegends__dot {
    transform: scale(1) translateZ(0);

    width: 4px;
    height: 4px;

    background-color: map-get($colors-list, "brand-cream");
    opacity: 0.3;
    border-radius: 50%;

    transition: transform 0.45s $ease-out-quint, opacity 0.45s $ease-out-quint;
    will-change: transform, opacity;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      content: "";

      position: absolute;
      top: -$base-px / 2;
      right: -$base-px / 2;
      bottom: -$base-px / 2;
      left: -$base-px / 2;
    }

    &:hover {
      transform: scale(2) translateZ(0);

      opacity: 0.5;
    }

    &.is-active {
      opacity: 1;
    }
  }

  .plegends__fixed-elements__button {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateZ(0);

    opacity: 1;
    visibility: visible;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }
  }
</style>
