<template>
  <section class="phome">
    <yl-scroll-h-container v-if="!isTabletDevice">
      <yl-home-landing :content="content"
                       :editable-content="editableContent.landing"
                       :YLHomePillarPanelsPositionX="YLHomePillarPanelsPositionX">
      </yl-home-landing>

      <yl-home-pillar-panels ref="yl-home-pillar-panels"
                             class="p-home__pillar-panels"
                             :content="content"
                             :editable-content="editableContent.panels">
      </yl-home-pillar-panels>
    </yl-scroll-h-container>

    <div v-else>
      <yl-home-landing :content="content"
                       :editable-content="editableContent.landing">
      </yl-home-landing>

      <yl-home-pillar-panels ref="yl-home-pillar-panels"
                             :content="content"
                             :editable-content="editableContent.panels">
      </yl-home-pillar-panels>
    </div>

    <section class="phome__fixed-elements">
      <div class="phome__fixed-elements__container">
        <div class="phome__fixed-elements__item f-home-decoration f-color-brand-white">
          <div v-html="'IMPACT........... :22,122ATT'"></div>
          <div v-html="'.......... .. .... .'"></div>
        </div>

        <div ref="button" class="phome__fixed-elements__button-container">
          <yl-round-button size="100px"
                           :icon="isTabletDevice ? 'full-arrow--bottom' : 'full-arrow--right'"
                           @click="scroll">
          </yl-round-button>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { enableScroll } from '@/helpers/scroll'

  // Store
  import { STATE as S } from '@/store/helpers'

  // Components
  // Scroll
  import YLScrollHContainer from '@/components/scroll/YLScrollHContainer'
  // Home
  import YLHomeLanding from '@/components/home/YLHomeLanding'
  import YLHomePillarPanels from '@/components/home/YLHomePillarPanels'
  // Elements
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'PHome',
    pageName: 'home',
    mixins: [PageMixin],

    components: {
      // Scroll
      'yl-scroll-h-container': YLScrollHContainer,
      // Home
      'yl-home-landing': YLHomeLanding,
      'yl-home-pillar-panels': YLHomePillarPanels,
      // Elements
      'yl-round-button': YLRoundButton
    },

    data () {
      return {
        YLHomePillarPanelsPositionX: 0
      }
    },

    computed: {
      windowW () {
        return this.$store.state[S.windowW]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
      EventBus.$on('YLScrollHContainer:raf', this._onRAF)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)
      EventBus.$on('YLScrollHContainer:raf', this._onRAF)
    },

    methods: {
      // Events
      _onResize () {
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)

        if (this.$refs['yl-home-pillar-panels'] && this.$refs['yl-home-pillar-panels'].$el) {
          this.YLHomePillarPanelsPositionX = this.$refs['yl-home-pillar-panels'].$el.getBoundingClientRect().left
        }

        if (this.isTabletDevice) {
          enableScroll()
        }
      },

      _onRAF (event) {
        const $ref = this.$refs['button']
        if (!$ref) return

        if ((event.scroll * -1) <= (this.windowW / 4 * 3)) {
          if ($ref && $ref.className.indexOf('is-hidden') > -1) {
            $ref.classList.remove('is-hidden')
          }
        } else {
          if ($ref && $ref.className.indexOf('is-hidden') === -1) {
            $ref.classList.add('is-hidden')
          }
        }
      },

      // HTML Events
      scroll () {
        EventBus.$emit('YLScrollHContainer:scroll-to', (this.windowW / 4 * 3) * -1)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .phome {
    position: relative;
    transform: translateZ(0);

    min-height: 100vh;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    transition: background-color 0.65s $ease-out-quint;
    will-change: background-color;

    @include breakpoint('medium-') {
      height: auto;
    }
  }

  .p-home__pillar-panels {
    margin-left: colspan(1, 'xsmall', 0px, true);
  }

  .phome__fixed-elements {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    transform: translateZ(0);

    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;

    opacity: 1;

    transition: opacity 0.65s $ease-out-quint;
    will-change: opacity;

    .has-home-landing-word-hover & {
      opacity: 0;
    }

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .phome__fixed-elements__container {
    position: relative;

    display: flex;
    align-items: flex-end;
    width: calc(100% - (2 * 64px));
    padding-bottom: $base-px * 6;

    @include breakpoint('medium-') {
      width: calc(100% - (2 * #{$base-px} * 3));
      padding-bottom: 0;
    }
  }

  .phome__fixed-elements__item {
    position: relative;
    transform: rotate(-90deg) translateZ(0);
    transform-origin: 0 0;

    display: inline-block;

    @include breakpoint('medium-') {
      position: absolute;
      bottom: $base-px * 3;
      left: 0;
    }
  }

  .phome__fixed-elements__button-container {
    position: absolute;
    right: 0;
    bottom: $base-px * 6;
    transform: translateZ(0);

    opacity: 1;
    visibility: visible;

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: opacity, visibility;

    &.is-hidden,
    .has-home-landing-word-hover & {
      opacity: 0;
      visibility: hidden;
    }

    @include breakpoint('medium-') {
      bottom: $base-px * 3;
    }
  }

</style>
