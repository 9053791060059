
<template>
  <section class="yl-pillar-form"
           :class="elClasses"
           :style="elStyle">
    <h4 class="yl-pillar-form__title f-h4 f-color-brand-green">
      <yl-text-appearance :text="content.h4"></yl-text-appearance>
    </h4>

    <p class="yl-pillar-form__text u-marg-t-1 f-body-small f-color-brand-white">
      <yl-text-appearance :text="content.text"></yl-text-appearance>
    </p>

    <div class="yl-pillar-form__fields-container">
      <div class="yl-pillar-form__icon-container"
           :class="[ icon.sprite ? 'is-sprite' : '' ]">
        <img class="yl-pillar-form__icon"
             v-bind="icon.image.bind" />
      </div>

      <div class="yl-pillar-form__fields u-marg-t-6">
        <yl-appearance :block="true">
          <fieldset :class="[ errors.user ? 'has-error' : '' ]">
            <label for="user"
                   class="f-body-small f-color-brand-cream"
                   v-html="content.fields.name.label">
            </label>

            <input type="text"
                   class="yl-pillar-form__input f-body-small f-color-brand-white"
                   required
                   v-model="user"
                   id="user"
                   :placeholder="content.fields.name.placeholder"
                   @keydown="_onKeydown('user')" />

            <div v-if="errors.user"
                 class="u-marg-t-1 f-body-small f-color-brand-error u-align-right"
                 v-html="errors.user">
            </div>
          </fieldset>
        </yl-appearance>

        <yl-appearance :block="true">
          <fieldset class="u-marg-t-4"
                    :class="[ errors.email ? 'has-error' : '' ]">
            <label for="email"
                   class="f-body-small f-color-brand-cream"
                   v-html="content.fields.email.label">
            </label>

            <input type="email"
                   class="yl-pillar-form__input f-body-small f-color-brand-white"
                   required
                   v-model="email"
                   id="email"
                   :placeholder="content.fields.email.placeholder"
                   @keydown="_onKeydown('email')" />

            <div v-if="errors.email"
                 class="u-marg-t-1 f-body-small f-color-brand-error u-align-right"
                 v-html="errors.email">
            </div>
          </fieldset>
        </yl-appearance>

        <yl-appearance :block="true">
          <fieldset class="u-marg-t-4"
                    :class="[ errors.message ? 'has-error' : '' ]">
            <label for="message"
                   class="f-body-small f-color-brand-cream"
                   v-html="content.fields.message.label">
            </label>

            <textarea type="message"
                      class="yl-pillar-form__input f-body-small f-color-brand-white"
                      required
                      v-model="message"
                      id="message"
                      :placeholder="content.fields.message.placeholder"
                      @keydown="_onKeydown('message')">
            </textarea>

            <div v-if="errors.message"
                 class="u-marg-t-1 f-body-small f-color-brand-error u-align-right"
                 v-html="errors.message">
            </div>
          </fieldset>
        </yl-appearance>
      </div>

      <yl-appearance>
        <div class="u-marg-t-4">
          <yl-cta v-if="!submitted"
                  class="yl-home-pillar-panels-item__button"
                  :label="content.button.label"
                  icon="full-arrow--right"
                  :loading="loading"
                  @click="_onSubmit">
          </yl-cta>

          <div v-else
               class="f-body-small f-color-brand-green"
               v-html="'Thank you for your submission.'">
          </div>
        </div>
      </yl-appearance>
    </div>
  </section>
</template>

<script>
  // Dependencies
  import axios from 'axios'

  // Helpers
  import { getImage } from '@/helpers/assets'

  // Components
  import YLCTA from '@/components/elements/YLCTA'

  const qs = require('querystring')

  export default {
    name: 'YLPillarForm',

    components: {
      'yl-cta': YLCTA
    },

    props: {
      /**
       *
       **/
      content: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        user: '',
        email: '',
        message: '',
        errors: {},
        submitted: false,
        loading: false
      }
    },

    computed: {
      icon () {
        return this.content.icon ? {
          image: getImage({
            filename: `${this.content.icon.source}${this.content.icon.sprite ? '_sprite' : ''}.png`
          }),
          sprite: this.content.icon.sprite
        } : null
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // HTML Events
      _onSubmit () {
        this.loading = true
        const errors = {}

        if (!this.user || this.user.trim() === '') {
          errors.user = 'This field is mandatory'
        }
        if (!this.email || this.email.trim() === '' || this.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) === null) {
          errors.email = 'Invalid email'
        }
        if (!this.message || this.message.trim() === '') {
          errors.message = 'This field is mandatory'
        }

        this.errors = errors

        const hasErrors = Object.keys(this.errors).length > 0

        if (!hasErrors) {
          const data = {
            action: 'action:send-mail',
            user: this.user,
            email: this.email,
            message: this.message,
            pillar: this.$route.name
          }

          axios({
            method: 'post',
            url: process.env.VUE_APP_API_URL,
            data: qs.stringify(data),
            config: {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          })
            .then(res => {
              this.submitted = true
              this.loading = false
              console.log(res)
            })
            .catch(e => {
              this.submitted = true
              this.loading = false
              console.log(e)
            })
        } else {
          this.loading = false
        }
      },

      _onKeydown (field) {
        delete this.errors[field]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-form {
    width: 100%;
    padding-left: colspan(0.25);

    border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);

    @include breakpoint('medium-') {
      padding-left: 0;

      border-left: none;
    }
  }

  .yl-pillar-form__title,
  .yl-pillar-form__text {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6,
    ));
  }

  .yl-pillar-form__fields-container {
    position: relative;
  }

  .yl-pillar-form__icon-container {
    position: absolute;
    top: 0;
    right: 0;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 3,
      xlarge: 3,
    ));

    opacity: 0.3;

    &.is-sprite {
      overflow: hidden;

      &:before {
        content: "";

        position: relative;

        display: block;
        width: 100%;
        padding-top: calc(100% / (26544 / 48) * 609)
      }
    }

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .yl-pillar-form__icon {
    .is-sprite & {
      $n: 48;
      position: absolute;
      top: 0;
      left: 0;

      width: (100% * $n) !important;
      max-width: none !important;

      $duration: ($n / 24) + 's';
      animation: sprite-h #{$duration} steps(#{$n}) infinite;
      animation-direction: alternate;
    }
  }

  .yl-pillar-form__fields {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 4,
      xlarge: 4,
    ));
  }

  .yl-pillar-form__input {
    display: block;
    width: 100%;
    padding-bottom: $base-px * 2;

    background: 0 none;
    border: none;
    border-bottom: 1px solid map-get($colors-list, "brand-white");
    appearance: none;
    border-radius: 0;

    &::placeholder {
      color: map-get($colors-list, "brand-white");
      opacity: 0.3;
    }

    &:focus {
      outline: none;
    }

    .has-error & {
      border-bottom: 1px solid map-get($colors-list, "brand-error");
    }
  }

  textarea {
    height: $base-px * 15;
  }
</style>
