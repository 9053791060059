// Dépendencies
import axios from 'axios'

// Store
import store from '@/store'
import { STATE as S } from '@/store/helpers'

export const fetchEditableContent = async () => {
  try {
    const content = await getEditableContent()

    return content
  } catch (e) {
    throw e.response
  }
}

const getEditableContent = async () => {
  const pages = store.state[S.pages]
  const content = {}
  const promises = [
    axios.get('/static/json/gpdr.json'),
    axios.get('/static/json/footer.json'),
    axios.get('/static/json/nav.json'),
    axios.get('/static/json/popups.json')
  ]

  pages.forEach(page => {
    promises.push(axios.get(`/static/json/${page.name}.json`))
  })

  return axios.all(promises)
    .then(results => {
      results.forEach(res => {
        const name = res.config.url.replace('/static/json/', '').replace('.json', '')
        content[name] = res.data
      })

      return content
    })
}
