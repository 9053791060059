<template>
  <section class="yl-legacies-list-category"
           :class="elClasses"
           :style="elStyle">
    <h2 class="f-h1 f-color-brand-green">
      <yl-text-appearance :text="category.name"></yl-text-appearance>
    </h2>

    <div class="yl-legacies-list-category__container u-marg-t-2">
      <yl-legalices-list-legacy v-for="(legacy, legacyIndex) in category.legacies"
                                :key="`yl-legacies-list-category_legacy-${legacyIndex}`"
                                :legacy="legacy"
                                :active="activeLegacy !== null && activeLegacy[1] === legacyIndex"
                                @click="$emit('click', legacyIndex)">
      </yl-legalices-list-legacy>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getAbsoluteBoundingRect } from '@/helpers/dom'

  // Components
  import YLLegaciesListLegacy from './YLLegaciesListLegacy'

  export default {
    name: 'YLLegaciesListCategory',

    components: {
      'yl-legalices-list-legacy': YLLegaciesListLegacy
    },

    props: {
      /**
       * Object of category
       * @param {Object} - *REQUIRED* category: Object w/ category's content
       * @param {String} - *REQUIRED* category.id: Category's ID
       * @param {String} - *REQUIRED* category.name: Category's name
       * @param {String} - *REQUIRED* category.slug: Category's slug
       * @param {Array} - *REQUIRED* category.legacies: List of Legacies related to Category
       **/
      category: {
        type: Object,
        required: true
      },

      /**
       * Array w/ 2 IDs: [0] = category's ID, [1] = legacy's ID
       * @param {Array} - *REQUIRED* activeLegacy: Array w/ 2 IDs
       * @param {Number} - *REQUIRED* activeLegacy[0]: Category's ID
       * @param {Number} - *REQUIRED* activeLegacy[1]: Legacy's ID
       */
      activeLegacy: {
        type: Array,
        default: null
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      _getBoundingRect () {
        return getAbsoluteBoundingRect(this.$el)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-list-category {
    margin-top: $base-px * 21;

    &:first-child {
      margin-top: $base-px * 12;

      @include breakpoint('medium-') {
        margin-top: $base-px * 8;
      }
    }

    @include breakpoint('medium-') {
      margin-top: $base-px * 8;
    }
  }

  .yl-legacies-list-category__container {
    @include breakpoint('medium-') {
      margin-top: 0;
    }
  }
</style>
