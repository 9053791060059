<template>
  <section class="yl-legacies-featured u-marg-t-20 u-marg-b-18 u-marg-x-auto"
           :class="elClasses"
           :style="elStyle">
    <yl-event-featured :content="content['event']"></yl-event-featured>

    <yl-ambassador-featured :content="content['ambassador']"></yl-ambassador-featured>
  </section>
</template>

<script>
  // Components
  import YLEventFeatured from '@/components/events/YLEventFeatured'
  import YLAmbassadorFeatured from '@/components/ambassadors/YLAmbassadorFeatured'

  export default {
    name: 'YLLegaciesFeatured',

    components: {
      'yl-event-featured': YLEventFeatured,
      'yl-ambassador-featured': YLAmbassadorFeatured
    },

    props: {
      /**
       * Object w/ content for event and ambassador
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {Object} - *REQUIRED* content.event: Object for Event block
       * @param {Object} - *REQUIRED* content.ambassador: Object for Ambassador block
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-featured {
    display: flex;
    justify-content: space-between;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 11;
      margin-bottom: $base-px * 10;

      flex-direction: column;
    }
  }
</style>
