<template>
  <section class="yl-events-statistics"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-events-statistics__background"></div>

    <div class="yl-events-statistics__container">
      <div class="yl-events-statistics__text-container u-marg-x-auto f-body-bigger f-color-brand-brown u-align-center">
        <yl-text-appearance :text="content.text"></yl-text-appearance>
      </div>

      <div class="yl-events-statistics__items-container u-marg-t-5">
        <yl-events-statistics-item v-for="(statistic, statisticIndex) in content.list"
                                  :key="`yl-events-statistics_item-${statisticIndex}`"
                                  :statistic="statistic"
                                  :index="statisticIndex">
        </yl-events-statistics-item>
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLEventsStatisticsItem from './YLEventsStatisticsItem'

  export default {
    name: 'YLEventsStatistics',

    components: {
      'yl-events-statistics-item': YLEventsStatisticsItem
    },

    props: {
      /**
       * Object of content
       * @param {Object} - *REQUIRED* content: Object of content
       * @param {String} - *REQUIRED* content.text: Text for description
       * @param {Array} - *REQUIRED* content.list: Array of statistics
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-statistics {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    @include breakpoint('small-') {
      height: auto;
      padding-top: $base-px * 4;
      padding-bottom: $base-px * 4;
    }
  }

  .yl-events-statistics__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url('~@/assets/images/events/yonex-legends_events_statistics_background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.14;
  }

  .yl-events-statistics__container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8
    ));
  }

  .yl-events-statistics__text-container {
    @include breakpoint('small-') {
      text-align: left;
    }
  }

  .yl-events-statistics__items-container {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    @include width-multi((
      xsmall: 5,
      small: 5
    ));

    @include breakpoint('small-') {
      margin-right: auto;
      margin-left: auto;

      flex-direction: column;
    }
  }
</style>
