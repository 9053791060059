<template>
  <div class="app"
       :class="elClasses"
       :style="elStyle">
    <yl-loader ref="loader"
               @loaded="loaded">
    </yl-loader>

    <yl-page-transition></yl-page-transition>

    <yl-header></yl-header>

    <yl-menu v-if="contentIsFetched"></yl-menu>

    <yl-popups v-if="contentIsFetched"></yl-popups>

    <router-view ref="page" />

    <yl-footer v-if="($route.meta.hasFooter || (isTabletDevice && $route.meta.hasFooterOnMobile)) && contentIsFetched"></yl-footer>

    <yl-background></yl-background>

    <yl-gpdr-popup v-if="contentIsFetched"></yl-gpdr-popup>
  </div>
</template>

<script>
  // Store
  import { GETTERS as G, MUTATIONS as M, STATE as S } from '@/store/helpers'

  // Helpers
  import { isAutoplaySupportedOldBrowser } from '@/helpers/videos'
  import { throttle, isPrerendering } from '@/helpers/globals'
  import { scrollTo } from '@/helpers/scroll'
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLLoader from '@/components/loader/YLLoader'
  import YLPageTransition from '@/components/transition/YLPageTransition'
  import YLPopups from '@/components/popups/YLPopups'
  import YLHeader from '@/components/header/YLHeader'
  import YLMenu from '@/components/menu/YLMenu'
  import YLFooter from '@/components/footer/YLFooter'
  import YLBackground from '@/components/background/YLBackground'
  import YLGPDRPopup from '@/components/gpdr/YLGPDRPopup'

  export default {
    components: {
      'yl-loader': YLLoader,
      'yl-page-transition': YLPageTransition,
      'yl-popups': YLPopups,
      'yl-header': YLHeader,
      'yl-menu': YLMenu,
      'yl-footer': YLFooter,
      'yl-background': YLBackground,
      'yl-gpdr-popup': YLGPDRPopup
    },

    data () {
      return {
        env: process.env.NODE_ENV,
        error: null,
        loading: true,
        homeLandingWordIsHover: false
      }
    },

    computed: {
      windowW () {
        return this.$store.state[S.windowW]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      isTouchDevice () {
        return this.$store.state[S.isTouchDevice]
      },

      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      scrollDirection () {
        return this.$store.state[S.scrollDirection]
      },

      support () {
        return this.$store.getters[G.support]
      },

      contentIsFetched () {
        return this.$store.getters[G.contentIsFetched]
      },

      menuIsOpen () {
        return this.$store.state[S.menuIsOpen]
      },

      elClasses () {
        return [
          this.homeLandingWordIsHover ? 'has-home-landing-word-hover' : '',
          this.menuIsOpen ? 'has-menu-open' : '',
          `is-scrolling-${this.scrollDirection}`,
          isPrerendering ? 'is-prerendering' : ''
        ]
      },

      elStyle () {
        return [
          isPrerendering ? {
            'position': 'absolute !important',
            'width': '1px',
            'height': '1px',
            'overflow': 'hidden',
            'clip': 'rect(1px 1px 1px 1px)'
          } : ''
        ]
      }
    },

    watch: {
      contentIsFetched: '_onContentIsFetchedChange',
      $route: '_onRouteChange'
    },

    beforeCreate () {
      isAutoplaySupportedOldBrowser().then(() => {
        this.$store.commit(M.autoplayVideo, true)
      }).catch(() => {
        this.$store.commit(M.autoplayVideo, false)
      })
    },

    created () {
      // Variables
      this.scrollTimeout = null
      this._routeChanged = false
      this._isFirstRoute = true

      this._updateBodyClass()

      // Events
      document.addEventListener('scroll', this._onScroll)
      document.addEventListener('keyup', this.keyup)
      EventBus.$on('YLHomeLanding:hover', this._onHomeLandingWordIsHover)
    },

    mounted () {
      this.$store.commit(M.mediaQuery)
      window.addEventListener('resize', this.onResize)

      this.onResize()
    },

    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
      document.removeEventListener('keyup', this.keyup)
      document.removeEventListener('app:back-to-top', this.handleBackToTop, false)

      // Events
      EventBus.$on('YLHomeLanding:hover', this._onHomeLandingWordIsHover)
    },

    methods: {
      // Watchers
      _onContentIsFetchedChange () {
        if (this.contentIsFetched) {
          document.dispatchEvent(new Event('app:pre-rendering'))
        }
      },

      _onRouteChange () {
        this._routeChanged = true

        setTimeout(() => {
          scrollTo(0, false)

          EventBus.$emit('YLPopups:close')

          this.$store.commit(M.menuIsOpen, false)
        }, 50)

        if (this._isFirstRoute) {
          setTimeout(() => {
            this._hideTransitionPage()
          }, 100)
        }
      },

      _hideTransitionPage () {
        this._routeChanged = false

        setTimeout(() => {
          this.$store.commit(M.isTransitioning, 'is-down')

          setTimeout(() => {
            this.$store.commit(M.isTransitioning, 'is-up')
          }, 1050)
        }, 50)
      },

      // HTML Events
      loaded () {
        this.loading = false
      },

      // Events
      _onScroll () {
        const scrollTop = window.scrollY || window.pageYOffset || document.body.scrollTop + ((document.documentElement && document.documentElement.scrollTop) || 0)
        const scrollDirection = scrollTop <= 0 ? 'up' : scrollTop > this.scrollTop ? 'down' : scrollTop < this.scrollTop ? 'up' : 'static'

        // this.eventHub.$emit('scroll', scrollTop)
        this.$store.commit(M.scrollTop, scrollTop)
        this.$store.commit(M.scrollDirection, scrollDirection)

        clearTimeout(this.scrollTimeout)
        this.scrollTimeout = setTimeout(() => {
          if (this._routeChanged) {
            this._hideTransitionPage()
          }
        }, 100)
      },

      keyup (event) {
        EventBus.$emit('keyup', event)
      },

      _onHomeLandingWordIsHover (value = false) {
        this.homeLandingWordIsHover = value
      },

      // BODY
      _updateBodyClass () {
        const $body = document.body

        if ($body && this.support.classes.length > 0) {
          this.support.classes.map(c => $body.classList.add(c))

          $body.classList.add(`color-scheme--${this.$store.getters[G.colorScheme]}`)
        }
      },

      onResize: throttle(function () {
        this.$store.commit(M.mediaQuery)

        const lastWindowW = this.windowW
        this.$store.commit(M.windowW)
        this.$store.commit(M.windowH)

        const $body = document.body
        if ($body) {
          const mode = this.windowW > this.windowH ? 'landscape' : this.windowW < this.windowH ? 'portrait' : 'square'

          $body.classList.remove('is-landscape')
          $body.classList.remove('is-portrait')
          $body.classList.remove('is-square')
          $body.classList.add(`is-${mode}`)
        }

        if (this.isTouchDevice && this.windowW !== lastWindowW) {
          EventBus.$emit('resize')
        } else if (!this.isTouchDevice) {
          EventBus.$emit('resize')
        }
      }, 200)
    }
  }
</script>

<style lang="scss">
  @import "scss/app";

  html,
  body {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }

  .content-is-fetched {
    display: none;
  }
</style>

<style lang="scss" scoped>
  .app {
    width: 100vw;
    min-height: 100vh;

    &.is-prerendering {
      position: absolute !important;
      height: 1px; width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
    }
  }
</style>
