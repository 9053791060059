<template>
  <section class="yl-ambassadors-list"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-ambassadors-list__container">
      <yl-ambassadors-list-item v-for="(ambassador, ambassadorIndex) in ambassadors.filter(a => filter === null || a.ACF.country.value === filter)"
                                :key="`yl-ambassadors-list_item-${ambassadorIndex}`"
                                :ambassador="ambassador"
                                :index="ambassadorIndex">
      </yl-ambassadors-list-item>
    </div>
  </section>
</template>

<script>
  // Store
  import { GETTERS as G } from '@/store/helpers'

  // Components
  import YLAmbassadorsListItem from './YLAmbassadorsListItem'

  export default {
    name: 'YLAmbassadorsList',

    components: {
      'yl-ambassadors-list-item': YLAmbassadorsListItem
    },

    data () {
      return {
        filter: null
      }
    },

    computed: {
      ambassadors () {
        const ambassadors = this.$store.getters[G.ambassadors]
        return ambassadors !== null ? ambassadors : null
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      change (event) {
        this.filter = event.value
      },

      clear () {
        this.filter = null
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassadors-list {
    display: flex;
    justify-content: center;

    $colspan: colspan(2.5);
    width: calc(100% - #{$colspan});

    @include breakpoint('medium-') {
      width: 100%;
      padding-top: $base-px * 5;
    }
  }

  .yl-ambassadors-list__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 10,
      large: 5,
      xlarge: 5
    ));
  }
</style>
