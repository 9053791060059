<template>
  <section class="yl-social-networks"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-social-networks__container">
      <yl-social-networks-item v-for="(item, itemIndex) in items"
                               :key="`yl-social-networks_item-${itemIndex}`"
                               :item="item">
      </yl-social-networks-item>
    </div>
  </section>
</template>

<script>
  // Components
  import YLSocialNetworksItem from './YLSocialNetworksItem'

  export default {
    name: 'YLSocialNetworks',

    components: {
      'yl-social-networks-item': YLSocialNetworksItem
    },

    props: {
      /**
       * Array of social-networks
       * @param {Array} - *REQUIRED* items
       **/
      items: {
        type: Array,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-social-networks {
    display: inline-block;
  }

  .yl-social-networks__container {
    display: flex;
    align-items: center;
  }
</style>
