import { render, staticRenderFns } from "./YLLegaciesContent.vue?vue&type=template&id=761cb5ab&scoped=true&"
import script from "./YLLegaciesContent.vue?vue&type=script&lang=js&"
export * from "./YLLegaciesContent.vue?vue&type=script&lang=js&"
import style0 from "./YLLegaciesContent.vue?vue&type=style&index=0&id=761cb5ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "761cb5ab",
  null
  
)

export default component.exports