<template>
  <section class="yl-pillar-block-key-figures"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-block-key-figures__container">
      <p v-if="block.subtitle"
         class="yl-pillar-block-key-figures__subtitle u-marg-x-auto f-body-small f-color-brand-cream u-align-center">
        <yl-text-appearance :text="block.subtitle"></yl-text-appearance>
      </p>

      <div class="yl-pillar-block-key-figures__keys-container">
        <div v-for="(key, keyIndex) in block.keys"
             :key="`yl-pillar-block-key-figures-key-${keyIndex}`"
             class="yl-pillar-block-key-figures__key u-align-center">
          <div class="yl-pillar-block-key-figures__key__wrapper">
            <div class="yl-pillar-block-key-figures__key__subwrapper">
              <p class="f-pillar-key-figures-title f-color-brand-cream">
                <yl-text-appearance :text="key.title"
                                    :transition-delay="15">
                </yl-text-appearance>
              </p>

              <p class="u-marg-t-1 f-pillar-key-figures-text f-color-brand-white">
                <yl-text-appearance :text="key.text"
                                    :transition-delay="30">
                </yl-text-appearance>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="block.button"
           class="yl-pillar-block-key-figures__button-container u-marg-t-10 u-align-center">
        <yl-appearance klass="yl-pillar-block-key-figures__button">
          <yl-cta :label="block.button.label"
                  :to="{ name: block.button.to }">
          </yl-cta>
        </yl-appearance>
      </div>
    </div>
  </section>
</template>

<script>
  // Components
  import YLCTA from '@/components/elements/YLCTA'

  export default {
    name: 'YLPillarBlockKeyFigures.',

    components: {
      'yl-cta': YLCTA
    },

    props: {
      /**
       * @param {Object} block - The block.
       * @param {String} block.subtitle - subtitle for the block.
       * @param {String} block.chapeau - chapeau for the block.
       * @param {String} block.button - button's label for the block.
       **/
      block: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return [
          `has-${this.block.keys.length}-items`,
          this.block.subtitle ? 'has-subtitle' : ''
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-block-key-figures {
    display: flex;
    justify-content: center;
  }

  .yl-pillar-block-key-figures__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));
  }

  .yl-pillar-block-key-figures__subtitle {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 5,
      xlarge: 5
    ));
  }

  .yl-pillar-block-key-figures__keys-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .has-subtitle & {
      margin-top: $base-px * 2;

      @include breakpoint('medium-') {
        flex-wrap: wrap;
      }
    }

    .has-4-items & {
      @include breakpoint('medium-') {
        flex-wrap: wrap;
      }
    }
  }

  .yl-pillar-block-key-figures__key {
    position: relative;
    margin-left: -$base-px * 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $base-px * 1;

    border: 1px solid map-get($colors-list, "brand-green");
    border-radius: 50%;

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: 100%;
    }

    .has-3-items & {
      width: $base-px * 25;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 3;
        margin-left: -$base-px * 3 / 2;

        width: calc((100% - (#{$base-px} * 3 / 2)) / 2);

        &:nth-child(-n + 2) {
          margin-top: 0;
        }
      }
    }

    .has-4-items & {
      width: $base-px * 20;

      @include breakpoint('medium-') {
        margin-top: -$base-px * 3 / 2;
        margin-left: -$base-px * 3 / 2;

        width: calc((100% - (#{$base-px} * 3 / 2)) / 2);

        &:nth-child(-n + 2) {
          margin-top: 0;
        }
      }
    }
  }

  .yl-pillar-block-key-figures__key__wrapper {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .yl-pillar-block-key-figures__button-container {
    @include breakpoint('medium-') {
      margin-top: $base-px * 5;
    }
  }
</style>
