<template>
  <section class="yl-popups"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-popups__background u-bg-color-brand-black u-cursor-pointer"
         @click="close">
    </div>

    <div class="yl-popups__container u-bg-color-brand-black">
      <yl-popups-item v-for="(popup, popupIndex) in popups"
                      :key="`yl-popups_item-${popupIndex}`"
                      :popup="popup"
                      :index="popupIndex"
                      :visible="popup.id === indexOpened"
                      @close="close">
      </yl-popups-item>

      <yl-popups-ambassador v-for="(ambassador, ambassadorIndex) in ambassadors"
                            :key="`yl-popups_ambassador-${ambassadorIndex}`"
                            :ambassador="ambassador"
                            :visible="ambassador.id === ambassadorOpened"
                            @close="close">
      </yl-popups-ambassador>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S, GETTERS as G } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { enableScroll, disableScroll } from '@/helpers/scroll'

  // Components
  import YLPopupsItem from './YLPopupsItem'
  import YLPopupsAmbassador from './YLPopupsAmbassador'

  export default {
    name: 'YLPopups',

    components: {
      'yl-popups-item': YLPopupsItem,
      'yl-popups-ambassador': YLPopupsAmbassador
    },

    data () {
      return {
        indexOpened: null,
        ambassadorOpened: null
      }
    },

    computed: {
      editableContent () {
        return this.$store.state[S.editableContent]['popups']
      },

      popups () {
        return this.editableContent.list
      },

      ambassadors () {
        return this.$store.getters[G.ambassadors]
      },

      elClasses () {
        return [
          this.indexOpened !== null || this.ambassadorOpened !== null ? 'has-popup-open' : '',
          this.indexOpened !== null ? 'has-popup-legend-open' : '',
          this.ambassadorOpened !== null ? 'has-popup-ambassador-open' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('YLPopups:open', this.open)
      EventBus.$on('YLPopups:close', this.close)
      EventBus.$on('YLPopups:open-ambassador', this.openAmbassador)
      EventBus.$on('keyup', this.keyup)
    },

    beforeDestroy () {
      // Events
      EventBus.$off('YLPopups:open', this.open)
      EventBus.$off('YLPopups:close', this.close)
      EventBus.$off('YLPopups:open-ambassador', this.openAmbassador)
      EventBus.$off('keyup', this.keyup)
    },

    methods: {
      // Childs' events
      close () {
        this.indexOpened = null
        this.ambassadorOpened = null

        if (this.$route.meta.hasScroll || (this.isMobileDevice && this.$route.meta.hasScrollOnMobile)) {
          enableScroll()
        }
      },

      // Events
      open (index) {
        this.indexOpened = index

        disableScroll()
      },

      keyup (event) {
        if (event.keyCode === 27) {
          this.close()
        }
      },

      openAmbassador (index) {
        this.ambassadorOpened = index

        disableScroll()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-popups {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 5;

    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;

    visibility: hidden;

    transition: visibility 1s;
    will-change: visibility;

    &.has-popup-open {
      visibility: visible;
    }
  }

  .yl-popups__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;
    height: 100%;

    opacity: 0;

    transition: opacity 0.65s $ease-out-quint;
    transition-delay: 0.3s;
    will-change: opacity;

    .has-popup-open & {
      opacity: 0.5;

      transition-delay: 0s;
    }
  }

  .yl-popups__container {
    position: relative;
    transform: translateX(100%) translateZ(0);
    z-index: 2;

    $colspan: colspan(2.5);
    width: $colspan;
    height: 100%;

    opacity: 0;

    transition: transform 0.65s $ease-out-quint, opacity 0.65s $ease-out-quint;
    transition-delay: 0s;
    will-change: opacity;

    .has-popup-legend-open & {
      width: 50%;

      @include breakpoint('medium-') {
        width: 100%;
      }
    }

    .has-popup-open & {
      transform: translateX(0) translateZ(0);

      opacity: 1;

      transition-delay: 0.3s;
    }

    @include breakpoint('medium-') {
      width: 100%;
    }
  }
</style>
