<template>
  <section class="yl-legacies-content"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legacies-content__content u-marg-x-auto f-body f-color-brand-brown u-align-center">
      <yl-text-appearance :text="content.content"></yl-text-appearance>
    </div>

    <div class="yl-legacies-content__quote-container u-marg-x-auto">
      <div class="yl-legacies-content__quote-wrapper">
        <div class="yl-legacies-content__quote__image-container">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="image.bind" />
        </div>

        <div class="yl-legacies-content__quote-container__quote">
          <blockquote class="f-quote f-color-brand-white u-align-center">
            <yl-appearance>
              <span class="f-color-brand-green">“</span>&nbsp;<span v-html="content.quote.quote"></span>&nbsp;<span class="f-color-brand-green">”</span>
            </yl-appearance>
          </blockquote>

          <p class="u-marg-t-2 f-quote-author f-color-brand-green u-align-center">
            <yl-text-appearance :text="`- ${content.quote.author}`"></yl-text-appearance>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLLegaciesContent',

    props: {
      /**
       * Object w/ content for content and quote
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* content.content: Object for Event block
       * @param {Object} - *REQUIRED* content.quote: Object for Quote block
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      image () {
        return getImage({
          filename: this.content.quote.image
        })
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-content {
    padding-top: $base-px * 25;

    @include breakpoint('medium-') {
      padding-top: $base-px * 3;
    }
  }

  .yl-legacies-content__content {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8,
    ));

    @include breakpoint('medium-') {
      text-align: left;
    }
  }

  .yl-legacies-content__quote-container {
    margin-top: $base-px * 16;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 10,
      xlarge: 10,
    ));
  }

  .yl-legacies-content__quote-wrapper {
    display: inline-flex;
    align-items: center;

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }

  .yl-legacies-content__quote__image-container {
    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 6,
      large: 4,
      xlarge: 4,
    ));

    opacity: 0.85;

    @include breakpoint('medium-') {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .yl-legacies-content__quote-container__quote {
    position: relative;
    margin-left: colspan(0.25, 'xsmall', 0px, true);
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 7,
      xlarge: 7,
    ));

    @include breakpoint('medium-') {
      margin-top: -$base-px * 3;
      margin-left: 0;
    }
  }
</style>
