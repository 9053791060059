<template>
  <section class="yl-events-sidebar"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-events-sidebar__container">
      <div v-if="!isMobileDevice"
           class="yl-events-sidebar__wrapper">
        <div v-for="(category, categoryIndex) in allCategories"
             :key="`yl-events-sidebar_category-${categoryIndex}`"
             class="yl-events-sidebar__category u-cursor-pointer"
             :class="[ filter === category.id ? 'is-active' : '' ]"
             @click="$emit('change', category)">
          <div class="yl-events-sidebar__category__checkbox">
            <div class="yl-events-sidebar__category__checkbox__border"></div>

            <div class="yl-events-sidebar__category__checkbox__dot"></div>
          </div>

          <div class="yl-events-sidebar__category__label u-marg-l-2 f-body-subtitle-medium f-color-brand-white"
               v-html="category.name">
          </div>
        </div>
      </div>

      <div v-else>
        <yl-selector :placeholder="_placeholder"
                     :choices="_categories"
                     @change="selectorChange"
                     @clear="selectorClear">
        </yl-selector>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLSelector from '@/components/elements/YLSelector'

  export default {
    name: 'YLEventsSidebar',

    components: {
      'yl-selector': YLSelector
    },

    props: {
      /**
       * Array of categories
       * @param {Array} - *REQUIRED* categories
       **/
      categories: {
        type: Array,
        required: true
      },

      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content
       **/
      content: {
        type: Object,
        required: true
      },

      /**
       * Object or null for filter
       * @param {Object | null} - *REQUIRED* filter
       **/
      filter: {
        type: null,
        default: null
      }
    },

    computed: {
      allCategories () {
        return [
          {
            id: null,
            slug: null,
            name: this.content['allEventsLabel']
          },
          ...this.categories
        ]
      },

      _placeholder () {
        return {
          value: null,
          label: this.content['allEventsLabel']
        }
      },

      _categories () {
        const categories = []

        this.categories.forEach(category => {
          categories.push({
            value: category.slug,
            label: category.name
          })
        })

        return categories
      },

      isMobileDevice () {
        return this.$store.state[S.isMobileDevice]
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      },

      // HTML Events
      selectorChange (event) {
        const category = this.allCategories.filter(c => c.slug === event.value)[0]

        this.$emit('change', category)
      },

      selectorClear (event) {
        const category = this.allCategories.filter(c => c.slug === event.value)

        this.$emit('change', category)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-sidebar {
    position: sticky;
    top: 0;
    transform: translateX(-100%) translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;
    $colspan: colspan(1.5);
    width: $colspan;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: map-get($colors-list, "brand-black");
    opacity: 0;
    visibility: hidden;

    transition: transform 1s $ease-out-quint, opacity 0.85s $ease-out-quint, visibility 1s;
    will-change: transform, opacity, visibility;

    &:after {
      content: "";

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;

      background-image: url('~@/assets/images/events/yonex-legends_events_list_image.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.3;
    }

    .is-visible & {
      transform: translateZ(0);

      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('medium-') {
      position: relative;
      transform: translateZ(0);

      width: 100%;
      min-height: auto;
      height: auto;
      padding-top: $base-px * 5;
      padding-bottom: $base-px * 5;

      opacity: 1;
      visibility: visible;
    }
  }

  .yl-events-sidebar__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 2,
      xlarge: 2
    ));
  }

  .yl-events-sidebar__category {
    display: flex;
    align-items: center;
    padding-top: $base-px * 2;
    padding-bottom: $base-px * 2;

    border-top: 1px solid rgba(map-get($colors-list, "brand-white"), 0.4);

    &:first-child {
      border-top: none;
    }
  }

  .yl-events-sidebar__category__checkbox {
    position: relative;
    transform: translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: 100%;
    }
  }

  .yl-events-sidebar__category__checkbox__border {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;
    height: 100%;

    border: 1px solid map-get($colors-list, "brand-white");
    border-radius: 50%;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-events-sidebar__category:not(.is-active):hover & {
      transform: scale(1.2) translateZ(0);
    }
  }

  .yl-events-sidebar__category__checkbox__dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: 6px;
    height: 6px;

    background-color: map-get($colors-list, "brand-green");
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    .is-active & {
      opacity: 1;
      visibility: visible;
    }
  }
</style>
