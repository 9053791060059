<template>
  <div class="yl-appearance"
       :class="elClasses"
       :style="elStyle"
       v-observe-visibility="visibilityOpts">
    <slot></slot>
  </div>
</template>

<script>
  // Mixins
  import visibilityMixin from '@/mixins/visibility'

  export default {
    name: 'YLAppearance',

    mixins: [visibilityMixin],

    props: {
      /**
       * String w/ custom classes
       * @param {String} - klass
       **/
      klass: {
        type: String,
        default: ''
      },

      /**
       * If component is a block
       * @param {Boolean} - block
       **/
      block: {
        type: Boolean,
        default: false
      },

      /**
       * If component has transition
       * @param {Boolean} - transition
       **/
      transition: {
        type: Boolean,
        default: true
      },

      /**
       * Number in ms for transition-duration
       * @param {Number} - transitionDuration
       **/
      transitionDuration: {
        type: Number,
        default: 800
      },

      /**
       * Number in ms for transition-delay
       * @param {Number} - transitionDelay
       **/
      transitionDelay: {
        type: Number,
        default: 15
      },

      /**
       * rootMargin
       * @param {String} - rootMargin
       **/
      rootMargin: {
        type: String,
        default: '-50px'
      },

      /**
       * threshold
       * @param {[Number, Array]} - threshold
       **/
      threshold: {
        type: [Number, Array],
        default: 0
      }
    },

    data () {
      return {
        opacity: this.transition ? 0 : 1,
        isAnimating: false,
        isReveal: false,
        vRootMargin: this.rootMargin,
        vThresold: this.threshold,
        reveal: false
      }
    },

    computed: {
      elClasses () {
        return {
          [this.klass]: this.klass.length > 0,
          'is-animating': this.isAnimating,
          'is-reveal': this.isReveal,
          'is-block': this.block
        }
      },

      elStyle () {
        return {
          opacity: this.opacity,
          transitionDuration: `${this.transitionDuration}ms`
        }
      }
    },

    mounted () {
      this.$el.style.setProperty('--transition-delay', `${this.transitionDelay}ms`)
    },

    methods: {
      isVisibleCallback () {
        this.reveal = true

        this.$nextTick(() => {
          this.revealText()
        })
      },

      revealText () {
        window.setTimeout(() => {
          window.requestAnimationFrame(() => {
            this.isAnimating = true
            this.opacity = 1
            this.isReveal = true
            this.$emit('text-will-reveal')
            window.setTimeout(() => {
              this.$emit('text-reveal')
            }, this.transitionDuration + 50)
          })
        }, 50) // 3 frames in theory if user run at 60 fps
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-appearance {
    position: relative;
    transform: translateY($base-px * 5) translateZ(0);

    display: inline-block;

    opacity: 0;

    transition-property: transform, opacity;
    transition-timing-function: $ease-out-quint;
    transition-delay: var(--transition-delay, 15ms);
    will-change: transform, opacity;

    &.is-block {
      display: block;
    }

    &.is-reveal {
      transform: translateZ(0);

      opacity: 1;
    }
  }
</style>
