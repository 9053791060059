<template>
  <section v-if="ambassadorFeatured !== null"
           class="yl-ambassador-featured"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-ambassador-featured__container u-marg-x-auto u-align-center">
      <div class="yl-ambassador-featured__wrapper">
        <p class="f-body-subtitle f-color-brand-brown">
        <yl-text-appearance :text="content.subtitle"></yl-text-appearance>
        </p>

        <h4 class="yl-ambassador-featured__title u-marg-t-1 f-h4 f-color-brand-cream u-align-center">
          <yl-text-appearance :text="ambassadorFeatured.ACF.name"></yl-text-appearance>
        </h4>

        <yl-appearance>
          <div class="yl-ambassador-featured__image-container u-marg-t-3 u-marg-x-auto">
            <yl-image-lazy-load klass="yl-ambassador-featured__image"
                              bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="ambassadorFeatured.ACF.picture.bind" />
          </div>
        </yl-appearance>

        <div class="yl-ambassador-featured__text-container u-marg-t-3 u-marg-x-auto f-body-small f-color-brand-white">
          <yl-text-appearance :text="ambassadorFeatured.ACF.short_description"></yl-text-appearance>
        </div>
      </div>

      <div class="yl-ambassador-featured__button-container u-marg-t-4">
        <yl-appearance klass="yl-ambassador-featured__button">
          <yl-cta :label="content.button.label"
                  :to="{ name: content.button.to }">
          </yl-cta>
        </yl-appearance>
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Components
  import YLCTA from '@/components/elements/YLCTA'

  export default {
    name: 'YLAmbassadorFeatured',

    components: {
      'yl-cta': YLCTA
    },

    props: {
      /**
       * Object w/ content for event
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* content.subtitle: Subtitle for block
       * @param {Object} - *REQUIRED* content.button: Object block's button
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      ambassadorFeatured () {
        return this.$store.state[S.ambassadorFeatured]
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassador-featured {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 5,
      xlarge: 5,
    ));
    padding: $base-px * 8 $base-px * 4;

    background-color: rgba(map-get($colors-list, "brand-black"), 0.5);

    @include breakpoint('medium-') {
      margin-top: $base-px * 4;

      padding: $base-px * 4 0;

      .pevents & {
        margin-top: 0;
      }
    }
  }

  .yl-ambassador-featured__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include breakpoint('medium-') {
      align-items: center;
    }
  }

  .yl-ambassador-featured__wrapper {
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 8
    ));
  }

  .yl-ambassador-featured__title {
    @include width-multi((
      xsmall: 4,
      small: 4,
      medium: 8
    ));
  }

  .yl-ambassador-featured__image-container {
    position: relative;

    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 8,
      large: 3,
      xlarge: 3
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 302 * 177);
    }
  }

  .yl-ambassador-featured__image {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  .yl-ambassador-featured__text-container {
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 8,
      large: 3,
      xlarge: 3
    ));
  }

  .yl-ambassador-featured__button-container {
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 8
    ));
  }
</style>
