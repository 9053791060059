<template>
  <section class="pcommunity-enrichment">
    <div ref="button"
         class="pcommunity-enrichment__button-container f-color-brand-black">
      <yl-round-button size="100px"
                       icon="full-arrow--bottom"
                       @click="scroll">
      </yl-round-button>
    </div>

    <div class="pcommunity-enrichment__sidebar">
      <yl-pillar-sidebar :index="1"
                         :name="$route.name">
      </yl-pillar-sidebar>
    </div>

    <yl-pillar-container :index="1"
                         :name="$route.name">
      <yl-pillar-header :content="editableContent.header"
                        :index="1">
      </yl-pillar-header>

      <div class="pcommunity-enrichment__blocks-container u-marg-x-auto">
        <yl-pillar-block type="h2-icon-text-image" :index="0">
          <yl-pillar-block-h2-icon-text-image :block="blocks[0]"></yl-pillar-block-h2-icon-text-image>
        </yl-pillar-block>

        <yl-pillar-block type="quote" :index="1">
          <yl-pillar-block-quote :block="blocks[1]"></yl-pillar-block-quote>
        </yl-pillar-block>

        <yl-pillar-block type="h2-icon-text-image" :index="2">
          <yl-pillar-block-h2-icon-text-image :block="blocks[2]"></yl-pillar-block-h2-icon-text-image>
        </yl-pillar-block>

        <yl-pillar-block type="case-study" :index="3">
          <yl-pillar-block-case-study :block="blocks[3]"></yl-pillar-block-case-study>
        </yl-pillar-block>

        <yl-pillar-block type="key-figures" :index="4">
          <yl-pillar-block-key-figures :block="blocks[4]"></yl-pillar-block-key-figures>
        </yl-pillar-block>

        <yl-pillar-block type="quote" :index="5">
          <yl-pillar-block-quote :block="blocks[5]"></yl-pillar-block-quote>
        </yl-pillar-block>

        <yl-pillar-block type="h2-icon-text-image" :index="6">
          <yl-pillar-block-h2-icon-text-image :block="blocks[6]"></yl-pillar-block-h2-icon-text-image>
        </yl-pillar-block>

        <yl-pillar-block type="case-study" :index="7">
          <yl-pillar-block-case-study :block="blocks[7]"></yl-pillar-block-case-study>
        </yl-pillar-block>
      </div>
    </yl-pillar-container>

    <yl-pillar-footer :index="1"
                      :content="editableContent.footer">
    </yl-pillar-footer>
  </section>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Store
  import { STATE as S } from '@/store/helpers'

  // Behavior
  import RAF from '@/behavior/RAF'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { scrollTo } from '@/helpers/scroll'

  // Components
  // Elements
  import YLRoundButton from '@/components/elements/YLRoundButton'
  // Pillar
  import YLPillarContainer from '@/components/pillar/YLPillarContainer'
  import YLPillarHeader from '@/components/pillar/YLPillarHeader'
  import YLPillarFooter from '@/components/pillar/YLPillarFooter'
  import YLPillarSidebar from '@/components/pillar/YLPillarSidebar'
  // Blocks
  import YLPillarBlock from '@/components/pillar/blocks/YLPillarBlock'
  import YLPillarBlockH2 from '@/components/pillar/blocks/YLPillarBlockH2'
  import YLPillarBlockTextImage from '@/components/pillar/blocks/YLPillarBlockTextImage'
  import YLPillarBlockQuote from '@/components/pillar/blocks/YLPillarBlockQuote'
  import YLPillarBlockH2IconTextImage from '@/components/pillar/blocks/YLPillarBlockH2IconTextImage'
  import YLPillarBlockCaseStudy from '@/components/pillar/blocks/YLPillarBlockCaseStudy'
  import YLPillarBlockConcept from '@/components/pillar/blocks/YLPillarBlockConcept'
  import YLPillarBlockVideo from '@/components/pillar/blocks/YLPillarBlockVideo'
  import YLPillarBlockKeyFigures from '@/components/pillar/blocks/YLPillarBlockKeyFigures'

  export default {
    name: 'PCommunityEnrichent',
    pageName: 'community-enrichment',
    mixins: [PageMixin],

    components: {
      // Elements
      'yl-round-button': YLRoundButton,
      // Pillar
      'yl-pillar-container': YLPillarContainer,
      'yl-pillar-header': YLPillarHeader,
      'yl-pillar-footer': YLPillarFooter,
      'yl-pillar-sidebar': YLPillarSidebar,
      // Blocks
      'yl-pillar-block': YLPillarBlock,
      'yl-pillar-block-h2': YLPillarBlockH2,
      'yl-pillar-block-text-image': YLPillarBlockTextImage,
      'yl-pillar-block-quote': YLPillarBlockQuote,
      'yl-pillar-block-h2-icon-text-image': YLPillarBlockH2IconTextImage,
      'yl-pillar-block-case-study': YLPillarBlockCaseStudy,
      'yl-pillar-block-concept': YLPillarBlockConcept,
      'yl-pillar-block-video': YLPillarBlockVideo,
      'yl-pillar-block-key-figures': YLPillarBlockKeyFigures
    },

    computed: {
      blocks () {
        return this.editableContent.blocks
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()

        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)

      this.stopRAF()
    },

    methods: {
      // Events
      _onResize (test) {
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      },

      scroll () {
        scrollTo(this.windowH)
      },

      // RAF
      rafCallback () {
        const $button = this.$refs['button']

        if ($button && this.scrollTop > this.windowH / 100 * 15) {
          if ($button && !$button.className.includes('is-hidden')) {
            $button.classList.add('is-hidden')
          }
        } else {
          if ($button && $button.className.includes('is-hidden')) {
            $button.classList.remove('is-hidden')
          }
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pcommunity-enrichment {
    position: relative;
    min-height: 100vh;
  }

  .pcommunity-enrichment__button-container {
    position: absolute;
    right: $base-px * 6.4;
    top: 100vh;
    transform: translateY(-100%) translateY($base-px * 6 * -1) translateZ(0);
    z-index: 2;

    opacity: 1;
    visibility: visible;

    transition: opacity 0.55s $ease-out-quint, visibility 0.55s;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .pcommunity-enrichment__sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    width: $base-px * 13;
    height: 100%;

    @include breakpoint('medium-') {
      display: none;
    }
  }

  .pcommunity-enrichment__blocks-container {
    margin-top: $base-px * 13;
    margin-bottom: $base-px * 20;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 10;
      margin-bottom: $base-px * 10;
    }
  }
</style>
