<template>
  <section class="plegacies">
    <yl-legacies-header :header="editableContent['header']"></yl-legacies-header>

    <yl-legacies-content :content="editableContent['content']"></yl-legacies-content>

    <yl-legacies-list-with-sidebar :content="editableContent['list']"></yl-legacies-list-with-sidebar>

    <div class="plegacies__push-pages-container u-marg-x-auto">
      <yl-pillar-push-pages :content="editableContent['push-pages']"></yl-pillar-push-pages>
    </div>

    <yl-legacies-featured :content="editableContent['featured']"></yl-legacies-featured>
  </section>
</template>

<script>
  // Mixins
  import PageMixin from '@/mixins/page'

  // Components
  import YLLegaciesHeader from '@/components/legacies/YLLegaciesHeader'
  import YLLegaciesContent from '@/components/legacies/YLLegaciesContent'
  import YLPillarPushPages from '@/components/pillar/YLPillarPushPages'
  import YLLegaciesFeatured from '@/components/legacies/YLLegaciesFeatured'
  import YLLegaciesListWithSidebar from '@/components/legacies/YLLegaciesListWithSidebar'

  export default {
    name: 'PLegacies',
    pageName: 'legacies',
    mixins: [PageMixin],

    components: {
      'yl-legacies-header': YLLegaciesHeader,
      'yl-legacies-content': YLLegaciesContent,
      'yl-pillar-push-pages': YLPillarPushPages,
      'yl-legacies-featured': YLLegaciesFeatured,
      'yl-legacies-list-with-sidebar': YLLegaciesListWithSidebar
    }
  }
</script>

<style lang="scss" scoped>
  .plegacies {
    position: relative;
    min-height: 100vh;
  }

  .plegacies__push-pages-container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 10,
      xlarge: 10
    ));
  }
</style>
