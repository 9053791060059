<template>
  <section class="yl-ambassadors-statistics"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-ambassadors-statistics__background"></div>

    <div class="yl-ambassadors-statistics__container">
      <yl-amabassadors-statistics-item v-for="(statistic, statisticIndex) in statistics"
                                       :key="`yl-ambassadors-statistics_item-${statisticIndex}`"
                                       :statistic="statistic"
                                       :index=statisticIndex>
      </yl-amabassadors-statistics-item>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLAmbassadorsStatisticsItem from './YLAmbassadorsStatisticsItem'

  export default {
    name: 'YLAmbassadorsStatistics',

    components: {
      'yl-amabassadors-statistics-item': YLAmbassadorsStatisticsItem
    },

    props: {
      /**
       * Array of statistics
       * @param {Array} - *REQUIRED* statistics: Array of statistics
       **/
      statistics: {
        type: Array,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassadors-statistics {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    @include breakpoint('small-') {
      height: auto;
      padding-top: $base-px * 7;
      padding-bottom: $base-px * 7;
    }
  }

  .yl-ambassadors-statistics__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: url('~@/assets/images/ambassadors/yonex-legends_ambassadors_statistics_background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.14;
  }

  .yl-ambassadors-statistics__container {
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8
    ));

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }
</style>
