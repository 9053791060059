<template>
  <section v-if="events !== null"
           class="yl-events-list-with-sidebar u-marg-t-15"
           :class="elClasses"
           :style="elStyle"
           v-observe-visibility="visibilityOpts">
    <div class="yl-events-list-with-sidebar__container u-marg-x-auto">
      <yl-events-sidebar :categories="categories"
                         :content="content['categories']"
                         :filter="filterID"
                         @change="change">
      </yl-events-sidebar>

      <yl-events-list :past-events="pastEvents"
                      :next-event="nextEvent"
                      :next-events="nextEvents"
                      :content="content['events']"
                      :filter="filterID">
      </yl-events-list>
    </div>
  </section>
</template>

<script>
  // Mixins
  import visibilityMixin from '@/mixins/visibility'

  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { scrollTo } from '@/helpers/scroll'

  // Components
  import YLEventsSidebar from './YLEventsSidebar'
  import YLEventsList from './YLEventsList'

  export default {
    name: 'YLEventsListWithSidebar',

    mixins: [visibilityMixin],

    components: {
      'yl-events-sidebar': YLEventsSidebar,
      'yl-events-list': YLEventsList
    },

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content
       * @param {Object} - *REQUIRED* content.categories: Content for sidebar
       * @param {Object} - *REQUIRED* content.list: Content for events list
       **/
      content: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        filterID: null,
        vRootMargin: this.rootMargin,
        vThresold: 0.1,
        visible: false
      }
    },

    computed: {
      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      events () {
        const events = this.$store.state[S.events]

        if (events !== null) {
          const eventsSorted = events.sort((a, b) => {
            const dA = new Date(a.ACF.start_date)
            const dB = new Date(b.ACF.start_date)

            return dA - dB
          })

          return eventsSorted.map(event => {
            event.ACF.start_date_formatted = (new Date(event.ACF.start_date)).toLocaleDateString('fr-FR')
            if (event.ACF.end_date) {
              event.ACF.end_date_formatted = (new Date(event.ACF.end_date)).toLocaleDateString('fr-FR')
            }

            return event
          })
        }

        return null
      },

      pastEvents () {
        if (this.events && this.events.length > 0) {
          const now = new Date()
          now.setHours(0, 0, 0, 0)
          const eventsPast = this.events.filter(event => new Date(event.ACF.start_date) - now < 0)

          return eventsPast.reverse()
        }

        return null
      },

      nextEvents () {
        if (this.events && this.events.length > 0) {
          const now = new Date()
          now.setHours(0, 0, 0, 0)
          const eventsNotPassed = this.events.filter(event => new Date(event.ACF.start_date) - now >= 0)

          return eventsNotPassed.reverse()
        }

        return null
      },

      nextEvent () {
        if (this.nextEvents && this.nextEvents.length > 0) {
          return this.nextEvents[this.nextEvents.length - 1]
        }

        return null
      },

      categories () {
        const categories = []

        if (this.events) {
          this.events.forEach(event => {
            const category = event.ACF.category

            categories.push({
              name: category.name,
              slug: category.slug,
              id: category.term_taxonomy_id
            })
          })

          const uCategories = categories.filter((category, index, self) => index === self.findIndex(c => c.id === category.id))

          return uCategories
        }

        return categories
      },

      elClasses () {
        return [
          this.visible ? 'is-visible' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    methods: {
      // Child's Events
      change (event) {
        this.filterID = event.id

        scrollTo(this.$el.getBoundingClientRect().top + this.scrollTop)
      },

      // Visibility
      isVisibleCallback () {
        this.visible = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-list-with-sidebar {

  }

  .yl-events-list-with-sidebar__container {
    display: flex;
    justify-content: space-between;

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }
</style>
