<template>
  <a class="yl-social-networks-item u-marg-l-2"
           :class="elClasses"
           :style="elStyle"
           :href="item.url"
           target="_blank"
           rel="noopener">
    <yl-appearance klass="yl-social-networks-item__container"
                   :transition-delay="index * 15">
      <div class="yl-social-networks-item__border"></div>

      <div class="yl-social-networks-item__icon-container f-color-brand-cream">
          <yl-svg-icon :icon="item.type"></yl-svg-icon>
      </div>
    </yl-appearance>
  </a>
</template>

<script>
  export default {
    name: 'YLSocialNetworksItem',

    props: {
      /**
       * Object for social network
       * @param {Object} - *REQUIRED* item
       * @param {String} - *REQUIRED* item.type: Type of social network (facebook | instagram | twitter)
       * @param {String} - *REQUIRED* item.url: URL to redirect to
       **/
      item: {
        type: Object,
        required: true
      },

      /**
       * Position of item in list
       * @param {Number} - index
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      elClasses () {
        return [
          `is-type-${this.item.type}`
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-social-networks-item {
    position: relative;

    width: $base-px * 5;

    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: 100%;
    }

    @include breakpoint('small-') {
      width: $base-px * 6;
    }
  }

  .yl-social-networks-item__container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;

    width: 100% !important;
    height: 100% !important;
  }

  .yl-social-networks-item__border {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale(1) translateZ(0);

    width: 100%;
    height: 100%;

    border: 1px solid map-get($colors-list, "brand-cream");
    border-radius: 50%;
    opacity: 0.5;

    transition: transform 0.45s $ease-out-quint, opacity 0.45s $ease-out-quint;
    will-change: transform, opacity;

    .yl-social-networks-item:hover & {
      transform: scale(1.1) translateZ(0);

      opacity: 1;
    }
  }

  .yl-social-networks-item__icon-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>
