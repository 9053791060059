<template>
  <component :is="hasLink ? 'a' : 'section'"
             :href="event.ACF.link"
             target="_blank"
             rel="noopener"
             class="yl-events-list-next-item"
             :class="elClasses"
             :style="elStyle">
    <div class="yl-events-list-next-item__overlay">
      <p class="yl-events-list-next-item__overlay__text f-body-subtitle-medium f-color-brand-white u-align-center"
         v-html="hasLink ? event.ACF.hover_text || content.hoverLinkLabel : event.ACF.hover_text || content.hoverNoLinkLabel">
      </p>
    </div>

    <div class="yl-events-list-next-item__col-left u-align-center">
      <p class="f-event-h3 f-color-brand-cream"
           v-html="event.ACF.category.name">
      </p>

      <div>
        <p class="yl-events-list-next-item__header__location f-event-h3 f-color-brand-white"
           v-html="event.ACF.location">
        </p>

        <p v-if="event.ACF.venue"
           class="yl-events-list-next-item__header__venue f-body-smaller f-color-brand-cream"
           v-html="event.ACF.venue">
        </p>
      </div>

      <div class="yl-events-list-next-item__header__date">
        <p class="f-body-smaller f-color-brand-cream"
           v-html="event.ACF.start_date_formatted">
        </p>

        <p v-if="event.ACF.end_date_formatted"
           class="f-body-smaller f-color-brand-cream"
           v-html="`- ${event.ACF.end_date_formatted}`">
        </p>
      </div>
    </div>

    <div class="yl-events-list-next-item__col-center">
      <div class="yl-events-list-next-item__title u-pad-y-3 f-event-h1 u-align-center"
           v-html="content.title">
      </div>

      <div class="yl-events-list-next-item__informations u-pad-y-2">
        <div class="yl-events-list-next-item__informations__col-left u-align-center">
          <p class="f-event-h2 f-color-brand-green"
             v-html="event.ACF.start_date_formatted">
          </p>

          <p v-if="event.ACF.end_date_formatted"
             class="f-event-h2 f-color-brand-green"
             v-html="`|<br/>${event.ACF.end_date_formatted}`">
          </p>
        </div>

        <div class="yl-events-list-next-item__informations__col-right f-event-h2 f-color-brand-white"
             v-html="event.ACF.name">
        </div>
      </div>

      <div class="yl-events-list-next-item__footer u-pad-y-2">
        <p class="f-home-pillar-card-footer f-color-brand-cream f-tf-uppercase"
           v-html="content.barcodeLabel">
        </p>

        <div class="yl-events-list-next-item__footer__icons">
          <img v-for="(icon, iconIndex) in icons"
               :key="`yl-events-list-next-item_icon-${iconIndex}`"
               class="yl-events-list-next-item__footer__icon"
               v-bind="icon.bind" />
        </div>
      </div>
    </div>

    <div class="yl-events-list-next-item__col-right">
      <div class="yl-events-list-next-item__barcode-container">
        <p class="yl-events-list-next-item__barcode__label f-home-pillar-card-footer f-color-brand-cream f-tf-uppercase"
           v-html="content.barcodeLabel">
        </p>

        <img v-if="!isMobileDevice"
             v-bind="barcode.bind" />

        <img v-else
             v-bind="barcodeH.bind" />
      </div>
    </div>
  </component>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLEventsListNextItem',

    props: {
      /**
       * Object w/ content of an event from API
       * @param {Object} - *REQUIRED* event
       * @param {Object} - *REQUIRED* event.ACF: Object from API
       **/
      event: {
        type: Object,
        required: true
      },

      /**
       * Object w/ editable content
       * @param {Object} - *REQUIRED* content
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      icons () {
        return [
          getImage({
            filename: 'events/yonex-legends_events_next-event_icon-00.png'
          }),
          getImage({
            filename: 'events/yonex-legends_events_next-event_icon-01.png'
          }),
          getImage({
            filename: 'events/yonex-legends_events_next-event_icon-02.png'
          })
        ]
      },

      isMobileDevice () {
        return this.$store.state[S.isMobileDevice]
      },

      barcode () {
        return getImage({
          filename: 'events/yonex-legends_events_barcode-v.png'
        })
      },

      barcodeH () {
        return getImage({
          filename: 'events/yonex-legends_events_barcode-h--brand-green.png'
        })
      },

      hasLink () {
        return this.event.ACF.link && this.event.ACF.link && this.event.ACF.link.trim() !== ''
      },

      elClasses () {
        return [
          this.hasLink ? 'has-link' : ''
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-list-next-item {
    position: relative;

    display: flex;

    border: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);

    &.has-link {
      cursor: pointer;
    }

    @include breakpoint('small-') {
      flex-direction: column;
    }
  }

  .yl-events-list-next-item__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background-color: rgba(map-get($colors-list, "brand-green"), 0.85);
    opacity: 0;
    visibility: hidden;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    .yl-events-list-next-item:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-events-list-next-item__overlay__text {
    position: relative;
    transform: translateY(100%) translateZ(0);

    max-width: 70%;

    opacity: 0;
    visibility: hidden;

    transition: transform 0.45s $ease-out-quint, opacity 0.45s $ease-out-quint, visibility 0.45s;
    transition-delay: 0;
    will-change: transform, opacity, visibility;

    .yl-events-list-next-item:hover & {
      transform: translateY(0) translateZ(0);

      opacity: 1;
      visibility: visible;

      transition-delay: 0.2s;
    }
  }

  .yl-events-list-next-item__col-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% * 170 / 755);
    padding: $base-px * 3 $base-px * 2;

    @include breakpoint('small-') {
      width: 100%;

      text-align: left;
    }
  }

  .yl-events-list-next-item__header__location {
    @include breakpoint('small-') {
      margin-top: $base-px;
    }
  }

  .yl-events-list-next-item__header__venue {
    @include breakpoint('small-') {
      margin-top: $base-px / 2;
    }
  }

  .yl-events-list-next-item__header__date {
    @include breakpoint('small-') {
      text-align: right;
    }
  }

  .yl-events-list-next-item__col-center {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - (100% * 170 / 755) - (100% * 110 / 755));
    padding-right: $base-px * 3;
    padding-left: $base-px * 3;

    border-right: 1px dashed rgba(map-get($colors-list, "brand-white"), 0.3);
    border-left: 1px dashed rgba(map-get($colors-list, "brand-white"), 0.3);

    @include breakpoint('small-') {
      width: 100%;
      padding-right: $base-px * 2;
      padding-left: $base-px * 2;

      border-top: 1px dashed rgba(map-get($colors-list, "brand-white"), 0.3);
      border-right: none;
      border-bottom: 1px dashed rgba(map-get($colors-list, "brand-white"), 0.3);
      border-left: none;
    }
  }

  .yl-events-list-next-item__col-right {
    display: flex;
    align-items: center;
    width: calc(100% * 110 / 755);
    padding: $base-px * 3;

    @include breakpoint('small-') {
      width: 100%;
    }
  }

  .yl-events-list-next-item__title {
    @include text-stroke(1px, "brand-cream");
  }

  .yl-events-list-next-item__informations {
    display: flex;
    height: 100%;

    border-top: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
    border-bottom: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);

    @include breakpoint('small-') {
      flex-direction: column;

      border-bottom: none;
    }
  }

  .yl-events-list-next-item__informations__col-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 50%;
    padding: $base-px $base-px * 3;

    @include breakpoint('small-') {
      width: 100%;
    }
  }

  .yl-events-list-next-item__informations__col-right {
    display: flex;
    align-items: center;
    width: 50%;
    padding: $base-px * 2 $base-px * 3;

    border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);

    @include breakpoint('small-') {
      position: relative;

      width: 100%;
      justify-content: center;

      border-left: none;
      text-align: center;

      &:before {
        content: "";

        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateZ(0);

        width: $base-px * 5;
        height: 1px;

        background-color: rgba(map-get($colors-list, "brand-white"), 0.3);
      }
    }
  }

  .yl-events-list-next-item__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint('small-') {
      display: none;
    }
  }

  .yl-events-list-next-item__footer__icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .yl-events-list-next-item__footer__icon {
    max-width: $base-px * (8 + 4) !important;
    max-height: $base-px * 3 !important;
    padding-right: $base-px * 2;
    padding-left: $base-px * 2;

    border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3) !important;

    opacity: 0.3;

    &:first-child {
      max-width: $base-px * (3.5 + 4) !important;

      border-left: 0;
    }

    &:last-child {
      max-width: $base-px * (8 + 2) !important;
      padding-right: 0;
    }
  }

  .yl-events-list-next-item__barcode-container {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: $base-px * 30 - ($base-px * 3 * 2);
  }

  .yl-events-list-next-item__barcode__label {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(102%) rotate(90deg) translateZ(0);
    transform-origin: 0 0;

    white-space: nowrap;

    @include breakpoint('small-') {
      position: relative;
      margin-bottom: $base-px;
      transform: translateZ(0);
    }
  }
</style>
