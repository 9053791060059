/**
 * RAF
 * Optimize animations on scroll
 * Simple Singleton to listen scroll and update things on raf
 */

// Helpers
import { randomUUID } from '@/helpers/random'

class RAF {
  constructor () {
    if (!RAF.instance) {
      this._isRunning = false
      this._callbacksMap = {}
      this._callbacks = []

      RAF.instance = this
    }

    return RAF.instance
  }

  update () {
    this._callbacks.forEach(callback => {
      callback()
    })

    this.rafID = window.requestAnimationFrame(this.update.bind(this))
  }

  run () {
    this._isRunning = true
    this.rafID = window.requestAnimationFrame(this.update.bind(this))
  }

  stop () {
    this._isRunning = false
    window.cancelAnimationFrame(this.rafID)
  }

  /**
   * Add Method
   * @param callback, an object with top and down method
   * @returns Number, a timestamp corresponding to id of callback
   */
  add (callback) {
    if (typeof callback !== 'function') return false
    const uid = randomUUID()
    this._callbacksMap[uid] = callback
    this._callbacks = Object.values(this._callbacksMap)

    if (this._isRunning) return uid

    this.run()
    return uid
  }

  /**
   * Remove Method
   * Pass an uid of callback as param
   * @param uid
   * @returns {null}
   */
  remove (uid) {
    if (!this._callbacksMap[uid]) return
    delete this._callbacksMap[uid]
    this._callbacks = Object.values(this._callbacksMap)
    if (this._callbacks.length === 0) {
      this.stop()
    }
    return null
  }

  destroy () {
    this.stop()
    this._callbacks = []
    this._callbacksMap = {}
  }
}

const instance = new RAF()

export default instance
