<template>
  <section class="yl-header"
           :style="elStyle">
    <router-link ref="logo" class="yl-header__logo-link" :to="{ name: 'home' }">
      <img class="yl-header__logo-link__image"
           v-bind="content.logotype.bind" />
    </router-link>

    <yl-header-burger-menu></yl-header-burger-menu>
  </section>
</template>

<script>
  // Data
  import headerData from '@/data/header'

  // Store
  import { MUTATIONS as M, STATE as S } from '@/store/helpers'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  // Components
  import YLHeaderBurgerMenu from './YLHeaderBurgerMenu'

  export default {
    name: 'YLHeader',

    components: {
      'yl-header-burger-menu': YLHeaderBurgerMenu
    },

    data () {
      return {
        content: headerData()
      }
    },

    computed: {
      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      elStyle () {
        return {
          'height': `${this.headerHeight}px`
        }
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        this.$store.commit(M.headerHeight, this.$refs['logo'].$el.offsetHeight || 60)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-header {
    position: fixed;
    top: $base-px * 6;
    left: 50%;
    transform: translateX(-50%) translateZ(0);
    z-index: 5;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - (2 * #{$base-px} * 6.4));

    transition: transform 1s;
    will-change: transform;

    @include breakpoint('medium-') {
      top: $base-px * 3;

      width: calc(100% - (2 * #{$base-px} * 3));

      .is-scrolling-up &,
      .is-scrolling-static & {
        transform: translateX(-50%) translateZ(0);
      }

      .is-scrolling-down & {
        transform: translateX(-50%) translatey(-100%) translateY(-$base-px * 6) translateZ(0);
      }
    }
  }

  .yl-header__logo-link {
    position: absolute;
    top: 0;
    left: 0;

    display: block;
  }

  .yl-header__logo-link__image {
    width: 103px;
    height: 43px;

    @include breakpoint('medium-') {
      width: 76px;
      height: 33px;
    }
  }
</style>
