<template>
  <section v-if="legacies !== null"
           class="yl-legacies-list-with-sidebar u-marg-t-16"
           :class="elClasses"
           :style="elStyle"
           v-observe-visibility="visibilityOpts">
    <div class="yl-legacies-list-with-sidebar__container u-marg-x-auto">
      <yl-legacies-sidebar-categories :categories="categories"
                                      :content="content['categories']"
                                      :active-category="activeCategory"
                                      @click="changeActiveCategory">
      </yl-legacies-sidebar-categories>

      <yl-legacies-list :categories="categories"
                        ref="list">
      </yl-legacies-list>
    </div>
  </section>
</template>

<script>
  // Mixins
  import visibilityMixin from '@/mixins/visibility'

  // Store
  import { STATE as S } from '@/store/helpers'

  // Behavior
  import RAF from '@/behavior/RAF'

  // Helpers
  import { scrollTo } from '@/helpers/scroll'

  // Components
  import YLLegaciesSidebarCategories from './YLLegaciesSidebarCategories'
  import YLLegaciesList from './YLLegaciesList'

  export default {
    name: 'YLLegaciesListWithSidebar',

    mixins: [visibilityMixin],

    components: {
      'yl-legacies-sidebar-categories': YLLegaciesSidebarCategories,
      'yl-legacies-list': YLLegaciesList
    },

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {Object} - *REQUIRED* content.categories: Object for Categories block
       * @param {String} - *REQUIRED* content.ambassadorLabel: String for "Ambassadors"
       **/
      content: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        activeCategory: 'juniors',
        vRootMargin: this.rootMargin,
        vThresold: 0.1,
        visible: false
      }
    },

    computed: {
      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      legacies () {
        const legacies = this.$store.state[S.legacies]
        return legacies !== null ? legacies : null
      },

      categories () {
        const categories = []

        if (this.legacies) {
          this.legacies.forEach(legacy => {
            const category = legacy.ACF.category

            categories.push({
              name: category.name,
              slug: category.slug,
              id: category.term_taxonomy_id,
              legacies: []
            })
          })

          const uCategories = categories.filter((category, index, self) => index === self.findIndex(c => c.id === category.id))

          uCategories.forEach(category => {
            category.legacies = this.legacies.filter(legacy => legacy.ACF.category.slug === category.slug)
          })

          return uCategories
        }

        return categories
      },

      elClasses () {
        return [
          this.visible ? 'is-visible' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Variables
      this._categoriesRect = null
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()

        this._onResize()
      })
    },

    beforeDestroy () {
      this.stopRAF()
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        if (this.$el && this.$el.style) {
          this.$el.style.setProperty('--vh', `${vh}px`)
        }

        if (this.$refs['list']) {
          this._categoriesRect = this.$refs['list']._getCategoriesBoundingRect()
        }
      },

      // HTML Events
      changeActiveCategory (slug) {
        // this.activeCategory = slug

        if (this._categoriesRect !== null && this.$refs['list']) {
          const _categoriesRect = this.$refs['list']._getCategoriesBoundingRect()
          const category = _categoriesRect.filter(c => c.slug === slug)[0]

          if (category) {
            scrollTo(category.rect.top)
          }
        }
      },

      // Visibility
      isVisibleCallback () {
        this.visible = true
      },

      // RAF
      rafCallback () {
        if (this._categoriesRect !== null) {
          let activeCategory = this.activeCategory
          this._categoriesRect.forEach(category => {
            if (this.scrollTop >= category.rect.top) {
              activeCategory = category.slug
            }
          })

          this.activeCategory = activeCategory
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-list-with-sidebar {

  }

  .yl-legacies-list-with-sidebar__container {
    display: flex;
    justify-content: space-between;

    @include breakpoint('medium-') {
      flex-direction: column;
    }
  }
</style>
