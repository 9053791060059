<template>
  <section class="yl-pillar-push-pages-item"
           :class="elClasses"
           :style="elStyle">
    <router-link class="yl-pillar-push-pages-item__link"
                 :to="{ name: item.to }">
      <div class="yl-pillar-push-pages-item__cover-container u-overflow-h">
        <yl-image-lazy-load :sources="image.bind"
                            klass="yl-pillar-push-pages-item__cover"
                            :delay="`${index * 0.15}s`" />

        <div class="yl-pillar-push-pages-item__number f-pillar-number"
             v-html="item.number">
        </div>
      </div>

      <div class="yl-pillar-push-pages-item__content-container u-marg-t-3">
        <p class="f-body-subtitle f-color-brand-cream"
            v-html="item.subtitle">
        </p>

        <h5 class="u-marg-t-1 f-pillar-footer-title f-color-brand-white"
            v-html="item.title">
        </h5>
      </div>
    </router-link>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLPillarPushPagesItem',

    props: {
      /**
       *
       **/
      item: {
        type: Object,
        required: true
      },

      /**
       * Index of item in container
       * @param {Number} - index
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      image () {
        return getImage({
          filename: this.item.image
        })
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-push-pages-item {
    .has-3-pages & {
      $n-of-pages: 3;
      width: calc((100% - ((#{$n-of-pages} - 1) * #{$base-px} * 6)) / #{$n-of-pages});

      @include breakpoint('small-') {
        width: 100%;
      }
    }

    .has-4-pages & {
      $n-of-pages: 4;
      width: calc((100% - ((#{$n-of-pages} - 1) * #{$base-px} * 3)) / #{$n-of-pages});

      @include breakpoint('medium-') {
        margin-top: $base-px * 3;

        $n-of-pages: 2;
        width: calc((100% - ((#{$n-of-pages} - 1) * #{$base-px} * 3)) / #{$n-of-pages});

        &:nth-child(-n + 2) {
          margin-top: 0;
        }
      }

      @include breakpoint('small-') {
        margin-top: $base-px * 4;

        width: 100%;

        &:nth-child(-n + 2) {
          margin-top: $base-px * 4;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    @include breakpoint('small-') {
      margin-top: $base-px * 4;

      &:nth-child(-n + 2) {
        margin-top: $base-px * 4;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .yl-pillar-push-pages-item__link {
    position: relative;

    display: block;

    @include breakpoint('small-') {
      display: flex;
      align-items: flex-start;
    }
  }

  .yl-pillar-push-pages-item__cover-container {
    position: relative;

    @include width-multi((
      xsmall: 2,
      small: 2
    ));

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 268 * 335);
    }
  }

  .yl-pillar-push-pages-item__cover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1) translateZ(0);

    width: 100%;

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-pillar-push-pages-item:hover & {
      transform: translateX(-50%) translateY(-50%)scale(1.1) translateZ(0);
    }
  }

  .yl-pillar-push-pages-item__number {
    position: absolute;
    bottom: $base-px * 3;
    left: $base-px * 3;
    transform: scale(1) translateZ(0);

    @include text-stroke(2px, "brand-green");

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-pillar-push-pages-item:hover & {
      $scale: 43 / 60;
      transform: scale(#{$scale}) translateZ(0);
    }

    @include breakpoint('small-') {
      bottom: $base-px * 1.5;
      left: $base-px * 1.5;
    }
  }

  .yl-pillar-push-pages-item__content-container {
    @include width-multi((
      xsmall: 4,
      small: 4
    ));

    @include breakpoint('small-') {
      margin-top: 0;

      padding-left: $base-px * 2;
    }
  }
</style>
