import { render, staticRenderFns } from "./YLCTA.vue?vue&type=template&id=03f70166&scoped=true&"
import script from "./YLCTA.vue?vue&type=script&lang=js&"
export * from "./YLCTA.vue?vue&type=script&lang=js&"
import style0 from "./YLCTA.vue?vue&type=style&index=0&id=03f70166&lang=scss&scoped=true&"
import style1 from "./YLCTA.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f70166",
  null
  
)

export default component.exports