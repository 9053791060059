// Repositories
import { RepositoryFactory } from '@/repositories/RepositoryFactory.js'

// Models
import LegacyModel from '@/models/LegacyModel'
import LegacyFeaturedModel from '@/models/LegacyFeaturedModel'

// Repositories
const Legacies = RepositoryFactory.get('legacies')
const LegacyFeatured = RepositoryFactory.get('legacy_featured')

export const fetchLegacies = async () => {
  try {
    const response = await Legacies.get()
    const data = response && response.hasOwnProperty('data') ? response.data : null

    if (data !== null) {
      const legacies = data.map(legacy => {
        const Legacy = new LegacyModel()
        Legacy.setProperties(legacy)

        return Legacy
      })

      return legacies
    } else {
      return null
    }
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data
    } else {
      throw e.toJSON()
    }
  }
}

export const fetchLegacyFeatured = async () => {
  try {
    const response = await LegacyFeatured.get()
    const data = response && response.hasOwnProperty('data') ? response.data : null

    if (data !== null) {
      const legacies = data.map(legacy => {
        const Legacy = new LegacyFeaturedModel()
        Legacy.setProperties(legacy)

        return Legacy
      })

      return legacies[0]
    } else {
      return null
    }
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data
    } else {
      throw e.toJSON()
    }
  }
}
