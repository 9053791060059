<template>
  <section v-click-outside="_onClickOutside"
           class="yl-selector"
           :class="elClasses"
           :style="elStyle">
    <select ref="select">
      <option :value="placeholder.value"
              v-html="placeholder.label">
      </option>

      <option v-for="(choice, choiceIndex) in choices"
              :key="`yl-selector_choice-${choiceIndex}`"
              :value="choice.value"
              v-html="choice.label">
      </option>
    </select>

    <div class="yl-selector__placeholder yl-selector__choice u-cursor-pointer"
         @click="open">
      <div class="yl-selector__placeholder__label f-body-subtitle-medium f-color-brand-white"
           v-html="selection.label">
      </div>

      <div class="yl-selector__placeholder__icons f-color-brand-white">
        <div v-if="closeIconIsVisible"
             class="yl-selector__placeholder__icons__close"
             @click="clear">
          <yl-svg-icon icon="close">
          </yl-svg-icon>
        </div>

        <div class="yl-selector__placeholder__icons__rafter">
          <yl-svg-icon icon="rafter-down"></yl-svg-icon>
        </div>
      </div>

      <div class="yl-selector__placeholder__border-bottom"></div>
    </div>

    <div ref="choices"
         class="yl-selector__choices"
         :class="choicesClasses"
         :style="choicesStyle">
      <div v-for="(choice, choiceIndex) in choices"
           :key="`yl-selector_custom-choice-${choiceIndex}`"
           class="yl-selector__choice f-body-subtitle-medium f-color-brand-white u-cursor-pointer"
           v-html="choice.label"
           @click="change(choice)">
      </div>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  export default {
    name: 'YLSelector',

    props: {
      /**
       * Object for the placeholder
       * @param {Object} - *REQUIRED* placeholder: Object for the placeholder
       * @param {label} - *REQUIRED* placeholder.label: Label used for first selection
       * @param {label} - *REQUIRED* placeholder.value: Value is used when user selects this choice
       */
      placeholder: {
        type: Object,
        required: true
      },

      /**
       * Array of choices
       * @param {Array} - *REQUIRED* choices: Array of choices
       * @param {label} - *REQUIRED* choices[{id}].label: Label used for selection
       * @param {label} - *REQUIRED* choices[{id}].value: Value is used when user selects this choice
       */
      choices: {
        type: Array,
        required: true
      }
    },

    data () {
      return {
        isOpen: false,
        selection: this.placeholder,
        choicesMaxHeight: null
      }
    },

    computed: {
      closeIconIsVisible () {
        return this.selection.value !== this.placeholder.value
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      elClasses () {
        return [
          this.isOpen ? 'is-open' : 'is-close'
        ]
      },

      elStyle () {
        return {}
      },

      choicesClasses () {
        return [
          this.choicesMaxHeight !== null ? 'has-max-height' : ''
        ]
      },

      choicesStyle () {
        return {
          'max-height': this.choicesMaxHeight !== null ? `${this.choicesMaxHeight}px` : 'none'
        }
      }
    },

    methods: {
      // Directives
      _onClickOutside (event) {
        this.isOpen = false
        this.$el.blur()
      },

      // HTML Events
      open () {
        const $choices = this.$refs['choices']
        if ($choices) {
          const rect = $choices.getBoundingClientRect()

          if (rect.top + rect.height > this.windowH) {
            this.choicesMaxHeight = this.windowH - rect.top
          } else {
            this.choicesMaxHeight = null
          }
        }

        this.isOpen = !this.isOpen
        this.$el.blur()
      },

      change (choice) {
        this.selection = choice
        this.isOpen = false
        this.$el.blur()

        this.$emit('change', this.selection)
      },

      clear () {
        this.selection = this.placeholder
        this.$el.blur()

        this.$emit('clear', this.selection)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-selector {
    position: relative;

    width: 100%;

    // Hide the original select
    select {
      display: none;
    }
  }

  .yl-selector__choice {
    padding: $base-px * 2 $base-px * 2.5;
  }

  .yl-selector__placeholder {
    position: relative;
  }

  .yl-selector__placeholder__icons {
    position: absolute;
    top: 50%;
    right: $base-px * 2.5;
    transform: translateY(-50%) translateZ(0);

    display: flex;
    align-items: center;
  }

  .yl-selector__placeholder__icons__rafter {
    position: relative;
    margin-left: $base-px;
    transform: rotate(0) translateZ(0);

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .is-open & {
      transform: rotate(-180deg) translateZ(0);
    }
  }

  .yl-selector__placeholder__border-bottom {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scaleX(1) translateZ(0);
    transform-origin: 50% 50%;

    width: 100%;
    height: 1px;

    background-color: map-get($colors-list, "brand-green");

    transition: transform 0.45s $ease-out-quint;
    will-change: transform;

    .yl-selector__placeholder:hover & {
      transform: scale(0.9) translateZ(0);
    }
  }

  .yl-selector__choices {
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateZ(0);

    width: 100%;

    opacity: 0;
    visibility: hidden;
    background-color: map-get($colors-list, "brand-black");

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    &.has-max-height {
      overflow-y: scroll;
    }

    .is-open & {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-selector__choice {
    transform: translateZ(0);

    width: 100%;

    border-bottom: 1px solid white;

    transition: color 0.45s $ease-out-quint;
    will-change: color;

    &.yl-selector__placeholder {
      border-bottom: none;
    }

    &:hover {
      color: map-get($colors-list, "brand-green") !important;
    }
  }
</style>
