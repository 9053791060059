<template>
  <div class="yl-ambassadors-statistics-item"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-ambassadors-statistics-item__container">
      <div class="yl-ambassadors-statistics-item__wrapper u-align-center">
        <h4 class="f-statistics-title f-color-brand-cream">
          <yl-text-appearance :text="statistic.title"
                              :transition-delay="(index * 15)">
          </yl-text-appearance>
        </h4>

        <p class="u-marg-t-2 f-statistics-description f-color-brand-white">
          <yl-text-appearance :text="statistic.description"
                              :transition-delay="(index * 15) + 15">
          </yl-text-appearance>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YLAmbassadorsStatisticsItem',

    props: {
      /**
       * Object w/ statistic
       * @param {Object} - *REQUIRED* statistic: Object w/ all statistic
       * @param {String} - *REQUIRED* statistic.title: Statistic's title
       * @param {String} - *REQUIRED* statistic.description: Statistic's description
       **/
      statistic: {
        type: Object,
        required: true
      },

      /**
       * Position of item in list
       * @param {Number} - index
       **/
      index: {
        type: Number,
        default: 0
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-ambassadors-statistics-item {
    position: relative;
    margin-left: $base-px * -2;

    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 4,
      large: 3,
      xlarge: 3,
    ));

    border: 1px solid map-get($colors-list, "brand-green");
    border-radius: 50%;

    &:first-child {
      margin-left: 0;
    }

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: 100%;
    }

    @include breakpoint('small-') {
      margin-top: -$base-px * 3;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .yl-ambassadors-statistics-item__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .yl-ambassadors-statistics-item__wrapper {
    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 2,
      large: 2,
      xlarge: 2,
    ));
  }
</style>
