export default class BaseModel {
  constructor () {
    this.set('_properties', [
      'id', 'date', 'date_gmt', 'slug', 'type', 'title', 'ACF'
    ])
  }

  set (property, value) {
    this[property] = value

    return this[property]
  }

  get (property) {
    return this[property]
  }

  setProperties (data = null) {
    if (data !== null) {
      const dataKeys = Object.keys(data)
      if (dataKeys && dataKeys.length > 0) {
        dataKeys.forEach(key => {
          const value = data[key]

          if (this.get('_properties').includes(key) && value) {
            this.set(key, value)
          }
        })
      }
    }
  }
}
