import { render, staticRenderFns } from "./YLLegaciesListWithSidebar.vue?vue&type=template&id=1a4f4489&scoped=true&"
import script from "./YLLegaciesListWithSidebar.vue?vue&type=script&lang=js&"
export * from "./YLLegaciesListWithSidebar.vue?vue&type=script&lang=js&"
import style0 from "./YLLegaciesListWithSidebar.vue?vue&type=style&index=0&id=1a4f4489&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a4f4489",
  null
  
)

export default component.exports