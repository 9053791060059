<template>
  <section class="yl-legacies-sidebar-categories"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-legacies-sidebar-categories__container u-align-center">
      <h4 class="f-h4 f-color-brand-white"
          v-html="content.title">
      </h4>

      <div class="yl-legacies-sidebar-categories__labels-container u-marg-t-5">
        <div v-for="(category, categoryIndex) in categories"
             :key="`yl-legacies-sidebar-categories_category-${categoryIndex}`"
             class="yl-legacies-sidebar-categories__label u-marg-t-2 f-body-subtitle-medium f-color-brand-white u-cursor-pointer"
             :class="[ activeCategory === category.slug ? 'is-active' : '' ]"
             v-html="category.name"
             @click="$emit('click', category.slug)">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  // Helpers
  import { EventBus } from '@/helpers/event-bus'

  export default {
    name: 'YLLegaciesSidebarCategories',

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* content.title: Title for the block
       **/
      content: {
        type: Object,
        required: true
      },

      /**
       * Array of categories
       * @param {Array} - *REQUIRED* content: Object w/ content
       **/
      categories: {
        type: Array,
        required: true
      },

      /**
       * Slug of active category
       * @param {String} - *REQUIRED* activeCategory: Slug of active category
       **/
      activeCategory: {
        type: String,
        default: null
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this._onResize()
      })
    },

    detroyed () {
      // Events
      EventBus.$off('resize', this._onResize)
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-legacies-sidebar-categories {
    position: sticky;
    top: 0;
    transform: translateX(-100%) translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;
    $colspan: colspan(1.5);
    width: $colspan;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: map-get($colors-list, "brand-black");
    opacity: 0;
    visibility: hidden;

    transition: transform 1s $ease-out-quint, opacity 0.85s $ease-out-quint, visibility 1s;
    will-change: transform, opacity, visibility;

    .is-visible & {
      transform: translateZ(0);

      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('medium-') {
      position: relative;
      transform: translateZ(0);

      width: 100%;
      height: auto;
      min-height: auto;

      background-color: transparent;
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-legacies-sidebar-categories__container {
    padding-right: $base-px * 5;
    padding-left: $base-px * 5;

    @include breakpoint('medium-') {
      padding: $base-px * 3 0;
    }
  }

  .yl-legacies-sidebar-categories__label {
    transform: translateZ(0);

    transition: color 0.45s $ease-out-quint;
    will-change: color;

    &:hover,
    &.is-active {
      color: map-get($colors-list, "brand-green") !important;
    }

    &:first-child {
      margin-top: 0;
    }
  }
</style>
