<template>
  <section class="yl-pillar-footer"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-footer__container">
      <yl-pillar-form :content="content['form']"></yl-pillar-form>

      <yl-pillar-push-pages :content="content['push-pages']"></yl-pillar-push-pages>
    </div>
  </section>
</template>

<script>
  // Components
  import YLPillarForm from './YLPillarForm'
  import YLPillarPushPages from './YLPillarPushPages'

  export default {
    name: 'YLPillarFooter',

    components: {
      'yl-pillar-form': YLPillarForm,
      'yl-pillar-push-pages': YLPillarPushPages
    },

    props: {
      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      },

      /**
       *
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-footer {
    display: flex;
    justify-content: center;
    padding-top: $base-px * 18;
    padding-bottom: $base-px * 18;

    background-color: rgba(map-get($colors-list, "brand-black"), 0.3);

    @include breakpoint('small-') {
      padding-top: $base-px * 10;
      padding-bottom: $base-px * 10;
    }
  }

  .yl-pillar-footer__container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 9,
      xlarge: 9,
    ));
    padding-left: $base-px * 10;

    @include breakpoint('medium-') {
      padding-left: 0;
    }
  }
</style>
