<template>
  <component :is="tag"
             class="yl-text-appearance"
             :class="elClasses"
             :style="elStyle"
             v-observe-visibility="visibilityOpts"
             v-html="text">
  </component>
</template>

<script>
  // Mixins
  import visibilityMixin from '@/mixins/visibility'

  export default {
    name: 'YLTextAppearance',

    mixins: [visibilityMixin],

    props: {
      /**
       * Component used
       * @param {String} - tag
       **/
      tag: {
        type: String,
        default: 'div'
      },

      /**
       * [String, Number] w/ content
       * @param {[String, Number]} - *REQUIRED* text
       **/
      text: {
        type: [String, Number],
        required: true
      },

      /**
       * If component has transition
       * @param {Boolean} - transition
       **/
      transition: {
        type: Boolean,
        default: true
      },

      /**
       * Number in ms for transition-duration
       * @param {Number} - transitionDuration
       **/
      transitionDuration: {
        type: Number,
        default: 800
      },

      /**
       * Number in ms for transition-delay
       * @param {Number} - transitionDelay
       **/
      transitionDelay: {
        type: Number,
        default: 15
      },

      /**
       * rootMargin
       * @param {String} - rootMargin
       **/
      rootMargin: {
        type: String,
        default: '-50px'
      },

      /**
       * threshold
       * @param {[Number, Array]} - threshold
       **/
      threshold: {
        type: [Number, Array],
        default: 0
      }
    },

    data () {
      return {
        opacity: this.transition ? 0 : 1,
        isAnimating: false,
        isReveal: false,
        vRootMargin: this.rootMargin,
        vThresold: this.threshold,
        reveal: false
      }
    },

    computed: {
      elClasses () {
        return {
          'is-animating': this.isAnimating,
          'is-reveal': this.isReveal
        }
      },

      elStyle () {
        return {
          opacity: this.opacity,
          transitionDuration: `${this.transitionDuration}ms`
        }
      }
    },

    mounted () {
      this.$el.style.setProperty('--transition-delay', `${this.transitionDelay}ms`)
    },

    methods: {
      isVisibleCallback () {
        this.reveal = true

        this.$nextTick(() => {
          this.revealText()
        })
      },

      revealText () {
        window.setTimeout(() => {
          window.requestAnimationFrame(() => {
            this.isAnimating = true
            this.opacity = 1
            this.isReveal = true
            this.$emit('text-will-reveal')
            window.setTimeout(() => {
              this.$emit('text-reveal')
            }, this.transitionDuration + 50)
          })
        }, 50) // 3 frames in theory if user run at 60 fps
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-text-appearance {
    position: relative;
    transform: translateY($base-px * 5) translateZ(0);

    display: inline-block;

    opacity: 0;

    transition-property: transform, opacity;
    transition-delay: var(--transition-delay, 15ms);
    will-change: transform, opacity;

    &.is-reveal {
      transform: translateZ(0);

      opacity: 1;
    }
  }
</style>
