import Vue from 'vue'
import Router from 'vue-router'
import VueHead from 'vue-head'

// Pages
import PHome from '@/views/PHome'
// Primary pages
import PJuniorDevelopment from '@/views/PJuniorDevelopment'
import PCommunityEnrichment from '@/views/PCommunityEnrichment'
import PParticipationDiscovery from '@/views/PParticipationDiscovery'
import PBadmintonAdvancement from '@/views/PBadmintonAdvancement'
// Secondary pages
import PLegends from '@/views/PLegends'
import PAmbassadors from '@/views/PAmbassadors'
import PLegacies from '@/views/PLegacies'
import PEvents from '@/views/PEvents'
import PAbout from '@/views/PAbout'

// Middlewares
import { getAllContent } from '@/middlewares/content'

Vue.use(Router)
Vue.use(VueHead, {
  separator: '|',
  complement: process.env.VUE_APP_SITE_NAME
})

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: PHome,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: false,
        hasFooterOnMobile: false,
        hasScroll: false,
        hasScrollOnMobile: true
      }
    },
    // Primary pages
    {
      path: '/junior-development',
      name: 'junior-development',
      component: PJuniorDevelopment,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    }, {
      path: '/community-enrichment',
      name: 'community-enrichment',
      component: PCommunityEnrichment,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    }, {
      path: '/participation-discovery',
      name: 'participation-discovery',
      component: PParticipationDiscovery,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    }, {
      path: '/badminton-advancement',
      name: 'badminton-advancement',
      component: PBadmintonAdvancement,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    },
    // Secondary pages
    {
      path: '/legends',
      name: 'legends',
      component: PLegends,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: false,
        hasFooterOnMobile: true,
        hasScroll: false,
        hasScrollOnMobile: true
      }
    }, {
      path: '/ambassadors',
      name: 'ambassadors',
      component: PAmbassadors,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    }, {
      path: '/legacies',
      name: 'legacies',
      component: PLegacies,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    }, {
      path: '/events',
      name: 'events',
      component: PEvents,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    }, {
      path: '/about',
      name: 'about',
      component: PAbout,
      beforeEnter: getAllContent,
      meta: {
        hasFooter: true,
        hasFooterOnMobile: true,
        hasScroll: true,
        hasScrollOnMobile: true
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  /**
   * Router Scroll behavior
   * @see: https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
   */
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
