import Repository from './Repository'

import { randomHex } from '@/helpers/random'

const ressourceName = 'legacies'
const salt = randomHex(8)
const resource = `wp-json/wp/v2/${ressourceName}?filter[orderby]=date&order=asc&per_page=100&salt=${salt}`

export default {
  get () {
    return Repository.get(`${resource}`)
  },

  getOne (id) {
    return Repository.get(`${resource}/${id}`)
  }
}
