<template>
  <section class="yl-pillar-container u-marg-x-auto"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-pillar-container__wrapper u-marg-x-auto">
      <slot></slot>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  export default {
    name: 'YLPillarContainer',

    props: {
      /**
       *
       **/
      index: {
        type: Number,
        default: 0
      },

      /**
       *
       **/
      name: {
        type: String,
        required: true
      }
    },

    computed: {
      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      pageFullHeight () {
        return this.$store.state[S.pageFullHeight]
      },

      elClasses () {
        return [
          `is-container-for-${this.name}`,
          `is-container-for-index-${this.index}`
        ]
      },

      elStyle () {
        return {
          'padding-top': `${this.headerHeight + 60 + 95}px` /* 60: header's margin-top, 95: container's margin-top */
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-pillar-container {
    position: relative;

    display: flex;
    align-items: flex-start;
    @include width-multi((
      large: 12,
      xlarge: 12,
    ));

    @include breakpoint('medium-') {
      padding-top: 0 !important;

      width: 100%;
    }
  }

  .yl-pillar-container__hidden-col {
    @include width-multi((
      xsmall: 2,
      small: 2,
      medium: 4,
      large: 2,
      xlarge: 2
    ));
  }

  .yl-pillar-container__wrapper {
    @include width-multi((
      large: 10,
      xlarge: 10,
    ));
    padding-left: $base-px * 10;

    @include breakpoint('medium-') {
      width: 100%;
      padding-left: 0;
    }
  }
</style>
