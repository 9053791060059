<template>
  <section class="yl-page-transition"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-page-transition__container">
      <yl-image-lazy-load class="u-opacity-20"
                          :sources="image.bind" />
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLPageTransition',

    computed: {
      isTransitioning () {
        return this.$store.state[S.isTransitioning]
      },

      image () {
        return getImage({
          filename: 'yonex-legends_transition_logotype.png'
        })
      },

      elClasses () {
        return [
          `${this.isTransitioning}`
        ]
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-page-transition {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transform: translateY(-100%) skewY(-5deg) translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;

    background-color: map-get($colors-list, "brand-black");
    opacity: 0;
    visibility: hidden;

    will-change: transform, opacity, visibility;

    &.is-up {
      transform: translateY(-115%) skewY(-5deg) translateZ(0);
    }

    &.is-in {
      transform: translateY(0) skewY(0) translateZ(0);

      opacity: 1;
      visibility: visible;

      transition: transform 1s $ease-out-quint;
    }

    &.is-down {
      transform: translateY(115%) skewY(5deg) translateZ(0);

      opacity: 1;
      visibility: visible;

      transition: transform 1s $ease-out-quint;
    }
  }

  .yl-page-transition__container {
    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 2,
      large: 2,
      xlarge: 2
    ));
  }
</style>
