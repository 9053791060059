<template>
  <section class="yl-home-landing"
           :class="elClasses">
    <div class="yl-home-landing__illustrations-container">
      <div v-for="(illustration, illustrationIndex) in illustrations"
           :key="`yl-home-landing_illustration-${illustrationIndex}`"
           class="yl-home-landing__illustration-wrapper"
           :class="[ illustration.sprite ? 'is-sprite' : '' ]">
        <yl-parallax-item :value="scroll"
                          :ratio="illustration.ratio">
          <img class="yl-home-landing__illustration"
               v-bind="illustration.image.bind" />
        </yl-parallax-item>
      </div>
    </div>

    <div class="yl-home-landing__container">
      <div class="yl-home-landing__wrapper">
        <h1 class="f-h1 f-color-white">
          <span v-for="(item, itemIndex) in editableContent.title"
                :key="`yl-home-landing_title-${itemIndex}`"
                class="">
            <span v-if="itemIndex === 1"
                  class="yl-home-landing__title__word-hover"
                  ref="word-hover"
                  v-html="item"
                  @mouseenter="_onWordMouseenter"
                  @mousemove="_onWordMousemove"
                  @mouseleave="_onWordMouseleave"
                  @touchstart="_onWordTouchstart"
                  @touchmove="_onWordTouchmove"
                  @touchend="_onWordTouchend">
            </span>

            <span v-else v-html="item"></span>
          </span>
        </h1>
      </div>
    </div>

    <div class="yl-home-landing__legends-images-container">
      <div v-for="(image, imageIndex) in imagesOfLegends"
           :key="`yl-home-landing_legend-image-${imageIndex}`"
           class="yl-home-landing__legend-image js-legend-image">
        <img class="yl-home-landing__legend-image__element"
             v-bind="image.bind" />
      </div>
    </div>

    <div v-if="isTabletDevice"
         class="yl-home-landing__fixed__item f-home-decoration f-color-brand-white">
      <div v-html="'IMPACT........... :22,122ATT'"></div>
      <div v-html="'.......... .. .... .'"></div>
    </div>

    <div v-if="isTabletDevice"
         ref="button" class="yl-home-landing__fixed__button-container">
      <yl-round-button size="100px"
                        icon="full-arrow--bottom"
                        @click="scrolling">
      </yl-round-button>
    </div>
  </section>
</template>

<script>
  // Mixins
  import ImagesMixin from '@/mixins/images'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'
  import YLParallaxItem from '@/components/parallax/YLParallaxItem'

  // Helpers
  import { getImage } from '@/helpers/assets'
  import { EventBus } from '@/helpers/event-bus'
  import { scrollTo } from '@/helpers/scroll'

  // Store
  import { STATE as S } from '@/store/helpers'

  // Behavior
  import RAF from '@/behavior/RAF'

  export default {
    name: 'YLHomeLanding',

    mixins: [ImagesMixin],

    components: {
      'yl-round-button': YLRoundButton,
      'yl-parallax-item': YLParallaxItem
    },

    props: {
      /**
       *
       **/
      content: {
        type: Object,
        default: () => {}
      },

      /**
       *
       **/
      editableContent: {
        type: Object,
        default: () => {}
      },

      /**
       *
       **/
      YLHomePillarPanelsPositionX: {
        type: Number,
        default: 0
      }
    },

    data () {
      return {
        wordIsHovered: false,
        wordHoverIndex: 0,
        scroll: 0
      }
    },

    computed: {
      isTabletDevice () {
        return this.$store.state[S.isTabletDevice]
      },

      isTouchDevice () {
        return this.$store.state[S.isTouchDevice]
      },

      windowW () {
        return this.$store.state[S.windowW]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      elClasses () {
        return [

        ]
      },

      illustrations () {
        const illustrations = []

        this.editableContent.illustrations.forEach(image => {
          illustrations.push({
            image: getImage({
              filename: `home/yonex-legends_home_parallax-object-${image.n}${image.sprite ? '_sprite' : ''}.png`
            }),
            sprite: image.sprite,
            ratio: image.ratio
          })
        })

        return illustrations
      },

      imagesOfLegends () {
        const images = []

        if (this.editableContent['number-of-legends']) {
          for (let i = 0; i < this.editableContent['number-of-legends']; i += 1) {
            images.push(getImage({
              filename: `home/yonex-legends_home_legends-0${i}.jpg`
            }))
          }
        }

        return images
      }
    },

    created () {
      // Variables
      this.wordHoverRect = null
      this.mouse = {
        x: 0,
        y: 0,
        start: {
          x: 0,
          y: 0
        },
        xEased: 0,
        yEased: 0
      }

      // Events
      EventBus.$on('resize', this._onResize)
      EventBus.$on('YLScrollHContainer:raf', this._onScroll)
    },

    mounted () {
      this.$nextTick(() => {
        // DOM Elements
        this.legendsImages = []
        this.$legendsImages = this.$el.querySelectorAll('.js-legend-image')

        this.startRAF()

        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)
      EventBus.$off('YLScrollHContainer:raf', this._onScroll)

      this.stopRAF()
    },

    methods: {
      // Events
      _onResize () {
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)

        this.$nextTick(() => {
          const $ref = this.$refs['word-hover'][0]
          if ($ref) {
            this.wordHoverRect = $ref.getBoundingClientRect()

            if (this.$legendsImages && this.$legendsImages.length > 0) {
              this.legendsImages = []
              this.legendsImages.length = 0

              this.$legendsImages.forEach($image => {
                this.legendsImages.push({
                  $el: $image,
                  rect: $image.getBoundingClientRect(),
                  x: 0,
                  y: 0
                })
              })
            }
          }
        })
      },

      scrolling () {
        scrollTo(this.windowH)
      },

      _onScroll (scroll) {
        this.scroll = scroll.scrollEased
      },

      // Mouse's events
      _onWordMouseenter (event) {
        this.wordIsHovered = true

        this.mouse.x = event.clientX
        this.mouse.y = event.clientY

        EventBus.$emit('YLHomeLanding:hover', true)
      },

      _onWordMousemove (event) {
        if (!this.wordIsHovered) return

        this.mouse.x = event.clientX
        this.mouse.y = event.clientY
      },

      _onWordMouseleave (event) {
        if (!this.wordIsHovered) return

        this.wordIsHovered = false

        this.mouse.x = event.clientX
        this.mouse.y = event.clientY

        EventBus.$emit('YLHomeLanding:hover', false)
      },

      // Touch events
      _onWordTouchstart (event) {
        this.wordIsHovered = true

        this.mouse.start.x = event.touches[0].clientX
        this.mouse.x = event.touches[0].clientX
        this.mouse.y = event.touches[0].clientY

        EventBus.$emit('YLHomeLanding:hover', true)
      },

      _onWordTouchmove (event) {
        if (!this.wordIsHovered) return

        this.mouse.x = event.touches[0].clientX
        this.mouse.y = event.touches[0].clientY
      },

      _onWordTouchend (event) {
        if (!this.wordIsHovered) return

        this.wordIsHovered = false

        this.mouse.start.x = 0
        this.mouse.x = event.changedTouches[0].clientX
        this.mouse.y = event.changedTouches[0].clientY

        EventBus.$emit('YLHomeLanding:hover', false)
      },

      _getImagesPosition () {
        if (!this.wordHoverRect || !this.mouse) return

        if (this.legendsImages && this.legendsImages.length > 0) {
          this.legendsImages.forEach((item, itemIndex) => {
            if (itemIndex === this.wordHoverIndex) {
              item.$el.classList.add('is-visible')
              item.$el.classList.remove('is-hidden')
            } else {
              item.$el.classList.remove('is-visible')
              item.$el.classList.add('is-hidden')
            }

            if (!this.isTouchDevice) {
              item.x = this.mouse.xEased
              item.y = this.mouse.yEased - (item.rect.height / 2)

              item.$el.style.transform = `translateX(${item.x}px) translateY(${item.y}px) translateZ(0)`
            } else {
              item.x = this.mouse.xEased - this.mouse.start.x

              item.$el.style.transform = `translateX(-50%) translateX(${item.x}px) translateY(-50%) translateZ(0)`
            }
          })
        }
      },

      // RAF
      rafCallback () {
        const ratioX = (this.mouse.x - this.wordHoverRect.x) / this.wordHoverRect.width

        if (this.editableContent['number-of-legends']) {
          for (let i = 0; i < this.editableContent['number-of-legends']; i += 1) {
            const step = 1 / this.editableContent['number-of-legends']

            if (ratioX > step * i) {
              this.wordHoverIndex = i
            }
          }
        }

        if (this.isTabletDevice) {
          const $ref = this.$refs['button']

          if ($ref) {
            if (this.scrollTop >= this.windowH / 100 * 25) {
              if (!$ref.className.includes('is-hidden')) {
                $ref.classList.add('is-hidden')
              }
            } else {
              if ($ref.className.includes('is-hidden')) {
                $ref.classList.remove('is-hidden')
              }
            }
          }
        }

        this.mouse.xEased += (this.mouse.x - this.mouse.xEased) * 0.1
        this.mouse.yEased += (this.mouse.y - this.mouse.yEased) * 0.1

        this._getImagesPosition()
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-home-landing {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100%;

    @include breakpoint('medium-') {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);

      overflow: hidden;
    }
  }

  .yl-home-landing__illustrations-container {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    width: 100%;
    height: 100%;

    opacity: 1;
    visibility: visible;

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: opacity, visibility;

    .has-home-landing-word-hover & {
      opacity: 0;
      visibility: hidden;
    }
  }

  .yl-home-landing__illustration-wrapper {
    position: absolute;

    &:nth-child(1) {
      top: 50%;
      left: 0;
      transform: translateX(-25%) translateY(-50%) translateZ(0);

      width: 40vw;

      opacity: 0.4;

      @include breakpoint('medium-') {
        top: 0;
        transform: translateX(-30%) translateY(25%) translateZ(0);

        width: 60vw;
      }

      @include breakpoint('small-') {
        top: 0;
        transform: translateX(-30%) translateY(25%) translateZ(0);

        width: 90vw;
      }
    }

    &:nth-child(2) {
      bottom: 0;
      left: 50%;
      transform: translateX(-100%) translateY(25%) translateZ(0);

      width: 27vw;

      opacity: 0.8;

      @include breakpoint('medium-') {
        bottom: 0;
        transform: translateX(-35%) translateY(-15%) translateZ(0);

        width: 42vw;
      }

      @include breakpoint('small-') {
        bottom: 0;
        transform: translateX(-35%) translateY(-15%) translateZ(0);

        width: 63vw;
      }
    }

    &:nth-child(3) {
      top: 50%;
      left: 50%;
      transform: translateX(100%) translateY(-100%) translateZ(0);

      width: 10vw;

      @include breakpoint('medium-') {
        display: none;
      }
    }

    &:nth-child(4) {
      top: 50%;
      left: 50%;
      transform: translateX(75%) translateY(-25%) translateZ(0);

      width: 20vw;

      &.is-sprite {
        &:before {
          content: "";

          position: relative;

          display: block;
          width: 100%;
          padding-top: calc(100% / (26544 / 48) * 609);
        }
      }

      @include breakpoint('medium-') {
        display: none;
      }
    }
  }

  .yl-home-landing__illustration {
    position: relative;
    transform: translateX(0) translateZ(0);

    .is-sprite & {
      opacity: 0.3;
    }

    .is-sprite:nth-child(4) & {
      $n: 48;
      position: absolute;
      top: 0;
      left: 0;

      width: (100% * $n) !important;
      max-width: none !important;

      $duration: ($n / 24) + 's';
      animation: sprite-h #{$duration} steps(#{$n}) infinite;
      animation-direction: alternate;
    }
  }

  .yl-home-landing__container {
    position: relative;
    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));
    height: 100%;
  }

  .yl-home-landing__wrapper {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));

    @include breakpoint('medium-') {
      position: relative;
      transform: translatey(-25%) translateZ(0);
    }
  }

  .yl-home-landing__title__word-hover {
    position: relative;

    cursor: pointer;

    &:after {
      content: "";

      position: absolute;
      bottom: $base-px * 3; /* HARDCODING */
      left: 0;
      z-index: -1;

      width: 100%;
      height: 1px;

      background-color: map-get($colors-list, "brand-green");

      @include breakpoint('large-') {
        bottom: $base-px * 2;
      }

      @include breakpoint('small-') {
        bottom: $base-px;
      }
    }
  }
  .yl-home-landing__legends-images-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 2;

    width: 100%;
    height: 100%;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.65s $ease-out-quint, visibility 0.65s;
    will-change: opacity, visibility;

    .has-home-landing-word-hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-home-landing__legend-image {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateZ(0);

    width: 506px;
    max-width: 100%;

    opacity: 0;
    visibility: hidden;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    &:before {
      content: "";

      position: relative;

      display: block;
      width: 100%;
      padding-top: calc(100% / 506 * 632);
    }

    .is-touch & {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) translateZ(0);

      width: 90%;
    }
  }

  .yl-home-landing__legend-image__element {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);
  }

  .yl-home-landing__fixed__item {
    position: absolute;
    bottom: $base-px * 3;
    left: $base-px * 3;
    transform: rotate(-90deg) translateZ(0);
    transform-origin: 0 0;

    display: inline-block;
  }

  .yl-home-landing__fixed__button-container {
    position: absolute;
    right: $base-px * 3;
    bottom: $base-px * 3;
    transform: translateZ(0);
    z-index: 4;

    opacity: 1;
    visibility: visible;

    transition: opacity 0.55s $ease-out-quint, visibility 0.55s;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;;
    }
  }
</style>
