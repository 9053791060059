<template>
  <section class="yl-about-header"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-about-header__container">
      <div class="yl-about-header__images-container u-marg-x-auto u-align-center">
        <yl-image-lazy-load klass="yl-about-header__legends"
                            :sources="images.legends.bind" />

        <yl-image-lazy-load klass="yl-about-header__logo u-marg-x-auto"
                            :sources="images.logo.bind" />
      </div>

      <div class="yl-about-header__subtitle u-marg-t-4 u-marg-x-auto">
        <yl-appearance>
          <h3 class="f-body-big f-color-brand-brown u-align-center"
              v-html="header.text">
          </h3>
        </yl-appearance>
      </div>
    </div>

    <div v-if="illustrations[0]"
         ref="illustration-00"
         class="yl-about-header__illustration-00-container"
         :style="illustrations[0].style">
      <yl-image-lazy-load :sources="illustrations[0].image.bind" />
    </div>

    <div v-if="illustrations[1]"
         ref="illustration-01"
         class="yl-about-header__illustration-01-container"
         :style="illustrations[1].style">
      <yl-image-lazy-load :sources="illustrations[1].image.bind" />
    </div>

    <div ref="button"
         class="yl-about-header__button-container">
      <yl-round-button size="100px"
                       icon="full-arrow--bottom"
                       @click="scroll">
      </yl-round-button>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S } from '@/store/helpers'

  // Behaviors
  import RAF from '@/behavior/RAF'

  // Helpers
  import { EventBus } from '@/helpers/event-bus'
  import { getImage } from '@/helpers/assets'
  import { scrollTo } from '@/helpers/scroll'

  // Components
  import YLRoundButton from '@/components/elements/YLRoundButton'

  export default {
    name: 'YLAboutHeader',

    components: {
      'yl-round-button': YLRoundButton
    },

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* header: Object w/ content
       * @param {String} - *REQUIRED* header.title: Title used for header
       * @param {String} - *REQUIRED* header.text: Text used for header
       **/
      header: {
        type: Object,
        required: true
      }
    },

    computed: {
      headerHeight () {
        return this.$store.state[S.headerHeight]
      },

      images () {
        return {
          legends: getImage({
            filename: 'about/yonex-legends_about_header_legends.png'
          }),
          logo: getImage({
            filename: 'about/yonex-legends_about_header_logo.png'
          })
        }
      },

      illustrations () {
        const illustrationsNames = ['about/yonex-legends_about_header_illustration-00.png', 'about/yonex-legends_about_header_illustration-01.png']
        const illustrations = []

        illustrationsNames.forEach((filename, index) => {
          const illustration = {
            image: getImage({ filename })
          }

          illustrations.push(illustration)
        })

        return illustrations
      },

      scrollTop () {
        return this.$store.state[S.scrollTop]
      },

      windowH () {
        return this.$store.state[S.windowH]
      },

      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    },

    created () {
      // Events
      EventBus.$on('resize', this._onResize)
    },

    mounted () {
      this.$nextTick(() => {
        this.startRAF()

        this._onResize()
      })
    },

    beforeDestroy () {
      // Events
      EventBus.$off('resize', this._onResize)

      this.stopRAF()
    },

    methods: {
      // Events
      _onResize () {
        const vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        this.$el.style.setProperty('--vh', `${vh}px`)
      },

      // HTML events
      scroll () {
        scrollTo(this.windowH)
      },

      // RAF
      rafCallback () {
        const $ref = this.$refs['button']
        if (!$ref) return

        if (this.scrollTop > (this.windowH / 100 * 25)) {
          if ($ref.className.indexOf('is-hidden') === -1) {
            $ref.classList.add('is-hidden')
          }
        } else {
          if ($ref.className.indexOf('is-hidden') > -1) {
            $ref.classList.remove('is-hidden')
          }
        }
      },

      startRAF () {
        if (this.rafID) return

        this.$nextTick(() => {
          this.rafID = RAF.add(this.rafCallback)
        })
      },

      stopRAF () {
        if (this.rafID) this.rafID = RAF.remove(this.rafID)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-about-header {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    overflow: hidden;

    @include breakpoint('small-') {
      height: auto;
      min-height: auto;
      padding-top: $base-px * 10;
      padding-bottom: $base-px * 3;
    }
  }

  .yl-about-header__container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));
  }

  .yl-about-header__images-container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 8,
      large: 8,
      xlarge: 8
    ));
  }

  .yl-about-header__legends {
    width: 100%;
  }

  .yl-about-header__logo {
    margin-top: -$base-px * 10;

    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 4,
      large: 4,
      xlarge: 4
    ));

    @include breakpoint('small-') {
      margin-top: -$base-px * 5;
    }
  }

  .yl-about-header__subtitle {
    @include width-multi((
      xsmall: 5,
      small: 5,
      medium: 8,
      large: 8,
      xlarge: 8,
    ));
  }

  .yl-about-header__illustration-00-container {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-25%) translateY(-50%) translateZ(0);

    width: 20%;
    @include width-multi((
      xsmall: 2,
      small: 2
    ));

    opacity: 0.7;
  }

  .yl-about-header__illustration-01-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(10%) translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 3,
      small: 3,
      medium: 3,
      large: 3,
      xlarge: 3
    ));

    opacity: 0.6;

    @include breakpoint('small-') {
      transform: translateX(50%) translateY(-50%) translateZ(0);
    }
  }

  .yl-about-header__button-container {
    position: fixed;
    right: 64px;
    bottom: 60px;
    transform: translateZ(0);

    opacity: 1;
    visibility: visible;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    &.is-hidden {
      opacity: 0;
      visibility: hidden;
    }

    @include breakpoint('small-') {
      display: none;
    }
  }
</style>
