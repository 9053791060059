<template>
  <component :is="hasLink ? 'a' : 'section'"
             :href="event.ACF.link"
             target="_blank"
             rel="noopener"
             class="yl-events-list-item u-marg-t-3 u-marg-l-3"
             :class="elClasses"
             :style="elStyle">
    <div class="yl-events-list-item__overlay">
      <p class="yl-events-list-item__overlay__text f-body-subtitle-medium f-color-brand-white u-align-center"
         v-html="hasLink ? event.ACF.hover_text || content.hoverLinkLabel : event.ACF.hover_text || content.hoverNoLinkLabel">
      </p>
    </div>

    <header class="yl-events-list-item__header">
      <p class="yl-events-list-item__category-name f-event-h2 f-color-brand-green"
         v-html="event.ACF.category.name">
      </p>

      <p class="yl-events-list-item__location f-event-title f-color-brand-white"
         v-html="event.ACF.location">
      </p>

      <p v-if="event.ACF.venue"
         class="f-body-smaller f-color-brand-cream"
         v-html="event.ACF.venue">
      </p>

      <div class="yl-events-list-item__dates f-body-smaller f-color-brand-cream">
        <div class="u-align-right">
          <p class=""
             v-html="event.ACF.start_date_formatted">
          </p>

          <p v-if="event.ACF.end_date_formatted"
             class=""
             v-html="`- ${event.ACF.end_date_formatted}`">
          </p>
        </div>
      </div>
    </header>

    <div class="yl-events-list-item__informations">
      <div class="yl-events-list-item__icon-container u-align-center">
        <img v-bind="icon.bind" />
      </div>

      <h4 class="yl-events-list-item__informations__title u-marg-t-3 f-event-title f-color-brand-white u-align-center"
          v-html="event.ACF.name">
      </h4>
    </div>

    <div ref="canvas-container"
         class="yl-events-list-item__triangles-container">
      <canvas ref="canvas"
              class="yl-events-list-item__canvas">
      </canvas>

      <div class="yl-events-list-item__triangles-container__dashed"></div>
    </div>

    <footer class="yl-events-list-item__footer">
      <p class="f-home-pillar-card-footer f-color-brand-cream f-tf-uppercase"
         v-html="content.barcodeLabel">
      </p>

      <img class="u-marg-t-1"
           v-bind="barcode.bind" />
    </footer>
  </component>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLEventsListItem',

    props: {
      /**
       * Object w/ content of an event from API
       * @param {Object} - *REQUIRED* event
       * @param {Object} - *REQUIRED* event.ACF: Object from API
       **/
      event: {
        type: Object,
        required: true
      },

      /**
       * Object w/ editable content
       * @param {Object} - *REQUIRED* content
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      hasLink () {
        return this.event.ACF.link
      },

      icon () {
        const modulo = this.event.ACF.category.slug === 'world-tour' ? 2 : 3
        const filename = `events/yonex-legends_events_event-${this.event.ACF.category.slug}_icon-0${this.event.idInCategory % modulo}.png`

        return getImage({
          filename
        })
      },

      barcode () {
        const color = this.event.ACF.category.slug === 'world-tour' ? 'brand-cream' : 'brand-green'
        const filename = `events/yonex-legends_events_barcode-h--${color}.png`

        return getImage({
          filename
        })
      },

      elClasses () {
        return [
          `has-${this.event.ACF.category.slug}-category`,
          this.hasLink ? 'has-link' : ''
        ]
      },

      elStyle () {
        return {}
      }
    },

    mounted () {
      this._initCanvas()
    },

    updated () {
      this._initCanvas()
    },

    methods: {
      // Canvas
      _initCanvas () {
        const triangleSize = 10

        this.canvas = this.$refs['canvas']
        this.context = this.canvas.getContext('2d')

        this.canvas.width = this.$refs['canvas-container'].offsetWidth
        this.canvas.height = this.$refs['canvas-container'].offsetHeight

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

        this.context.beginPath()
        this.context.moveTo(0, 0)
        this.context.lineTo(triangleSize / 3 * 2, triangleSize)
        this.context.lineTo(0, triangleSize * 2)

        // the outline
        this.context.lineWidth = 2
        this.context.strokeStyle = this.event.ACF.category.slug === 'world-tour' ? 'rgba(209, 191, 159, 0.7)' : 'rgba(233, 243, 237, 0.3)'
        this.context.stroke()

        this.context.beginPath()
        this.context.moveTo(this.canvas.width, 0)
        this.context.lineTo(this.canvas.width - triangleSize / 3 * 2, triangleSize)
        this.context.lineTo(this.canvas.width, triangleSize * 2)

        // the outline
        this.context.lineWidth = 2
        this.context.strokeStyle = this.event.ACF.category.slug === 'world-tour' ? 'rgba(209, 191, 159, 0.7)' : 'rgba(233, 243, 237, 0.3)'
        this.context.stroke()
      }
    }
  }
</script>

<style lang="scss" scoped>
  $n-items-row: 3;

  .yl-events-list-item {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc((100% - ((#{$n-items-row} - 1) * #{$base-px} * 3)) / #{$n-items-row});

    &.has-link {
      cursor: pointer;
    }

    &:nth-child(-n + 3) {
      margin-top: 0;
    }

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    @include breakpoint('medium-') {
      margin-left: $base-px / 2;

      $n-items-row: 2;
      width: calc((100% - ((#{$n-items-row} - 1) * #{$base-px} / 2)) / #{$n-items-row});

      &:nth-child(odd) {
        margin-left: 0;
      }

      &:nth-child(even) {
        margin-left: $base-px / 2;
      }

      &:nth-child(-n + 3) {
        margin-top: $base-px * 3;
      }

      &:nth-child(-n + 2) {
        margin-top: 0;
      }
    }
  }

  .yl-events-list-item__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateZ(0);
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.45s $ease-out-quint, visibility 0.45s;
    will-change: opacity, visibility;

    .has-legacy-tour-category & {
      background-color: rgba(map-get($colors-list, "brand-green"), 0.85);
      border: 1px solid rgba(white, 0.25);
    }

    .has-world-tour-category & {
      background-color: rgba(map-get($colors-list, "brand-cream"), 0.95);
      border: 1px solid rgba(white, 0.25);
    }

    .yl-events-list-item:not(.has-link) & {
      background-color: rgba(map-get($colors-list, "brand-black"), 0.85);
      border: 1px solid rgba(white, 0.25);
    }

    .yl-events-list-item:hover & {
      opacity: 1;
      visibility: visible;
    }
  }

  .yl-events-list-item__overlay__text {
    position: relative;
    transform: translateY(100%) translateZ(0);

    max-width: 70%;

    opacity: 0;
    visibility: hidden;

    transition: transform 0.45s $ease-out-quint, opacity 0.45s $ease-out-quint, visibility 0.45s;
    transition-delay: 0;
    will-change: transform, opacity, visibility;

    .yl-events-list-item:hover & {
      transform: translateY(0) translateZ(0);

      opacity: 1;
      visibility: visible;

      transition-delay: 0.2s;
    }
  }

  .yl-events-list-item__header {
    padding: $base-px * 2;

    .has-legacy-tour-category & {
      border-top: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
      border-right: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
      border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
    }

    .has-world-tour-category & {
      border-top: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
      border-right: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
      border-left: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
    }

    @include breakpoint('small-') {
      padding: $base-px * 1.5;
    }
  }

  .yl-events-list-item__category-name {
    .has-world-tour-category & {
      color: map-get($colors-list, "brand-cream");
    }
  }

  .yl-events-list-item__location {
    @include breakpoint('medium-') {
      margin-top: $base-px / 2;
    }
  }

  .yl-events-list-item__dates {
    display: flex;
    justify-content: flex-end;

    @include breakpoint('small-') {
      margin-top: $base-px;
    }
  }

  .yl-events-list-item__informations {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: $base-px * 3;

    .has-legacy-tour-category & {
      border-top: 1px dashed rgba(map-get($colors-list, "brand-white"), 0.3);
      border-right: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
      border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
    }

    .has-world-tour-category & {
      border-top: 1px dashed rgba(map-get($colors-list, "brand-cream"), 0.7);
      border-right: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
      border-left: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
    }

    @include breakpoint('medium-') {
      padding: $base-px * 6;
    }

    @include breakpoint('small-') {
      padding: $base-px * 1.5;
    }
  }

  .yl-events-list-item__icon-container {
    display: flex;
    align-items: center;

    height: 100%;

    .has-legacy-tour-category & {
      padding-right: $base-px * 2;
      padding-left: $base-px * 2;
    }
  }

  .yl-events-list-item__informations__title {
    @include breakpoint('small-') {
      margin-top: $base-px * 1.5;
    }
  }

  .yl-events-list-item__triangles-container {
    position: relative;

    height: $base-px * 2;
  }

  .yl-events-list-item__triangles-container__dashed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    width: calc(100% - ((2 * 2) * #{$base-px}));
    // height: 1px;

    .has-legacy-tour-category & {
      border-top: 1px dashed rgba(map-get($colors-list, "brand-white"), 0.3);
    }

    .has-world-tour-category & {
      border-top: 1px dashed rgba(map-get($colors-list, "brand-cream"), 0.7);
    }
  }

  .yl-events-list-item__canvas {
    position: relative;

    width: 100%;
    height: 100%;
  }

  .yl-events-list-item__footer {
    padding: $base-px * 2.5;

    .has-legacy-tour-category & {
      border-right: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
      border-bottom: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
      border-left: 1px solid rgba(map-get($colors-list, "brand-white"), 0.3);
    }

    .has-world-tour-category & {
      border-right: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
      border-bottom: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
      border-left: 1px solid rgba(map-get($colors-list, "brand-cream"), 0.7);
    }

    @include breakpoint('small-') {
      padding: $base-px * 1.25;
    }
  }
</style>
