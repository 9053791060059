<template>
  <section class="yl-about-quote"
           :class="elClasses"
           :style="elStyle">
    <div class="yl-about-quote__container u-marg-x-auto">
      <div class="yl-about-quote__wrapper">
        <div v-if="image"
             class="yl-about-quote__image-container">
          <yl-image-lazy-load bcg-color="rgba(2, 2, 3, 0.5)"
                              :sources="image.bind" />
        </div>

        <div class="yl-about-quote__quote">
          <blockquote class="f-quote f-color-brand-white u-align-center">
            <yl-appearance>
              <span class="f-color-brand-green">“</span>&nbsp;<span v-html="content.quote"></span>&nbsp;<span class="f-color-brand-green">”</span>
            </yl-appearance>
          </blockquote>

          <p class="u-marg-t-2 f-quote-author f-color-brand-green u-align-center">
            <yl-text-appearance :text="`- ${content.author}`"></yl-text-appearance>
          </p>
        </div>
      </div>
    </div>

    <div v-if="icon"
         class="yl-about-quote__icon-container">
      <yl-image-lazy-load :sources="icon.bind" />
    </div>
  </section>
</template>

<script>
  // Helpers
  import { getImage } from '@/helpers/assets'

  export default {
    name: 'YLAboutQuote',

    props: {
      /**
       * Object w/ content
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {String} - *REQUIRED* content.quote: Quote
       * @param {String} - *REQUIRED* content.author: Quote's author
       * @param {String} - *OPTIONAL* content.image: If quote has an image on the left
       * @param {String} - *OPTIONAL* content.icon: If quote has an illustration on the background
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return [
          this.image ? 'is-layout-with-image' : ''
        ]
      },

      elStyle () {
        return {}
      },

      image () {
        return this.content.image ? getImage({
          filename: this.content.image
        }) : false
      },

      icon () {
        return this.content.icon ? getImage({
          filename: this.content.icon
        }) : false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-about-quote {
    position: relative;
    margin-top: $base-px * 27;

    @include breakpoint('medium-') {
      margin-top: $base-px * 20;
    }

    @include breakpoint('small-') {
      margin-top: $base-px * 11;
    }
  }

  .yl-about-quote__container {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8
    ));

    .is-layout-with-image & {
      margin-top: $base-px * 16;

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 10,
        xlarge: 10
      ));
    }
  }

  .yl-about-quote__wrapper {
    display: block;

    .is-layout-with-image & {
      display: inline-flex;
      align-items: center;

      @include breakpoint('medium-') {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .yl-about-quote__image-container {
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6
    ));
  }

  .yl-about-quote__quote {
    position: relative;
    z-index: 2;

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 8,
      xlarge: 8
    ));

    .is-layout-with-image & {
      margin-left: colspan(1, 'xsmall', 0px, true);

      @include width-multi((
        xsmall: 6,
        small: 6,
        medium: 12,
        large: 6,
        xlarge: 6
      ));

      @include breakpoint('medium-') {
        margin-top: -$base-px * 3;
        margin-left: 0;
      }
    }
  }

  .yl-about-quote__icon-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) translateZ(0);

    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 12,
      large: 6,
      xlarge: 6
    ));

    opacity: 0.15;
  }
</style>
