<template>
  <section class="yl-events-featured u-marg-t-20 u-marg-b-18 u-marg-x-auto"
           :class="elClasses"
           :style="elStyle">
    <yl-ambassador-featured :content="content['ambassador']"></yl-ambassador-featured>

    <yl-legacy-featured :content="content['legacy']"></yl-legacy-featured>
  </section>
</template>

<script>
  // Components
  import YLAmbassadorFeatured from '@/components/ambassadors/YLAmbassadorFeatured'
  import YLLegacyFeatured from '@/components/legacies/YLLegacyFeatured'

  export default {
    name: 'YLEventsFeatured',

    components: {
      'yl-ambassador-featured': YLAmbassadorFeatured,
      'yl-legacy-featured': YLLegacyFeatured
    },

    props: {
      /**
       * Object w/ content for ambassadors and legacy
       * @param {Object} - *REQUIRED* content: Object w/ content
       * @param {Object} - *REQUIRED* content.ambassador: Object for Event block
       * @param {Object} - *REQUIRED* content.legacy: Object for Legacy block
       **/
      content: {
        type: Object,
        required: true
      }
    },

    computed: {
      elClasses () {
        return []
      },

      elStyle () {
        return {}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-events-featured {
    display: flex;
    justify-content: space-between;
    @include width-multi((
      xsmall: 6,
      small: 6,
      medium: 10,
      large: 10,
      xlarge: 10,
    ));

    @include breakpoint('medium-') {
      margin-top: $base-px * 11;
      margin-bottom: $base-px * 10;

      flex-direction: column;
    }
  }
</style>
