<template>
  <section class="yl-gpdr-popup"
           :class="elClasses"
           :style="elStyle">
    <div class="f-gpdr f-color-brand-white">
      <span v-html="editableContent.text"></span>

      <a :href="editableContent['learn-more'].url"
         target="_blank"
         rel="noopener"
         class="yl-gpdr-popup__learn-more u-marg-l-1 f-color-brand-white"
         v-html="editableContent['learn-more'].label">
      </a>
    </div>

    <div class="yl-gpdr-popup__button-container u-marg-l-6">
      <yl-cta class="yl-gpdr-popup__button"
              :label="editableContent.button.label"
              backgroundColor="white"
              labelColor="white"
              @click="accept">
      </yl-cta>
    </div>
  </section>
</template>

<script>
  // Store
  import { STATE as S, MUTATIONS as M } from '@/store/helpers'

  // Components
  import YLCTA from '@/components/elements/YLCTA'

  export default {
    name: 'YLGPDRPopup',

    components: {
      'yl-cta': YLCTA
    },

    computed: {
      elClasses () {
        return [
          this.visible ? 'is-visible' : ''
        ]
      },

      elStyle () {
        return {}
      },

      visible () {
        return !this.$store.state[S.gpdrAccepted]
      },

      editableContent () {
        return this.$store.state[S.editableContent]['gpdr']
      }
    },

    methods: {
      // HTML Events
      accept () {
        this.$store.commit(M.gpdrAccepted, true)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .yl-gpdr-popup {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(100%) translateZ(0);
    z-index: 10;

    display: flex;
    align-items: center;
    padding: $base-px * 3 $base-px * 4;

    background-color: map-get($colors-list, "brand-green");
    opacity: 0;
    visibility: hidden;

    transition: transform 0.55s $ease-out-quint, opacity 0.55s $ease-out-quint, visibility 0.55s;
    will-change: transform, opacity, visibility;

    &.is-visible {
      transform: translateY(0) translateZ(0);

      opacity: 1;
      visibility: visible;
    }

    @include breakpoint('small-') {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .yl-gpdr-popup__learn-more {
    text-decoration: underline !important;

    &:hover {
      text-decoration: none;
    }
  }

  .yl-gpdr-popup__button-container {
    @include breakpoint('small-') {
      margin-top: $base-px * 2;
      margin-left: 0;
    }
  }
</style>
