// Repositories
import { RepositoryFactory } from '@/repositories/RepositoryFactory.js'

// Models
import AmbassadorModel from '@/models/AmbassadorModel'
import AmbassadorFeaturedModel from '@/models/AmbassadorFeaturedModel'

// Repositories
const Ambassadors = RepositoryFactory.get('ambassadors')
const AmbassadorFeatured = RepositoryFactory.get('ambassador_featured')

export const fetchAmbassadors = async () => {
  try {
    const response = await Ambassadors.get()
    const data = response && response.hasOwnProperty('data') ? response.data : null

    if (data !== null) {
      const ambassadors = data.map(ambassador => {
        const Ambassador = new AmbassadorModel()
        Ambassador.setProperties(ambassador)

        return Ambassador
      })

      return ambassadors
    } else {
      return null
    }
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data
    } else {
      throw e.toJSON()
    }
  }
}

export const fetchAmbassadorFeatured = async () => {
  try {
    const response = await AmbassadorFeatured.get()
    const data = response && response.hasOwnProperty('data') ? response.data : null

    if (data !== null) {
      const ambassadors = data.map(ambassador => {
        const Ambassador = new AmbassadorFeaturedModel()
        Ambassador.setProperties(ambassador)

        return Ambassador
      })

      return ambassadors[0]
    } else {
      return null
    }
  } catch (e) {
    if (e.response && e.response.data) {
      throw e.response.data
    } else {
      throw e.toJSON()
    }
  }
}
